import React, { Component } from 'react';
import Modal from 'react-modal';
import './mobile-image-modal.css';
import Parser from 'html-react-parser';
import classNames from 'classnames';
import { ActionButton, TextStyle } from '@able/react';

class MobileImageModal extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false };
        this.link = React.createRef();
    }
    render() {
        const { bannerImage = '', bannerImage1 = '', altText = '', altText1 = '', buttonText, onKeyDown, disabled = false } = this.props;
        const banner = <img className="img-fluid bannerImg" alt={altText} src={bannerImage} />;
        const banner1 = <img className="img-fluid bannerImg" alt={altText1} src={bannerImage1} />;

        const toggleModal = (e) => {
            e.preventDefault();
            if (disabled) return;
            this.setState((state) => ({
                modalOpen: !state.modalOpen
            }));
            this.link.current.blur();
        };

        const ImagelModal = ({ modalOpen, imageText, imageText1 }) => {
            return (
                <Modal isOpen={modalOpen} className="modal-dialog modal-lg" role="dialog" autoFocus={false}>
                    <div className="modal-content" id="modal-content">
                        <div className="modal-body mobile-image-modal-body" id="modal-body">
                            <div className="centered-content" alt={imageText}>
                                {banner}
                            </div>
                            {bannerImage1 && <div className="centered-content" style={{ marginTop: 15 }} alt={imageText1}>
                                {banner1}
                            </div>}
                        </div>
                        <TextStyle alias="FinePrint" className="modal-message" element="span" colour="Subtle">Required information is highlighted</TextStyle>
                        <ActionButton element="button" variant="MediumEmphasis" label="Close" className="modal-close" onClick={toggleModal} />
                    </div>
                </Modal>
            );
        };

        return (
            <div className="hide-on-desktop" aria-labelledby="hide-on-desktop" style={{ marginTop: 1 }}>
                {
                    <a ref={this.link} className={classNames({
                        'idv-link-text': true,
                        'disabled-link': disabled
                    })} aria-disabled={disabled} id="idv-link-text" tabIndex="0" styling="link" role="button" onClick={toggleModal} target="_blank" onKeyDown={onKeyDown}>
                        {Parser(buttonText)}
                    </a>
                }
                <ImagelModal toggleModal={toggleModal} modalOpen={this.state.modalOpen} bannerImage={bannerImage} imageText={altText} imageText1={altText1} />
            </div>
        );
    }
}

export default MobileImageModal;
