import React, { Fragment } from 'react';
import TelstraLogo from './TelstraLogo';
import { ActionButton } from '@able/react';
import { getAbleSpriteSheet } from '../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();
class SignOut extends React.PureComponent {
    render() {
        const { appData: { uiState: { loggedIn = false, isEsimActivation = false } = {} } = {} } = this.props;
        return (
            <Fragment>
                <div className="telstra-header">
                    <div className="telstra-header-container">
                        <TelstraLogo title="Telstra-Logo" />
                        {!isEsimActivation && loggedIn && (
                            <ActionButton
                                developmentUrl={ableSpriteSheet}
                                element="button"
                                variant="LowEmphasis"
                                label="Sign out"
                                icon="SignOut"
                                className="sign-out"
                                onClick={window.TelstraAuth.logout} />
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SignOut;
