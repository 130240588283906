import React from 'react';
import './fieldError.css';
import { Icon, TextStyle } from '@able/react';
import { getAbleSpriteSheet } from '../../../adapters/utils';
const ableSpriteSheet = getAbleSpriteSheet();

const FieldError = ({ children, showIcon = true, className = '', iconSize = '32', textAlias = 'TextBodyShort' }) => {
    return (
        <TextStyle alias={textAlias} className={`field-error ${className}`}>
            {showIcon && <Icon icon="Error" size={iconSize} aria-hidden developmentUrl={ableSpriteSheet} className="mr-1" />}{children}
        </TextStyle>
    );
};

export default FieldError;
