import { Route, withRouter } from 'react-router';
import React from 'react';
import store from '../../store';
import { Debugger } from '..';
import { isDebuggingMode } from '../../adapters/utils';
import App from '../../routes/app';

function PrivateRoute({ component: Component, ...rest }) {
    // TOD -PCF commenting this code. Check and include if banners are neccesary with the new VD.

    const { app = {} } = store.getState();
    const {
        isAppLoaded,
        loginCheckInProgress
    } = app;
    const allowRoutes = isAppLoaded && !loginCheckInProgress;

    return (
        <div className="ppa-container">
            {isDebuggingMode() && <Debugger />}
            {/* <Banner /> */}
            <Route {...rest} render={(props) => (allowRoutes ? <Component {...props} /> : <App {...props} />)} />
        </div>
    );
}

export default withRouter(PrivateRoute);
