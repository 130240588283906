import React from "react";
import { TextField as AbleTextField } from "@able/react";
import { getAbleSpriteSheet } from "../../adapters/utils";
import { addDataLayerEventInfo } from "../../adapters/analytics-utils";
const ableSpriteSheet = getAbleSpriteSheet();

class TextField extends React.Component {
    componentDidUpdate() {
        const { meta: { touched = false, error, submitFailed = false} = {} } = this.props;
        if (submitFailed && touched && error) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
        }
    }
    render() {
        const {
            id,
            input,
            label,
            type,
            disabled,
            maxLength,
            helpText,
            inputHelper,
            showValidationInline,
            validationInlineVariant,
            validationInlineText = '',
            "aria-required": ariaRequired,
            meta: {
                submitFailed,
                touched,
                error
            } = {}
        } = this.props;

        return (
            <AbleTextField
                {...input}
                id={id}
                label={label}
                type={type}
                defaultValue=""
                disabled={disabled}
                maxLength={maxLength}
                helpText={helpText}
                invalidInputText={submitFailed && touched && error}
                invalid={!!(submitFailed && touched && error)}
                inputHelper={inputHelper}
                aria-required={ariaRequired}
                developmentUrl={ableSpriteSheet}
                showValidationInline={showValidationInline}
                validationInlineVariant={validationInlineVariant}
                validationInlineText={validationInlineText}
            />
        );
    }
}

export default TextField;
