import * as errorCodeMeta from './errorCode';
import { callingModuleMeta, userProfileInfoMeta, dataLayerEventActions } from './constants';
import { isEmpty } from './validations';
import { getUiState } from './store-utils';
import { getRoutesFromPathName, getBrandDetails, checkTypeInuserProfileInfoMeta, pushUrlToLiveChat } from './utils';

export const pageErrorMap = {};
const eventList = [];

export const isInPageErrorMap = (pageName) => {
    if (!pageErrorMap[pageName]) {
        pageErrorMap[pageName] = true;
        return false;
    }
    return true;
};

window.pageError = pageErrorMap;

/**
 * notifyAnalytics - add app error to 'window.digitalData.page.errorMessage' for analytics
 *
 * @param {string} requestObj - error request name
 * @param {number|string} uiError - http error code or service error constant string
 * @param {string} callingModule - app module name from where error originates
 */
export const notifyAnalytics = (requestObj = {}, uiError, callingModule = '') => {
    let analyticalError = {};
    const { request, errorText = '' } = requestObj;
    if (isErrorPresentInDataLayer(errorText)) {
        return;
    }
    if (request === requestCodeMeta.customerValidationFaliure) {
        let validationUiError = uiError;
        if (callingModule === callingModuleMeta.authorisedRepresentative) {
            if (uiError !== errorCodeMeta.SERVER_ERROR || uiError !== errorCodeMeta.BAD_REQUEST) {
                validationUiError = errorCodeMeta.CUSTOMER_NOT_AUTHORISED;
            }
        }
        analyticalError = errorMapper.find((error) => error.request === request && error.uiError === validationUiError);
    } else {
        analyticalError = errorMapper.find((error) => error.request === request && error.uiError === uiError);
    }

    if (isEmpty(analyticalError)) {
        analyticalError = errorMapper.find((error) => error.request === request && error.uiError === requestCodeMeta.notFound);
    }

    if (!isEmpty(analyticalError)) {
        window.digitalData = window.digitalData || getDefaultDataLayerObject();
        window.digitalData.page.errorMessage = window.digitalData.page.errorMessage || [];
        const report = {
            errorCode: analyticalError.errorCode,
            message: errorText,
            type: analyticalError.type
        };
        // add new error message
        window.digitalData.page.errorMessage.push(report);
        if (window.digitalData.page.pageInfo && window.digitalData.page.pageInfo.pageName) {
            if (!isInPageErrorMap(window.digitalData.page.pageInfo.pageName)) {
                addDataLayerEventInfo(dataLayerEventActions.ERROR);
            }
        }
    }
};

export const requestCodeMeta = {
    packageFailure: 'package',
    portingFailure: 'porting',
    cannotPortOnline: 'cannotPort',
    offersFailure: 'offers',
    documentFailure: 'identityDetails',
    activationFailure: 'reviewDetails',
    customerValidationFaliure: 'customerValidationFaliure',
    addressFailure: 'addressFailure',
    authoriedRepresntativeMaxRetry: 'authoriedRepresntativeMaxRetry',
    notFound: 'notFound',
    ccFaliure: 'ccFaliure',
    paypalFaliure: 'paypalFaliure',
    visaCheckoutFaliure: 'visaCheckoutFaliure',
    validationUiFailure: 'validationUiFailure',
    EmptyLicenseField: 'EmptyLicenseField',
    invalidLicenseField: 'invalidLicenseField'
};

export const errorMessages = {
    TECHNICAL_ISSUE_IDPAGE: 'The government database that we use to verify your ID is experiencing technical difficulties.',
    PACKAGE_ERROR_WELCOMEPAGE: 'The service number or Sim serial is invalid, expired or already active'
};

const analyticalErrorTypeMeta = {
    technical: 'Technical',
    business: 'Business',
    validation: 'Validation'
};

export const errorMapper = [
    // package failure
    {
        request: requestCodeMeta.packageFailure,
        uiError: 404,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000134'
    },
    {
        request: requestCodeMeta.packageFailure,
        uiError: 403,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000134'
    },
    {
        request: requestCodeMeta.packageFailure,
        uiError: errorCodeMeta.PACKAGE_NOT_AVAILABLE,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000134'
    },
    {
        request: requestCodeMeta.packageFailure,
        uiError: errorCodeMeta.ORDER_IN_PROGRESS,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000134'
    },

    // server error - Retrieve Resource Details call fails - welcome
    {
        request: requestCodeMeta.packageFailure,
        uiError: 500,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000137'
    },
    {
        request: requestCodeMeta.packageFailure,
        uiError: errorCodeMeta.SERVER_ERROR,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000137'
    },

    {
        request: requestCodeMeta.packageFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000137'
    },

    // offers failure
    {
        request: requestCodeMeta.offersFailure,
        uiError: errorCodeMeta.NO_OFFERS,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000138'
    },

    // offers failure - not found

    {
        request: requestCodeMeta.offersFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000138'
    },

    // cannot port online - exsisting number
    {
        request: requestCodeMeta.cannotPortOnline,
        uiError: errorCodeMeta.CANNOT_PORT,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000139'
    },
    {
        request: requestCodeMeta.validationUiFailure,
        uiError: errorCodeMeta.VALIDATION_ERROR,
        type: analyticalErrorTypeMeta.validation,
        errorCode: 'V:000391'
    },

    // porting failed
    {
        request: requestCodeMeta.portingFailure,
        uiError: errorCodeMeta.PORTING_FAILED,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000141'
    },
    {
        request: requestCodeMeta.validationUiFailure,
        uiError: errorCodeMeta.TERMS_NOT_ACCEPTED,
        type: analyticalErrorTypeMeta.validation,
        errorCode: 'V:000141'
    },
    {
        request: requestCodeMeta.portingFailure,
        uiError: errorCodeMeta.SERVER_ERROR,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000144'
    },
    {
        request: requestCodeMeta.portingFailure,
        uiError: 500,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000141'
    },
    {
        request: requestCodeMeta.portingFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000141'
    },

    // customer not authorised to create new account
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: errorCodeMeta.CUSTOMER_NOT_AUTHORISED,
        callingModule: callingModuleMeta.authorisedRepresentative,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000144'
    },

    // max retry failure - authoried represntative
    {
        request: requestCodeMeta.authoriedRepresntativeMaxRetry,
        uiError: errorCodeMeta.MAX_RETRY_FAILURE,
        type: 'business',
        errorCode: 'B:000146'
    },

    // server error - customer validation
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: errorCodeMeta.SERVER_ERROR,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // not found - customer validation

    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // customer not found
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: errorCodeMeta.CUSTOMER_NOT_FOUND,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000150'
    },

    // address failure
    {
        request: requestCodeMeta.addressFailure,
        uiError: 500,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000151'
    },

    // address failure - not found
    {
        request: requestCodeMeta.addressFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000151'
    },

    // customer matching call failure
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: errorCodeMeta.BAD_REQUEST,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000140'
    },

    // customer matching call failure
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: 403,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000140'
    },

    // customer matching call failure
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: 417,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000140'
    },

    // customer matching call failure
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: 405,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000140'
    },

    // multiple customers found
    {
        request: requestCodeMeta.customerValidationFaliure,
        uiError: errorCodeMeta.MULTIPLE_CUSTOMERS_FOUND,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000155'
    },

    // document validation error
    {
        request: requestCodeMeta.documentFailure,
        uiError: 403,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000156'
    },

    // document validation error
    {
        request: requestCodeMeta.documentFailure,
        uiError: 405,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000156'
    },

    // document validation error
    {
        request: requestCodeMeta.documentFailure,
        uiError: errorCodeMeta.INVALID_DOCUMENT,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000156'
    },

    // max retry failure - identity details
    {
        request: requestCodeMeta.documentFailure,
        uiError: errorCodeMeta.MAX_RETRY_FAILURE,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000157'
    },

    // server failure - ID details
    {
        request: requestCodeMeta.documentFailure,
        uiError: 500,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // server failure - ID details
    {
        request: requestCodeMeta.documentFailure,
        uiError: errorCodeMeta.SERVER_ERROR,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // Not found - ID details
    {
        request: requestCodeMeta.documentFailure,
        uiError: requestCodeMeta.LicenseFieldEmpty,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000465'
    },

    // Not found - ID details
    {
        request: requestCodeMeta.documentFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // review details - blacklist error
    {
        request: requestCodeMeta.activationFailure,
        uiError: 5004,
        type: analyticalErrorTypeMeta.business,
        errorCode: 'B:000162'
    },

    // review details - server error
    {
        request: requestCodeMeta.activationFailure,
        uiError: 417,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // review details - server error
    {
        request: requestCodeMeta.activationFailure,
        uiError: 500,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // review details - server error
    {
        request: requestCodeMeta.activationFailure,
        uiError: errorCodeMeta.SERVER_ERROR,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },

    // review details - not found
    {
        request: requestCodeMeta.activationFailure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000143'
    },
    // payment faliures
    {
        request: requestCodeMeta.visaCheckoutFaliure,
        uiError: requestCodeMeta.VISA_CHECKOUT_PAYMENT_FALIURE,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000160'
    },
    {
        request: requestCodeMeta.ccFaliure,
        uiError: requestCodeMeta.BILLPAY_PAYMENT_FALIURE,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000159'
    },
    {
        request: requestCodeMeta.paypalFaliure,
        uiError: requestCodeMeta.PAYPAL_PAYMENT_FALIURE,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000161'
    },
    {
        request: requestCodeMeta.visaCheckoutFaliure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000160'
    },
    {
        request: requestCodeMeta.ccFaliure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000159'
    },
    {
        request: requestCodeMeta.paypalFaliure,
        uiError: requestCodeMeta.notFound,
        type: analyticalErrorTypeMeta.technical,
        errorCode: 'T:000161'
    }
];

/**
 * getDefaultDataLayerObject - returns initial default data layer object for PPA with mandatory properties
 *
 * @return {object}
 */
const getDefaultDataLayerObject = () => {
    return {
        page: {},
        event: [],
        version: '1.0.2' // W3C Digital Data Layer standard, fixed value
    };
};

/**
 * isValidDataLayerObject - does basic validation on data layer object and mandatory properties
 *
 * @param {object} inputObj - the data layer object to be validated
 * @return {boolean}
 */
export const isValidDataLayerObject = (inputObj) => {
    // check object type
    if (!inputObj || typeof inputObj !== 'object') {
        return false;
    }

    // check mandatory properties
    if (!inputObj.page || typeof inputObj.page !== 'object') {
        return false;
    } else if (!inputObj.event || !Array.isArray(inputObj.event)) {
        return false;
    } else if (!inputObj.version || typeof inputObj.version !== 'string') {
        return false;
    }

    return true;
};

/**
 * resetDataLayerObject - reset current data layer object to initial default state
 */
export const resetDataLayerObject = () => {
    window.digitalData = getDefaultDataLayerObject();
};

/**
 * updateDataLayerObject - will merge the update object to the current data layer object
 *
 * @param {object} updateObj - the data layer update object to be merged into the current data layer object
 */
export const updateDataLayerObject = (updateObj) => {
    // check update object type
    if (!updateObj || typeof updateObj !== 'object') {
        return;
    }

    // set default data layer if not already have one
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    // merge and update if valid
    const updatedDataLayerObj = Object.assign(window.digitalData, updateObj);
    if (isValidDataLayerObject(updatedDataLayerObj)) {
        window.digitalData = updatedDataLayerObj;
    }
};

/**
 * replaceDataLayerObject - will replace the current data layer object with the input object
 *
 * @param {object} replaceObj - the new data layer object to replace the existing one
 */
export const replaceDataLayerObject = (replaceObj) => {
    if (isValidDataLayerObject(replaceObj)) {
        window.digitalData = Object.assign({}, replaceObj);
    }
};

/**
 * createDataLayerPage - creates and returns a page object for data layer
 *
 * @param {string} pageName - page name text
 * @param {boolean} isEsimActivation - if the page is of eSIM activation
 * @return {object}
 */
export const createDataLayerPage = (pageName = '', isEsimActivation = false) => {
    // page object shapes needs to align with the following wiki:
    // https://wiki.ae.sda.corp.telstra.com/pages/viewpage.action?pageId=79004079#DataDictionary:W3CDigitalDataLayer-3.1.digitalData.page
    return {
        page: {
            pageInfo: {
                pageName,
                destinationURL: window.location.href,
                variant: isEsimActivation ? 'eSIM' : 'default',
                deployEnv: window.appConfig && window.appConfig.PPA_ENV_MODE ? (window.appConfig.PPA_ENV_MODE.toLowerCase().indexOf('prod') !== -1 ?  'production': window.appConfig.PPA_ENV_MODE)  : '',
                referringURL: document.referrer,
                redirectedURL: '' // Needs to change this once confirmed from anaytics team
            },
            category: {
                primaryCategory: isEsimActivation ? 'eSIM Pre-paid Activation' : 'Pre-paid Activation',
                secondaryCategory: 'Activation',
                pageType: 'service'
            },
            attributes: {
                division: 'TD',
                subDivision: 'TR',
                subDivisionUnit: 'TR'
            }
        }
    };
};

/**
 * createDefaultDataLayerEvent - creates and returns a event info object for data layer
 *
 * @return {object|null}
 */

export const createDefaultDataLayerEvent = (eventAction, eventType = '', eventCategory = '', eventName = '') => {
    return {
        eventInfo: {
            eventAction,
            eventType,
            eventCategory,
            eventName
        },
        attributes: {}
    };
};

/**
 * createDataLayerEventInfo - creates and returns a event info object for data layer
 *
 * @param {string} eventAction - mandatory event action value
 * @return {object|null}
 */
export const createDataLayerEventInfo = (eventAction) => {
    if (typeof eventAction !== 'string' || !eventAction.trim().length) {
        return null;
    }

    return { eventInfo: { eventAction } };
};

/**
 * addDataLayerEventInfo - adds new event info object to data layer
 *
 * @param {string} eventAction - mandatory event action value
 */
export const addDataLayerEventInfo = (eventAction, eventType = '', eventCategory = 'unavailable', eventName = '') => {
    if (isEmpty(eventAction)) {
        return;
    }
    if ((eventAction === 'click' && eventName === 'Set up auto recharge') || (eventCategory === 'PPAPriceModal')) {
        const eventInfo = createDefaultDataLayerEvent(eventAction, eventType, eventCategory, eventName);
        // set default data layer if not already have one
        window.digitalData = window.digitalData || getDefaultDataLayerObject();
        window.digitalData.event = window.digitalData.event || [];
        // add new event info
        window.digitalData.event.push(eventInfo);

    } else if (isNotAnExistingEvent(eventName, eventAction, eventCategory)) {
        const eventInfo = createDefaultDataLayerEvent(eventAction, eventType, eventCategory, eventName);
        // set default data layer if not already have one
        window.digitalData = window.digitalData || getDefaultDataLayerObject();
        window.digitalData.event = window.digitalData.event || [];
        // add new event info
        window.digitalData.event.push(eventInfo);
    }
};

/**
 * createDataLayerProduct - creates and returns a product object for data layer
 *
 * @param {object} data - product data object
 * @return {object|null}
 */
export const createDataLayerProduct = ({ productID = '', price = -1, quantity = 0, starterCredit = 0 }) => {
    if (typeof productID !== 'string' || !productID.trim().length) {
        return null;
    }
    if (isNaN(price) || price < 0) {
        return null;
    }
    if (isNaN(quantity) || quantity < 1) {
        return null;
    }

    return {
        item: [{
            category: {
                primaryCategory: 'Consumer Mobile',
                productType: 'Primary',
                secondaryCategory: 'Prepaid',
                tertiaryCategory: 'Activation'
            },
            productInfo: {
                productID: `${productID}|${price}`,
                productName: productID,
                planSize: quantity,
                contractDuration: 0,
                orderType: 'Activation',
                starterCreditValue: starterCredit
            },
            price: {
                basePrice: price,
                productTotal: price,
                currency: 'AUD'
            }
        }]
    };
};

/**
 * addDataLayerProduct - adds new product object to data layer
 *
 * @param {object} data - product data object
 */
export const addDataLayerProduct = (data) => {
    // create product object and check
    const product = createDataLayerProduct(data);
    // if (product === null) {
    //     return;
    // }

    // set default data layer if not already have one
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    window.digitalData.cart = window.digitalData.cart || getDefaultCartDataLayerObject();
    window.digitalData.cart = product; // Assign product item directly
    updateCartPrice(product); // Pass product as an array to updateCartPrice

    // window.digitalData.product = window.digitalData.product || [];
    // add product
    // window.digitalData.product[0] = product;
};

// addDataLayerSimDetails - adds new sim details object to data layer

export const addDataLayerSimDetails = () => {
    setTimeout(() => {
        const { brandName } = getBrandDetails();
        window.digitalData = window.digitalData || getDefaultDataLayerObject();
        window.digitalData.simDetails = {
            simBrandName: brandName === 'JB Hi-Fi' ? 'JB HiFi' : 'Telstra'
        };
    }, 1000);
};

/**
 * trackHistoryLocationChange - tracking method when app history location changes
 */
export const trackHistoryLocationChange = (location = {}) => {
    const { pathname = '' } = location;
    if (!pathname.length) {
        return;
    }

    // get matching app routes from location path name
    const routes = getRoutesFromPathName(pathname);
    if (!routes.length || !routes[0].pageName) {
        return;
    }

    // page update object properties
    let pageName = routes[0].pageName;
    const events = routes[0].events;
    const addBrandDetails = routes[0].addBrandDetails;
    const { isEsimActivation = false } = getUiState();

    // Update pagename to SIM Details for esim flow
    if (isEsimActivation && pageName === 'Welcome') {
        pageName = 'SIM Details';
    } else {
        // Change 'Existing Number' to 'Service Details' for pageName
        pageName = (pageName === "Existing Number") ? "Service Details" : pageName;
    }
    // create data layer page object and update
    updateDataLayerObject(createDataLayerPage(pageName, isEsimActivation));
    pushUrlToLiveChat(pageName);

    if (addBrandDetails) {
        addDataLayerSimDetails();
    }

    // add page view event
    if (events && events.length) {
        events.forEach((event) => addDataLayerEventInfo(event));
    }
};

/**
 * addDataLayerUser - adds new user object to data layer
 * @param {string} eventAction - mandatory event action value
 */
export const addDataLayerUser = (eventAction) => {
    // create event info object and check
    const { type, value, address } = eventAction;
    if (type && (!checkTypeInuserProfileInfoMeta(type) || typeof value !== 'string' || !value.trim().length)) {
        return;
    }

    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    window.digitalData.user = window.digitalData.user || [];

    if (!window.digitalData.user.length) {
        // Assuming only single user and single profile, implementation needs to be changed
        // in case of multiple users/profile
        window.digitalData.user[0] = { profile: [{ profileInfo: {}, address: {} }] };
    }
    if (type) window.digitalData.user[0].profile[0].profileInfo[type] = value;
    if (address) window.digitalData.user[0].profile[0].address.stateProvince = address.stateProvince;
};

/**
 * addMultiDataLayerUser - ability to add multiple userdata layer
 * @param {array eventAction} eventArr - array of eventAction
 * eventAction to have mandatory type and value key
 */

export const addMultiDataLayerUser = (eventArr) => {
    if (!eventArr.length) {
        return;
    }
    eventArr.forEach((eventAction) => {
        addDataLayerUser(eventAction);
    });
};

/**
 * getDefaultCartDataLayerObject - returns default cart Object
 * @returns {object}
 */
export const getDefaultCartDataLayerObject = () => {
    return {
        price: {},
        item: []
    };
};

export const getDefaultApplicationDataLayerObject = () => {
    return {
        portIn: {}
    };
};

export const updateCartPrice = (products) => {
    const voucherDiscount = 0; // TODO: needs to be passed as argument
    let basePrice = 0;
    let cartTotal = 0;
    if (products && products.item.length) {
        products.item.forEach((product) => {
            const price = product.price && product.price.basePrice ? product.price.basePrice : 0;
            const totalPrice = product.price && product.contractDuration ? price * product.contractDuration : price;
            basePrice += price;
            cartTotal += totalPrice;
        });
    }
    cartTotal -= voucherDiscount;
    setDataLayerCartPrice(userProfileInfoMeta.BASE_PRICE, basePrice);
    setDataLayerCartPrice(userProfileInfoMeta.CART_TOTAL, cartTotal);
};

/**
 * addCartDataLayer - ability to add cart data layer
 * @param {array eventAction} eventArr - array of eventAction
 * eventAction to have mandatory type and value key
 */

export const addCartDataLayer = (data) => {
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    // if (window.digitalData.product && window.digitalData.product.length) {
    //     addToCartLayer(window.digitalData.product);
    //     delete window.digitalData.product;
    // } else {
    //     const product = createDataLayerProduct(data);
    //     if (product === null) {
    //         return;
    //     }
    addDataLayerProduct(data);
    // }
};

export const setTotalPrice = () => {
    if (window.digitalData.cart.price) {
        const { basePrice = 0, cartTotal = 0, currency = 'AUD', paymentType = '' } = window.digitalData.cart.price;
        const total = {
            // Need to change the implementation to calculate total price by summing up all cart items
            // as of now only one product is allowed so total == price in cart
            transactionTotal: cartTotal,
            basePrice,
            currency,
            paymentType
        };
        delete Object.assign(window.digitalData.cart, { total }).price;
    }
};

export const addToTransactionLayer = (customerInteractionId) => {
    window.digitalData = window.digitalData || getDefaultDataLayerObject();

    if (window.digitalData.cart != null) {
        // setTotalPrice();
        // delete Object.assign(window.digitalData, { transaction: window.digitalData.cart }).cart;
        const cart = window.digitalData.cart;
        const transaction = {
            item: cart.item.map((item) => ({
                category: item.category,
                productInfo: item.productInfo,
                price: item.price,
                quantity: 1 // Assuming each item is added only once
            })),
            transactionID: customerInteractionId,
            total: {
                basePrice: cart.price.basePrice,
                transactionTotal: cart.price.cartTotal,
                paymentType: cart.price.paymentType,
                currency: 'AUD'
            }
        };
        window.digitalData.transaction = transaction;
        delete window.digitalData.cart;
    }
};

const addToCartLayer = (product) => {
    window.digitalData.cart = window.digitalData.cart || getDefaultCartDataLayerObject();
    window.digitalData.cart.item = [{ ...product.product[0] }]; // Assign product item directly
    updateCartPrice(product);
};

export const addCartLayer = () => {
    window.digitalData.cart = window.digitalData.cart || getDefaultCartDataLayerObject();
};

export const addApplicationLayer = () => {
    window.digitalData.application = window.digitalData.application || getDefaultApplicationDataLayerObject();
};

export const setDataLayerCartPrice = (type, value) => {
    if (!checkTypeInuserProfileInfoMeta(type)) {
        return;
    }
    const priceObj = { [type]: value };
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    if (window.digitalData.cart != null) {
        window.digitalData.cart.price = Object.assign({}, window.digitalData.cart.price, priceObj);
    }
};

export const isErrorPresentInDataLayer = (errorMsg) => {
    const digitalData = window.digitalData;
    let isPresentInDigitalData = false;
    if (digitalData && digitalData.page && digitalData.page.errorMessage && digitalData.page.errorMessage.length) {
        digitalData.page.errorMessage.forEach((error) => {
            if (errorMsg === error.message) {
                isPresentInDigitalData = true;
            }
        });
    }
    return isPresentInDigitalData;
};

export const clearDataLayerError = () => {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.errorMessage) {
        delete window.digitalData.errorMessage;
    }
};

export const errorDataLayerAddition = (errorCode, errorType, errorCategory) => {
    const errorMsg = errorCodeMeta.errorMapForAnalytics.find((er) => {
        return errorCode === er.id;
    });

    if (errorMsg && Object.keys(errorMsg).length !== 0) {
        addDataLayerEventInfo('error', errorType, errorCategory, errorMsg.text);
    }
};

export const addDataLayerCartPrice = (type, value) => {
    if (!checkTypeInuserProfileInfoMeta(type)) {
        return;
    }
    const priceObj = { [type]: value };
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    if (window.digitalData.cart != null) {
        window.digitalData.cart.price = Object.assign({}, window.digitalData.cart.price, priceObj);
    }
};

export const addDataLayerApplicationItem = (type, value) => {
    const applicationObj = { [type]: value };
    window.digitalData = window.digitalData || getDefaultDataLayerObject();
    if (window.digitalData.application != null) {
        window.digitalData.application.portIn = Object.assign({}, window.digitalData.application.portIn, applicationObj);
    }
};

export const addUniqueEventDataLayer = (eventType, dataLayerObj = window.digitalData) => {
    let present = false;
    if (dataLayerObj && dataLayerObj.event && dataLayerObj.event.length) {
        present = dataLayerObj.event.some((el) => {
            return el.eventInfo.eventAction === eventType;
        });
    }
    if (!present) {
        addDataLayerEventInfo(eventType);
    }
};

/**
 * isNotAnExistingEvent - checks for duplicate events
 *
 * @param {string} eventName - eventName
 */
const isNotAnExistingEvent = (eventName, eventAction, eventCategory ='') => {
    const eventKey = isEmpty(eventName) ? eventAction+eventCategory : eventName+eventAction+eventCategory; // Creating a unique key for each event
    if (eventList.includes(eventKey)) {
        return false;
    }
    eventList.push(eventKey); // Add the event to the list
    return true;
};
