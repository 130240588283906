import React, { Component } from 'react';
import PortingLock from '../../assets/img/svg/lock.svg';
import './your-contact-details.css';
import { formatMobileNumber, nextPage, focusOnElement, getAbleSpriteSheet, isSelectedDeviceMBB } from '../../adapters/utils';
import { getUiState } from '../../adapters/store-utils';
import * as errorCodeMeta from '../../adapters/errorCode';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import { Picto, SomethingWentWrong104, TextField, RadioGroup, RadioButton, TextStyle, MessageSection, IconButton, ActionButton, Spacing } from '@able/react';
import { validateMobileNumberPattern } from '../../adapters/validations';
import { updateDataLayerObject, createDataLayerPage, addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { getOtcError } from './utils/utils';
import { OTC_VERIFY_PURPOSE } from '../../adapters/constants';

const ableSpriteSheet = getAbleSpriteSheet();

class UpdateContactModal extends Component {
    static getDerivedStateFromProps(props, state) {
        const {
            toggleModal,
            actions: { updateStepperCustomData },
            errors: { hasError, errorCode },
            updateSuccessAction
        } = props;

        const {
            editContact: { hasOtpSent, hasOtpError, isProcessingOtp },
            validations: { isOTCVerified, isVerifyOTCInProgress },
            otherEmail,
            appData: { uiState: { loggedIn = false, isEsimActivation } = {}, customer: {
                data: { contactEmail }
            }, msisdn } = {},
            app: { customerDataFetchSuccess },
            welcome: { portingNumber }
        } = props;


        const { selectedActivationFlow = '' } = getUiState();
        const isPortingFlow = selectedActivationFlow === 'portNumber';

        const update = {};

        if (hasOtpSent && !hasOtpError && !hasError) {
            update.showScreen = 'validate identity';
        }

        if (state.contactUpdated) {
            toggleModal && toggleModal();
            if (!loggedIn) {
                nextPage(updateStepperCustomData);
                updateSuccessAction();
            } else {
                if (otherEmail) {
                    contactEmail ?
                        addDataLayerEventInfo('impression', 'updateEmail', 'existingEmail', 'existingEmail') : addDataLayerEventInfo('impression', 'addEmail', 'newEmail', 'newEmail');
                    updateSuccessAction({ contactEmail: otherEmail });
                } else if (state.otherNumber) {
                    updateSuccessAction({ phoneNumber: state.otherNumber });
                    addDataLayerEventInfo('impression', 'updateMobile', 'existingMobile', 'existingMobile');
                } else if (state.mobileSelectedOption === 'activating') {
                    updateSuccessAction({ phoneNumber: isEsimActivation ? `The mobile number I'm activating` : isPortingFlow ? portingNumber : msisdn });
                } else if (msisdn || portingNumber) {
                    updateSuccessAction();
                } else if (isEsimActivation && updateSuccessAction) {
                    updateSuccessAction();
                }
                // address part is already done in the address update modal

                if (loggedIn && !customerDataFetchSuccess) {
                    nextPage(updateStepperCustomData);
                }
            }
        }

        if (hasError) {
            if (
                errorCode === errorCodeMeta.VALIDATE_OTC_ERROR ||
                errorCode === errorCodeMeta.SEND_OTC_ERROR ||
                errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC ||
                errorCode === errorCodeMeta.MAX_ATTEMPTS_SEND_OTC ||
                errorCode === errorCodeMeta.UPDATE_MOBILE_ERROR ||
                errorCode === errorCodeMeta.SERVER_ERROR ||
                errorCode === errorCodeMeta.UNKNOWN_ERROR ||
                errorCode === errorCodeMeta.UPDATE_CONTACT_MEDIUM_ERROR
            ) {
                update.showScreen = 'show error modal';
            }
        }

        if ((state.otherNumber || otherEmail) && !hasOtpSent && !isProcessingOtp && !hasError) {
            update.showScreen = 'confirm identity';
        } else if (!isVerifyOTCInProgress && isOTCVerified && !hasError && !state.otherNumber && !otherEmail) {
            update.showScreen = 'show update screen';
        }

        return update;
    }

    constructor(props) {
        super(props);
        this.state = { ...this.getInitialState(props) };
        this.closeModal = this.closeModal.bind(this);
        this.verifyOTC = this.verifyOTC.bind(this);
        this.resetErrors = this.resetErrors.bind(this);
        this.updated = this.updated.bind(this);
        this.validateContactMedium = this.validateContactMedium.bind(this);
        this.getVerifyIdDetails = this.getVerifyIdDetails.bind(this);
        this.verifyMobileNumber = this.verifyMobileNumber.bind(this);
        this.onOtcChange = this.onOtcChange.bind(this);
        this.onNewMobileNumberChange = this.onNewMobileNumberChange.bind(this);
        this.previousScreenRef = React.createRef(null);
    }

    getInitialState = (props = this.props) => {
        const isMBBActivation = isSelectedDeviceMBB(props.appData.uiState.selectedDevice);
        return {
            showScreen: props.identityVerified ? 'show update screen' : 'confirm identity',
            contactUpdated: false,
            verifyId: '',
            contactMedium: '',
            errors: {
                otc: false,
                mblNum: false
            },
            otc: '',
            otcOptionValue: '',
            otcMaskedValue: '',
            otcOptionType: '',
            isResend: false,
            newNumber: '',
            otherNumber: '',
            mobileSelectedOption: '',
            confirmMobileOption: props.appData.customer.data.phoneNumber || isMBBActivation ? 'newnumber' : 'activating',
            confirmIdentityOption: 'mobileNumber',
            isMBBActivation
        };
    }

    resetState = () => {
        this.setState({ ...this.getInitialState() });
    }

    componentDidUpdate(prevProps) {
        if (this.state.errors.mblNum) {
            focusOnElement('#newNumber');
        }
        if (this.state.errors.otc) {
            focusOnElement('#otcInput');
        }

        if (this.previousScreenRef.current !== this.state.showScreen) {
            this.previousScreenRef.current = this.state.showScreen;
            if (this.props.modalRef && this.props.modalRef.current) {
                this.props.modalRef.current.node.getElementsByClassName("modal-heading")[0].focus();
                this.props.modalRef.current.node.getElementsByClassName("ReactModal__Overlay")[0].removeAttribute("aria-modal");
            }
        }

        const {
            validations: { isOTCVerified, isVerifyOTCInProgress, otcCounter },
            updateMobileStatus: { isMobileUpdateInProgress, isMobileUpdated },
            errors: { hasError, errorCode },
            otherEmail,
            actions: { updateContactMedium, setPhoneNumVerificationLimitInfo }
        } = this.props;

        if (hasError &&
            prevProps.errors?.errorCode !== errorCode &&
            (errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC || errorCode === errorCodeMeta.MAX_ATTEMPTS_SEND_OTC) &&
            !this.state.isMBBActivation && !this.props.appData.customer?.data?.phoneNumber
        ) {
            const errorDetails = {
                errorHeading: 'Unable to process request',
                errorText: "We can't add this number to our records as we couldn't verify your access to it. You can add the mobile number you're activating now."
            };
            this.closeModal();
            setPhoneNumVerificationLimitInfo(true, errorDetails);
        }

        const {
            validations: { otcCounter: prevOtcCounter },
        } = prevProps;

        if (prevOtcCounter !== otcCounter) {
            const otherContact = otherEmail || this.state.otherNumber;
            if (!isVerifyOTCInProgress && isOTCVerified && !hasError && otherContact && !isMobileUpdateInProgress && !isMobileUpdated) {
                const request = {
                    data: {
                        activatingMsisdn: false,
                        contactMediumType: otherEmail ? 'Email' : 'Telephone',
                        contactMediumValue: otherContact
                    }
                };
                updateContactMedium(request);
            }
        }
    }

    componentDidMount() {
        this.previousScreenRef.current = this.state.showScreen;
        if (this.props.modalRef && this.props.modalRef.current) {
            this.props.modalRef.current.node.getElementsByClassName("modal-heading")[0].focus();
            this.props.modalRef.current.node.getElementsByClassName("ReactModal__Overlay")[0].removeAttribute("aria-modal");
        }
    }

    render() {
        const {
            appData: { msisdn },
            errors: { hasError, errorCode },
            updateMobileStatus: { isMobileUpdated, isMobileUpdateInProgress },
            validations: { otcVerifyMediumType }
        } = this.props;
        let errorProps;

        if (hasError) {
            if (this.state.showScreen && this.state.showScreen === 'validate identity') {
            }
            if (errorCode === errorCodeMeta.INVALID_OTC) {
                errorProps = {
                    errorHeading: '',
                    errorText: 'Incorrect code. Please check your code and try again.'
                };
            } else if (errorCode === errorCodeMeta.EXPIRED_OTC) {
                errorProps = {
                    errorHeading: '',
                    errorText: 'The code you’ve entered has expired. Request a new one below.'
                };
            } else if (errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC) {
                if (otcVerifyMediumType === 'SMS' && (this.props.appData.customer?.data?.phoneNumber || this.state.isMBBActivation)) {
                    errorProps = {
                        hideCloseButton: true,
                        errorTextLink: 'OK',
                        onClickErrorTextLink: this.onContinueFromLimitReachModal,
                        errorHeading: 'Unable to process request',
                        errorText: "We can't add this number to our records as we couldn't verify your access to it. We'll keep your current details."
                    };
                } else {
                    errorProps = {
                        errorHeading: 'Unable to process request',
                        errorText: "You've reached the maximum attempts for entering your one-time code. You'll need to wait at least 20 minutes before trying again."
                    };
                }
            } else if (errorCode === errorCodeMeta.MAX_ATTEMPTS_SEND_OTC) {
                if (otcVerifyMediumType === 'SMS' && (this.props.appData.customer?.data?.phoneNumber || this.state.isMBBActivation)) {
                    errorProps = {
                        hideCloseButton: true,
                        errorTextLink: 'OK',
                        onClickErrorTextLink: this.onContinueFromLimitReachModal,
                        errorHeading: 'Unable to process request',
                        errorText: "We can't add this number to our records as we couldn't verify your access to it. We'll keep your current details."
                    };
                } else {
                    errorProps = {
                        errorHeading: 'Unable to process request',
                        errorText: "You've reached the maximum attempts for requesting your one-time code. You'll need to wait at least 20 minutes before trying again."
                    };
                }

            } else if (
                errorCode === errorCodeMeta.VALIDATE_OTC_ERROR ||
                errorCode === errorCodeMeta.SEND_OTC_ERROR ||
                errorCode === errorCodeMeta.UPDATE_MOBILE_ERROR ||
                errorCode === errorCodeMeta.SERVER_ERROR ||
                errorCode === errorCodeMeta.UNKNOWN_ERROR ||
                errorCode === errorCodeMeta.UPDATE_CONTACT_MEDIUM_ERROR
            ) {
                errorProps = {
                    errorHeading: 'Something went wrong',
                    errorText: "We're unable to process your request. Please try again later.",
                    errorTextLink: 'Try again',
                    onClickErrorTextLink: this.closeErrorModal,
                };
            }
        }

        return (
            <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">
                {this.state.showScreen === 'confirm identity' && this.confirmIdentity()}
                {this.state.showScreen === 'validate identity' && this.validateIdentity(msisdn, errorProps)}
                {this.state.showScreen === 'show error modal' && this.showFullModalAlert(errorProps)}
                {this.state.showScreen === 'show update screen' && this.showUpdateScreen()}
                {!isMobileUpdateInProgress && isMobileUpdated && this.updated()}
            </div>
        );
    }

    onContinueFromLimitReachModal = () => {
        const {
            appData: { uiState: { loggedIn = false } = {} } = {},
            toggleModal,
            actions: { updateStepperCustomData, noUpdateOnExistingNumber }
        } = this.props;
        if (loggedIn) {
            toggleModal?.();
        } else {
            noUpdateOnExistingNumber();
            nextPage(updateStepperCustomData);
        }
    }

    closeModal() {
        this.resetErrors();
        this.props.toggleModal();
    }

    closeErrorModal = () => {
        this.resetErrors();
        if (this.props.gotoInitModalOnErrorClose) {
            this.props.resetModal();
            this.resetState();
        } else {
            this.props.toggleModal();
        }
    }

    onOtcChange({ target: { value } } = {}) {
        if (isNaN(Number(value))) {
            return;
        }
        this.setState({ otc: value, errors: { otc: false } });
        this.resetErrors(false);
    }

    verifyOTC(dispatch, props) {
        const { validateOTC } = this.props.actions;
        let { errors } = this.state;
        const verificationCode = this.state.otc;

        const otcError = getOtcError(verificationCode);
        if (otcError) {
            errors = { ...errors, otc: otcError };
            this.setState({ errors });
            addDataLayerEventInfo('error', 'Inline', 'unavailable', errors);
            return;
        }

        errors = { ...errors, otc: '' };
        this.setState({ errors, isResend: false });

        const payload = {
            data: {
                oneTimePinValue: verificationCode
            }
        };
        payload.contactMediumType = 'SMS';
        if (this.props.otherEmail) {
            payload.contactMediumType = 'EMAIL';
        }
        validateOTC(payload);
    }

    updated() {
        this.setState({
            contactUpdated: true
        });
    }

    resetErrors(isOTCVerified) {
        const {
            errors: { hasError }
        } = this.props;
        const { resetOTC } = this.props.actions;
        hasError && resetOTC(isOTCVerified);
    }

    getVerifyIdDetails() {
        const {
            appData: {
                customer: {
                    data: { contactDetails = [] }
                }
            }
        } = this.props;
        let verifyId = '';
        if (contactDetails.length === 1) {
            if (contactDetails[0].contactMediumType === 'Telephone') {
                verifyId = contactDetails[0].contactMediumValue;
            } else if (contactDetails[0].contactMediumType === 'Email') {
                verifyId = contactDetails[0].contactMediumValue;
            }
        }
        return verifyId;
    }

    verifyMobileNumber() {
        const {
            appData: { uiState: { loggedIn = false, selectedActivationFlow, isEsimActivation } = {}, msisdn } = {},
            welcome: { portingNumber },
            toggleModal,
            updateSuccessAction
        } = this.props;
        const { resetEditContactModal, noUpdateOnExistingNumber, updateStepperCustomData } = this.props.actions;
        const existingNumber = this.props.appData.customer.data.phoneNumber;

        let { errors } = this.state;
        const selectedOption = this.state.confirmMobileOption;
        let contactMediumValue;

        if (selectedOption === 'newnumber' || selectedOption === 'activating') {
            if (selectedOption === 'newnumber') {
                resetEditContactModal();
                contactMediumValue = this.state.newNumber;

                const num = Number(contactMediumValue);
                if (isNaN(num) || !contactMediumValue) {
                    errors = { ...errors, mblNum: 'Required field. Please enter a mobile number.' };
                    addDataLayerEventInfo('error', 'Inline', errors.mblNum || '');
                    this.setState({ errors });
                    return;
                } else if (validateMobileNumberPattern(contactMediumValue)) {
                    errors = { ...errors, mblNum: 'Invalid input. Please use format 04XXXXXXXX.' };
                    addDataLayerEventInfo('error', 'Inline', 'unavailable', errors.mblNum || '');
                    this.setState({ errors });
                    return;
                }
                errors = { ...errors, mblNum: '' };
                this.setState({ errors, otherNumber: contactMediumValue, isResend: false, otc: '' });
            } else if (selectedOption === 'activating') {
                this.setState({ mobileSelectedOption: 'activating' });
                toggleModal && toggleModal();
                const isPortingFlow = selectedActivationFlow === 'portNumber';
                updateSuccessAction({ phoneNumber: isPortingFlow ? portingNumber : isEsimActivation ? `The mobile number I'm activating` : msisdn });
                !loggedIn && nextPage(updateStepperCustomData);
            }
        } else if (selectedOption === 'existing') {
            toggleModal && toggleModal();
            loggedIn && updateSuccessAction({ phoneNumber: existingNumber || '' });
            noUpdateOnExistingNumber();
            !loggedIn && nextPage(updateStepperCustomData);
        } else {
            this.updated();
        }
    }

    confirmIdentity() {
        const { toggleModal } = this.props;
        const {
            appData: {
                customer: {
                    data: { contactDetails = [], phoneNumber, contactEmail }
                }
            }
        } = this.props;
        const {
            editContact: { isProcessingOtp }
        } = this.props;
        const verifyId = this.getVerifyIdDetails();
        const otherNumber = this.state.otherNumber;
        const { otherEmail } = this.props;
        const { confirmIdentityOption } = this.state;
        otherEmail ? updateDataLayerObject(createDataLayerPage('Email - Verify Access To Your Contact Details')) : otherNumber ? updateDataLayerObject(createDataLayerPage('Mobile - Verify Access To Your Contact Details')) : updateDataLayerObject(createDataLayerPage('Confirm Your Identity'));
        return (
            <Spacing bottom="spacing4x" left="spacing4x" right="spacing4x">
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <img className="porting-lock-image ml-0 mb-4" src={PortingLock} aria-hidden="true" alt="" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                </div>
                <div tabIndex="-1" className="modal-heading" id={this.props.readerId}>
                    <TextStyle tabIndex="-1" element={'h2'} alias="HeadingB">
                        {otherNumber || otherEmail ? 'Verify access to your contact details' : 'Confirm your Identity'}
                    </TextStyle>
                    <TextStyle className="send-code-info" alias="TextBodyShort">
                        {otherNumber || otherEmail
                            ? `We’ll send you a one-time code to ${otherNumber || otherEmail} to verify you have access to it.`
                            : `We’ll send a one-time code to ${contactDetails.length === 1 ? verifyId : ''} ${verifyId ? 'to' : ''} confirm your identity.`}
                    </TextStyle>
                </div>
                {!otherNumber && !otherEmail && contactDetails.length > 1 && (
                    <div className="mt-4">
                        <RadioGroup label="Select where to send code" name="confirmIdRadios" variant="Comfortable">
                            {phoneNumber && <RadioButton description={formatMobileNumber(phoneNumber || '')} checked={confirmIdentityOption === 'mobileNumber'} label="Mobile number" name="mobileNumber" value="mobileNumber" onChange={() => this.setState({ confirmIdentityOption: 'mobileNumber' })} />}
                            {contactEmail && <RadioButton description={contactEmail} checked={confirmIdentityOption === 'emailContact'} label="Email address" name="emailContact" value="emailContact" onChange={() => this.setState({ confirmIdentityOption: 'emailContact' })} />}
                        </RadioGroup>
                    </div>
                )}
                <SpinningButton id="verifyButton" onSubmit={this.validateContactMedium} stylingClass="w-100 mt-4" isDisabled={isProcessingOtp} isLoading={isProcessingOtp} buttonText="Send code" />
            </Spacing>
        );
    }

    validateIdentity(msisdn, errorContent) {
        const {
            validations: { isVerifyOTCInProgress },
            editContact: { hasOtpSent, isProcessingOtp },
            updateMobileStatus: { isMobileUpdateInProgress },
            toggleModal,
            otherEmail
        } = this.props;
        const {
            errors: { otc },
            otc: otcValue,
            otcMaskedValue,
            isResend
        } = this.state;
        otherEmail ? updateDataLayerObject(createDataLayerPage('Email - Enter Your One-Time Code')) : otcMaskedValue ? updateDataLayerObject(createDataLayerPage('Mobile - Enter Your One-Time Code')) : null;

        return (
            <Spacing left="spacing4x" right="spacing4x">
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <img className="porting-lock-image ml-0 mb-4" src={PortingLock} aria-hidden="true" alt="" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                </div>
                {isResend && hasOtpSent && !isProcessingOtp && (
                    <MessageSection
                        className="mb-3"
                        variant="Success"
                        developmentUrl={ableSpriteSheet}
                        description="We’ve resent your one-time code." />
                )}
                <div tabIndex="-1" className="modal-heading mt-3" id={this.props.readerId}>
                    <TextStyle tabIndex="-1" element={'h2'} alias="HeadingB">
                        Enter your one-time code
                    </TextStyle>
                    <TextStyle alias="TextBodyShort">
                        {`Please enter the 6-digit code we sent to ${otherEmail || otcMaskedValue}`}
                    </TextStyle>
                </div>
                <div className="w-100 mt-3">
                    {otcValue && errorContent && (
                        <div id="otc-error-message" role="alert">
                            <MessageSection
                                className="mbxl"
                                variant="Error"
                                developmentUrl={ableSpriteSheet}
                                titleText={errorContent.errorHeading}
                                description={errorContent.errorText} />
                        </div>
                    )}
                    <TextField
                        id="otcInput"
                        name="otcInput"
                        label="Enter 6 digit code"
                        size="Default"
                        required
                        aria-describedby={otcValue && errorContent ? "otc-error-message" : undefined}
                        defaultValue=""
                        invalidInputText={otc}
                        invalid={!!otc}
                        developmentUrl={getAbleSpriteSheet()}
                        maxLength={6}
                        value={otcValue}
                        onChange={this.onOtcChange}
                    />
                </div>
                <SpinningButton
                    id="verifyButton"
                    onSubmit={this.verifyOTC}
                    isDisabled={isVerifyOTCInProgress || isMobileUpdateInProgress}
                    isLoading={isVerifyOTCInProgress || isMobileUpdateInProgress}
                    buttonText="Verify"
                    className="mb-button"
                    stylingClass="w-100 my-4"
                />
                <div className="center">
                    <ActionButton
                        developmentUrl={ableSpriteSheet}
                        element="button"
                        label="Resend code"
                        id="resendBtn"
                        variant="LowEmphasis"
                        onClick={() => {
                            this.setState({ isResend: true, otc: '', errors: { otc: false } });
                            this.validateContactMedium();
                        }}
                        disabled={isProcessingOtp}
                        className="update-inline" />
                </div>
            </Spacing>
        );
    }

    validateContactMedium() {
        const { fetchOneTimeCode } = this.props.actions;
        const {
            appData: {
                customer: {
                    data: { phoneNumber, contactEmail, contactDetails = [] }
                }
            },
            otherEmail
        } = this.props;
        const otherNumber = this.state.newNumber;
        let payload = {};
        let contactMediumType = '';
        let contactMediumValue = '';
        let otcMaskedValue = '';
        const mobileNumber = otherNumber || phoneNumber;
        if (otherEmail) {
            payload = {
                contactMediumType: 'EMAIL',
                contactMediumValue: otherEmail
            };
        } else if (this.state.showScreen && this.state.showScreen === 'confirm identity') {
            const regex = /^\+61|^61/;
            const selectedOption = !otherNumber && contactDetails.length > 1 && document.querySelector('input[name="confirmIdRadios"]:checked').value;
            if (selectedOption && selectedOption === 'mobileNumber') {
                contactMediumType = 'SMS';
                contactMediumValue = mobileNumber.replace(regex, 0);
                otcMaskedValue = contactMediumValue;
            } else if (selectedOption && selectedOption === 'emailContact') {
                contactMediumType = 'EMAIL';
                contactMediumValue = contactEmail;
                otcMaskedValue = contactMediumValue;
            } else {
                contactMediumType = mobileNumber ? 'SMS' : 'EMAIL';
                contactMediumValue = mobileNumber ? mobileNumber.replace(regex, 0) : contactEmail;
                otcMaskedValue = mobileNumber || contactMediumValue;
            }
            payload = {
                contactMediumType,
                contactMediumValue
            };
            this.setState({
                otcOptionType: contactMediumType,
                otcOptionValue: contactMediumValue,
                otcMaskedValue
            });
        } else {
            const { otcOptionType, otcOptionValue } = this.state;
            payload = {
                contactMediumType: otcOptionType,
                contactMediumValue: otcOptionValue
            };
        }
        if (payload.contactMediumType === 'EMAIL') {
            payload.otcType = OTC_VERIFY_PURPOSE.PERSONAL_CONTACT_EMAIL_UPDATE;
        } else if (payload.contactMediumType === 'SMS') {
            payload.otcType = OTC_VERIFY_PURPOSE.PERSONAL_CONTACT_SMS_UPDATE;
        }
        fetchOneTimeCode(payload);
    }

    showFullModalAlert = (errorContent) => {
        return (
            <Spacing bottom="spacing4x" top="spacing4x" left="spacing4x" right="spacing4x">
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <Picto theme="blue">
                        <SomethingWentWrong104 />
                    </Picto>
                    {!errorContent.hideCloseButton && <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={this.closeErrorModal} />}
                </div>
                <div tabIndex="-1" className="modal-heading" id={this.props.readerId}>
                    {errorContent && errorContent.errorHeading && (
                        <TextStyle tabIndex="-1" element="h1" alias="HeadingA">
                            {errorContent.errorHeading}
                        </TextStyle>
                    )}
                    {errorContent && errorContent.errorText && (
                        <TextStyle alias="TextBodyShort" className="error-modal-text">
                            {errorContent.errorText}
                        </TextStyle>
                    )}
                    {errorContent && errorContent.errorTextLink && (
                        <ActionButton
                            element="button"
                            label={errorContent.errorTextLink}
                            variant="LowEmphasis"
                            onClick={errorContent.onClickErrorTextLink} />
                    )}
                </div>
            </Spacing>
        );
    }

    onNewMobileNumberChange({ target: { value } = {} } = {}) {
        if (isNaN(Number(value))) {
            return;
        }
        this.setState({ newNumber: value, errors: { mblNum: false } });
        this.resetErrors();
    }

    showUpdateScreen() {
        const {
            toggleModal,
            appData: {
                customer: {
                    data: { phoneNumber }
                },
                msisdn,
                uiState: { isEsimActivation }
            },
            updateMobileStatus: { isMobileUpdateInProgress },
            welcome: { portingNumber }
        } = this.props;
        const {
            errors: { mblNum },
            newNumber,
            confirmMobileOption
        } = this.state;
        const { selectedActivationFlow = '' } = getUiState();
        const isPortingFlow = selectedActivationFlow === 'portNumber';
        updateDataLayerObject(createDataLayerPage('Confirm Mobile Number'));

        const newNumberUpdateOnly = !!phoneNumber || this.state.isMBBActivation;
        const newNumberField = (
            <TextField
                id="newNumber"
                name="newNumber"
                label={newNumberUpdateOnly ? 'Mobile number' : 'Enter mobile number'}
                size="Default"
                required
                developmentUrl={getAbleSpriteSheet()}
                defaultValue=""
                value={newNumber}
                invalidInputText={mblNum}
                invalid={!!mblNum}
                maxLength={10}
                onChange={this.onNewMobileNumberChange}
            />
        );
        return (
            <Spacing bottom="spacing4x" left="spacing4x" right="spacing4x">
                <div className="modal-header-no-border ppv-header update-contact-modal-header update-screen-header">
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                </div>
                <div tabIndex="-1" className="modal-heading" id={this.props.readerId}>
                    <TextStyle element={'h2'} tabIndex="-1" alias="HeadingB" className="update-content-heading">
                        {newNumberUpdateOnly ? 'Enter mobile number' : 'Confirm mobile number'}
                    </TextStyle>
                </div>
                {
                    newNumberUpdateOnly
                        ? <div className="mt-3 mb-2">
                            {newNumberField}
                        </div>
                        : <React.Fragment>
                            <div className="mt-4">
                                <RadioGroup label="Select from the following options" name="mblRadios" variant="Comfortable">
                                    {!phoneNumber && (isEsimActivation || msisdn) ? (
                                        <RadioButton
                                            description={!isPortingFlow ? isEsimActivation ? '' : formatMobileNumber(msisdn || '') : formatMobileNumber(portingNumber || '')}
                                            checked={confirmMobileOption === 'activating'}
                                            label="The mobile number I'm activating"
                                            name="activating"
                                            value="activating"
                                            events={{
                                                onChange: () => {
                                                    this.setState({ confirmMobileOption: 'activating' });
                                                }
                                            }}
                                        />
                                    ) : (
                                        <React.Fragment />
                                    )}
                                    <RadioButton
                                        description=""
                                        checked={confirmMobileOption === 'newnumber'}
                                        events={{
                                            onChange: () => {
                                                this.setState({ confirmMobileOption: 'newnumber' });
                                            }
                                        }}
                                        label="Use a different number"
                                        name="newnumber"
                                        value="newnumber"
                                    />
                                </RadioGroup>
                            </div>
                            {confirmMobileOption === 'newnumber' && (
                                <div className="mt-3">
                                    {newNumberField}
                                </div>
                            )}
                        </React.Fragment>
                }
                <SpinningButton
                    onSubmit={this.verifyMobileNumber}
                    buttonText="Continue"
                    className="mb-button"
                    stylingClass="w-100 mt-3"
                    isDisabled={isMobileUpdateInProgress}
                    isLoading={isMobileUpdateInProgress}
                />
            </Spacing>
        );
    }
}

export default UpdateContactModal;
