import React from 'react';
import { Error } from '../';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { TextStyle } from '@able/react';

/* eslint-disable */
class BirthDate extends React.Component {
    static checkValue(str, max) {
        let num;
        if (str.charAt(0) !== '0' || str === '00') {
            num = parseInt(str, 10);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
        }
        return str;
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { meta: { touched = false, error: latestError, submitFailed = false } = {}, fieldType } = nextProps;
        const fieldTypeVal = fieldType === 'date';
        if (submitFailed && touched && latestError && !fieldTypeVal) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', latestError);
        }
    }

    render() {
        const { input, label, autocomplete, meta = {}, skipDay, ...custom } = this.props;
        const { touched = false, error, submitFailed = false } = meta;
        const { value, name } = input;
        const placeholder = skipDay ? 'Eg : MM/YYYY' : 'Eg : DD/MM/YYYY';
        const inputClassName = touched && error ? `has-error` : '';
        return (
            <div className={`form-element ${custom.wrapperClassName}`}>
                <TextStyle element="label" alias="Label" htmlFor={name}>
                    {label}
                </TextStyle>
                <input
                    {...input}
                    value={value}
                    type="text"
                    id={name}
                    autoComplete={autocomplete}
                    className={`${inputClassName} ${custom.className}`}
                    onChange={(e) => this.handleChange(e, skipDay)}
                    onBlur={() => this.handleBlur(input.value, skipDay)}
                    placeholder={''}
                    aria-describedby={`${name}-description ${name}-success-description ${name}-error-description`}
                />
                {touched && error ? (
                    <Error errorDescriptionId={name} isValidationError errorText={error} errorIconClassName={'icon-alert-solid-round'} />
                ) : (
                    <TextStyle element="p" alias="FinePrint" colour="Subtle" id={`${name}-description`} aria-live="polite" aria-atomic="true">
                        {placeholder}
                    </TextStyle>
                )}
            </div>
        );
    }

    handleChange(event, skipDay = false) {
        let output;
        let result;
        const {
            input: { onChange }
        } = this.props;

        this.type = 'text';
        let input = event.target.value;
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);

        var values = input.split('/').map(function (v) {
            return v.replace(/\D/g, '');
        });

        if (skipDay) {
            if (values[0]) values[0] = BirthDate.checkValue(values[0], 12);
            output = values.map(function (v, i) {
                return v.length == 2 && i < 1 ? v + ' / ' : v;
            });
            result = output.join('').substr(0, 9);
        } else {
            if (values[0]) values[0] = BirthDate.checkValue(values[0], 31);
            if (values[1]) values[1] = BirthDate.checkValue(values[1], 12);
            output = values.map(function (v, i) {
                return v.length == 2 && i < 2 ? v + ' / ' : v;
            });
            result = output.join('').substr(0, 14);
        }

        this.value = result;
        onChange(result);
    }

    handleBlur(input, skipDay) {
        const {
            input: { onBlur }
        } = this.props;

        this.type = 'text';
        const values = input.split('/').map((v) => {
            return v.replace(/\D/g, '');
        });
        let output = '';
        if (values.length === 3) {
            const year = values[2].length !== 4 ? parseInt(values[2], 10) + 2000 : parseInt(values[2], 10);
            const month = parseInt(values[1], 10) - 1;
            const day = parseInt(values[0], 10);
            const d = new Date(year, month, day);
            if (!isNaN(d)) {
                const dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
                output = dates
                    .map((v) => {
                        return v.toString().length === 1 ? '0' + v : v;
                    })
                    .join(' / ');
            }
        } else if (values.length === 2 && skipDay) {
            const year = values[1].length !== 4 ? parseInt(values[1], 10) + 2000 : parseInt(values[1], 10);
            const month = parseInt(values[0], 10) - 1;
            const d = new Date(year, month);
            if (!isNaN(d)) {
                const dates = [d.getMonth() + 1, d.getFullYear()];
                output = dates
                    .map((v) => {
                        return v.toString().length === 1 ? '0' + v : v;
                    })
                    .join(' / ');
            }
        }
        onBlur(output);
    }
}

export default BirthDate;
