import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isValidBusinessPhoneNumber } from "../../../../adapters/validations";
import EditFieldModal from "../../../../common/components/editFieldModal/editFieldModal";
import { AbleTextField } from "../../../../components/form/AbleTextField";
import { updateCustomerBusinessData } from "../../../app/actions";
import CONTACT_PAGE_CONSTANTS from "../../constants";
import { updateBusinessContactMedium } from "../../module";
import { addDataLayerEventInfo } from "../../../../adapters/analytics-utils";

const { businessContactForm: { Phone } } = CONTACT_PAGE_CONSTANTS;

const View = ({
    closeModal,
    selectedBusiness,
    updateBusinessContactsMeta = {},
    actions: {
        updateContactMediumAction,
        updateCustomerBusinessDataAction
    } }) => {
    const inputRef = useRef();
    const [newPhone, setNewPhone] = useState('');
    const [newPhoneMeta, setNewPhoneMeta] = useState({ error: '', valid: false, touched: false });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (submitted && newPhoneMeta.error && newPhoneMeta.error !== '') {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', newPhoneMeta.error );
            setSubmitted(false);
        }
    }, [submitted, newPhoneMeta.error]);

    const checkIsValid = (value = newPhone) => {
        let error;
        if (isEmpty(value)) {
            error = Phone.errorMessages.emptyField;
        } else if (!isValidBusinessPhoneNumber(value)) {
            error = Phone.errorMessages.invalidField;
        }
        setNewPhoneMeta({ error, touched: true, valid: !error });
        return !error;
    }

    const onChange = ({ target: { value } }) => {
        setNewPhone(value);
        if (newPhoneMeta.touched) checkIsValid(value);
    }

    const onSubmit = () => {
        setSubmitted(true);
        if (checkIsValid()) {
            //todo - show toast after successful update
            const payload = {
                data: {
                    activatingMsisdn: false,
                    contactMediumType: 'Telephone',
                    contactMediumValue: newPhone,
                    businessContacts: true,
                    accountNumber: selectedBusiness
                }
            };
            updateContactMediumAction(payload, ({ success }) => {
                if (success) {
                    updateCustomerBusinessDataAction(selectedBusiness, { businessPhoneNumber: newPhone });
                    closeModal();
                }
            });
        } else inputRef.current.focus();
    }

    return (
        <EditFieldModal
            id="edit-biz-phone"
            closeModal={closeModal}
            modalTitle='Enter new contact number'
            onContinue={onSubmit}
            loading={updateBusinessContactsMeta.updating}
            isOpen>
            <AbleTextField
                meta={{
                    touched: newPhoneMeta.touched,
                    error: newPhoneMeta.error
                }}
                input={{
                    required: true,
                    ref: inputRef,
                    onChange,
                    onBlur: () => checkIsValid(),
                    value: newPhone
                }}
                helpText={Phone.helpText}
                maxLength={100}
                id={Phone.name}
                label={Phone.label} />
        </EditFieldModal>
    );
}

export const UpdatePhoneModal = connect(state => ({
    updateBusinessContactsMeta: state.yourContactDetails.updateBusinessContactsMeta,
    selectedBusiness: state.yourContactDetails.selectedBusiness.selectedBusiness
}), dispatch => ({
    actions: bindActionCreators({
        updateContactMediumAction: updateBusinessContactMedium,
        updateCustomerBusinessDataAction: updateCustomerBusinessData
    }, dispatch)
}))(View);
