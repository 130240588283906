import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NONPHYSICAL_ADDRESS_ERROR } from '../../../../adapters/errorCode';
import EditFieldModal from '../../../../common/components/editFieldModal/editFieldModal';
import { updateCustomerBusinessData } from '../../../app/actions';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { updateBusinessContactMedium, verifyBusinessAddress } from '../../module';
import { AddressAutoComplete } from '../addressAutoComplete/addressAutoComplete';
import { addDataLayerEventInfo } from '../../../../adapters/analytics-utils';

const { businessContactForm: { Address } } = CONTACT_PAGE_CONSTANTS;

export const View = ({
    closeModal,
    selectedBusiness,
    updateBusinessContactsMeta = {},
    addressVerificationMeta = {},
    actions: {
        verifyBusinessAddressAction,
        updateContactMediumAction,
        updateCustomerBusinessDataAction
    }
}) => {
    const inputRef = useRef();
    const [newAddress, setNewAddress] = useState();
    const [newAddressMeta, setNewAddressMeta] = useState({ error: '', valid: false, touched: false });

    const checkIsValid = (address = newAddress) => {
        let error;
        if (!(address && address.value)) {
            error = Address.errorMessages.emptyField;
        }
        setNewAddressMeta({ error, touched: true, valid: !error });
        return !error;
    };

    const onChange = (address) => {
        setNewAddress(address);
        if (newAddressMeta.touched) checkIsValid(address);
    };

    const onSubmit = () => {
        if (checkIsValid()) {
            verifyBusinessAddressAction({ addressLine1: newAddress.value }, ({ hasError, errorCode, data }) => {
                if (!hasError && data && data.addressId) {
                    const payload = {
                        data: {
                            activatingMsisdn: false,
                            contactMediumType: 'Address',
                            contactMediumValue: data.addressId,
                            businessContacts: true,
                            accountNumber: selectedBusiness
                        }
                    };
                    updateContactMediumAction(payload, ({ success }) => {
                        if (success) {
                            updateCustomerBusinessDataAction(selectedBusiness, {
                                businessAddressId: data.addressId,
                                addressline1: data.addressline1,
                                addressline2: data.addressline2,
                                localityName: data.localityName,
                                state: data.state,
                                postcode: data.postcode
                            });
                            closeModal();
                        }
                    });
                } else if (hasError && errorCode === NONPHYSICAL_ADDRESS_ERROR) {
                    setNewAddressMeta({ ...newAddressMeta, addressValidationError: true, touched: true, error: Address.errorMessages.nonPhysicalAddress });
                    setNewAddress();
                    inputRef.current.focus();
                }
            });
        } else {
            inputRef.current.focus();
            addDataLayerEventInfo('error', 'Inline', 'unavailable', Address.errorMessages.emptyField );
        }
    };

    return (
        <EditFieldModal
            id="edit-biz-address"
            closeModal={closeModal}
            modalTitle="Enter new address"
            onContinue={onSubmit}
            loading={updateBusinessContactsMeta.updating || addressVerificationMeta.verifying}
            isOpen
        >
            <AddressAutoComplete
                label={Address.label}
                name={Address.name}
                formSubmitError={newAddressMeta.addressValidationError}
                assistiveText={Address.helpText}
                id={Address.name}
                input={{
                    required: true,
                    ref: inputRef,
                    onChange,
                    value: newAddress
                }}
                meta={{
                    touched: newAddressMeta.touched,
                    error: newAddressMeta.error
                }}
            />
        </EditFieldModal>
    );
};


export const UpdateAddressModal = connect(state => ({
    updateBusinessContactsMeta: state.yourContactDetails.updateBusinessContactsMeta,
    addressVerificationMeta: state.yourContactDetails.addressVerificationMeta,
    selectedBusiness: state.yourContactDetails.selectedBusiness.selectedBusiness
}), dispatch => ({
    actions: bindActionCreators({
        verifyBusinessAddressAction: verifyBusinessAddress,
        updateContactMediumAction: updateBusinessContactMedium,
        updateCustomerBusinessDataAction: updateCustomerBusinessData
    }, dispatch)
}))(View);
