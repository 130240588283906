import './../reviewdetails.css';
import React from 'react';
import { TextStyle } from '@able/react';
import tick from '../../../assets/img/svg/icon-controls-ui-tick.svg';
import { formatMobileNumber } from '../../../adapters/utils';

const OfferCard = (props) => {
    const value = props.price ? props.price : props.starterCredit;
    const amount = props.skipRecharge ? '$0 recharge' : `$${value}`;
    const price = (
        <TextStyle role="listitem" alias="TextBodyShort" className="mbm">
            <img className="custom-tick" src={tick} alt="tick" />
            {props.plan.firstTimePrice ? props.plan.firstTimePrice : amount}
        </TextStyle>
    );

    let usageQuota;
    if (props.plan && props.plan.usageQuota && props.price !== 0) {
        usageQuota = (
            <React.Fragment>
                {props.plan.usageQuota.map((quota) => {
                    return (
                        <TextStyle role="listitem" alias="TextBodyShort" className="mbm d-flex">
                            <img className="custom-tick" src={tick} alt="tick" />
                            {quota}
                        </TextStyle>
                    );
                })}
            </React.Fragment>
        );
    }

    let planCondition;
    if (props.plan && props.plan.planCondition && props.price !== 0) {
        planCondition = (
            <TextStyle alias="TextBodyShort" className="small-font mbm">
                {props.plan.planCondition}
            </TextStyle>
        );
    }
    const cardHeading1 = 'Your service number';
    const defaultPlan = props.appData.uiState.selectedCurrentPlan;
    const planText = props.skipRecharge ? `Your ${defaultPlan} plan includes` : 'Your recharge includes';
    const cardHeading2 = props.confirm && !props.skipRecharge ? 'Your recharge includes' : planText;
    const discount = props.plan.firstTimeDiscountPercentage || props.plan.firstTimeDiscountPrice;
    return (
        <div tabIndex="-1" className="payment-card offer-card-details">
            <div>
                <TextStyle className="mbm" element="h2" alias="HeadingD">
                    {cardHeading1}
                </TextStyle>
                <TextStyle className="mbm" alias="TextBodyShort">
                    {formatMobileNumber(props.msisdn)}
                </TextStyle>
            </div>
            <div>
                <TextStyle className="mbm" element="h2" alias="HeadingD">
                    {cardHeading2}
                </TextStyle>
                <div role="list">
                    {props.plan && props.plan.promotion && props.plan.includedBonus && props.price !== 0 && (
                        <TextStyle role="listitem" className="mbm bonus-text d-flex" alias="HeadingD">
                            <img className="custom-tick" src={tick} alt="tick" /> {`${props.plan.includedBonus}`}
                        </TextStyle>
                    )}
                    {discount && (
                        <TextStyle role="listitem" className="mbm bonus-text d-flex" alias="HeadingD">
                            <img className="custom-tick" src={tick} alt="tick" /> {discount} OFF
                        </TextStyle>
                    )}
                    {props.starterCredit !== 1 && price}
                    {props.plan && props.plan.planHeading && props.price !== 0 && (
                        <TextStyle role="listitem" className="mbm d-flex" alias="TextBodyShort">
                            <img className="custom-tick" src={tick} alt="tick" />
                            {props.plan.planHeading}
                        </TextStyle>
                    )}
                    {usageQuota}
                    {planCondition}
                    {props.plan.promotionalTemplate && props.price !== 0 && (
                        <TextStyle role="listitem" className="small-font mbm" alias="TextBodyShort">
                            {props.plan.promotionalTemplate}
                        </TextStyle>
                    )}
                    {props.plan.firstTimeTemplate && (
                        <TextStyle role="listitem" className="small-font mbm" alias="TextBodyShort">
                            {props.plan.firstTimeTemplate}
                        </TextStyle>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OfferCard;
