import { connect } from 'react-redux';
import Progress from './progress';

const mapStateToProps = (state) => {
    return {
        stepperBar: state.stepperBar,
        hasErrorOccurred: state.app.hasErrorOccurred,
        hasServiceError: state.app.hasServiceError,
        appData: state.app.appData,
        yourContactDetails: state.yourContactDetails,
        router: state.router
    };
};

const progress = connect(mapStateToProps, null)(Progress);

export default progress;
