import React from 'react';
import { ActionButton } from '@able/react';
import { getAbleSpriteSheet, route } from '../../adapters/utils';
import store from '../../store';
import { GO_BACK } from '../../routes/actions';
import './go-back.css';

export const GoBackButton = ({ ...props }) => {
    const back = (e) => {
        e.preventDefault();
        const { path = '/welcome', goPrevious = false } = props;
        if (goPrevious) {
            const requestPath = window.location.href;
            if (requestPath[requestPath.length - 1] === '#') window.history.go(-2);
            else window.history.go(-1);
        } else {
            store.dispatch({ type: GO_BACK, path });
            route(path);
        }
    };

    return (
        <nav className="go-back-btn-nav">
            <ActionButton
                developmentUrl={getAbleSpriteSheet()}
                icon="ChevronLeft"
                variant="LowEmphasis"
                element="button"
                onClick={back}
                className="nav-link-back"
                label="Back"
            />
        </nav>
    );
};

export default GoBackButton;
