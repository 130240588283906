import { email, isEmpty, isEmailFormatValid } from '../../../../adapters/validations';
import { getUiState } from '../../../../adapters/store-utils';

export const validateEmail = (value) => {
    const { isEsimActivation = false } = getUiState() || {};
    if (isEmpty(value)) {
        return 'Required field. Please enter an email address.';
    }

    if (!isEmailFormatValid(value)) {
        return 'Invalid input. Please enter a valid email address.';
    }

    if (email(value, isEsimActivation)) {
        return 'Invalid email domain. Please enter a valid email address.';
    }

    return '';
};
