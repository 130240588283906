export const PAYPAL_PAYMENT_INIT_DONE = 'PAYPAL_PAYMENT_INIT_DONE';
export const PAYPAL_PAYMENT_INIT_ERROR = 'PAYPAL_PAYMENT_INIT_ERROR';
export const PAYPAL_PAYMENT_SUCCESS = 'PAYPAL_PAYMENT_SUCCESS';
export const PAYPAL_PAYMENT_ERROR = 'PAYPAL_PAYMENT_ERROR';
export const BILLPAY_INIT_DONE = 'BILLPAY_INIT_DONE';
export const BILLPAY_INIT_ERROR = 'BILLPAY_INIT_ERROR';
export const BILLPAY_PAYMENT_SUCCESS = 'BILLPAY_PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const VISACHECKOUT_INIT_DONE = 'VISACHECKOUT_INIT_DONE';
export const VISACHECKOUT_INIT_ERROR = 'VISACHECKOUT_INIT_ERROR';
export const VISACHECKOUT_PAYMENT_SUCCESS = 'VISACHECKOUT_PAYMENT_SUCCESS';
export const VISACHECKOUT_PAYMENT_ERROR = 'VISACHECKOUT_PAYMENT_ERROR';
export const WELCOME_PAGE_TITLE = 'Activate Telstra Pre-Paid & Mobile SIM Online';
export const BUSINESS = 'BUSINESS';
export const SOLETRADER = 'SOLETRADER';
export const BRAINTREE_DEVICE_DATA = 'BRAINTREE_DEVICE_DATA';
export const BILLPAY_HOSTED_FIELD_INITIALISED = 'BILLPAY_HOSTED_FIELD_INITIALISED';
export const BILLPAY_HOSTED_FIELD_EMPTY = 'BILLPAY_HOSTED_FIELD_EMPTY';
export const BILLPAY_CARD_ERROR = 'BILLPAY_CARD_ERROR';
export const FETCH_CARD_LIST_SUCCESS = 'FETCH_CARD_LIST_SUCCESS';
export const FETCH_CARD_LIST_FAILURE = 'FETCH_CARD_LIST_FAILURE';
export const HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME = 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME';
export const HOSTED_FIELDS_INVALID_FIELD_SELECTOR = 'HOSTED_FIELDS_INVALID_FIELD_SELECTOR';
export const BRAINTREE_CC_VALIDATION_SUCCESS = 'BRAINTREE_CC_VALIDATION_SUCCESS';
export const SAVED_CARD_AUTORECHARGE = 'SAVED_CARD_AUTORECHARGE';
export const AUTO_RECHARGE_ERROR = 'AUTO_RECHARGE_ERROR';
// reutrn base path based on AEM environment for authoring
// const { isAuthor = false } = window.tcom || {};
// const { reactBasePath = '', authorBasePath = '' } = window.appConfig || {};
// export const REACT_BASE_PATH = isAuthor ? authorBasePath : reactBasePath;

export const callingModuleMeta = {
    address: 'address-details',
    customerDetails: 'customer-details',
    authorisedRepresentative: 'authorised-representative',
    contactDetails: 'contact-details'
};

export const STORE_STATE_AND_LOGIN = 'STORE_STATE_AND_LOGIN';
export const RESET_PERSISTOR = 'RESET_PERSISTOR';
export const STORE_STATE_AND_LOGIN_MFA = 'STORE_STATE_AND_LOGIN_MFA'

export const identityDetailsMeta = {
    drivingLicense: 'drivingLicenseForm',
    australianPassport: 'australianPassportForm',
    australianVisa: 'australianVisaForm',
    medicareCard: 'medicareForm'
};

export const countries = [
    { value: 'AFG', label: 'AFGHANISTAN' },
    { value: 'ALB', label: 'ALBANIA' },
    { value: 'DZA', label: 'ALGERIA' },
    { value: 'AND', label: 'ANDORRA' },
    { value: 'AGO', label: 'ANGOLA' },
    { value: 'ATG', label: 'ANTIGUA AND BARBUDA' },
    { value: 'ARG', label: 'ARGENTINA' },
    { value: 'ARM', label: 'ARMENIA' },
    { value: 'AUS', label: 'AUSTRALIA' },
    { value: 'AUT', label: 'AUSTRIA' },
    { value: 'AZE', label: 'AZERBAIJAN' },
    { value: 'BHS', label: 'BAHAMAS' },
    { value: 'BHR', label: 'BAHRAIN' },
    { value: 'BGD', label: 'BANGLADESH' },
    { value: 'BRB', label: 'BARBADOS' },
    { value: 'BLR', label: 'BELARUS' },
    { value: 'BEL', label: 'BELGIUM' },
    { value: 'BLZ', label: 'BELIZE' },
    { value: 'BEN', label: 'BENIN' },
    { value: 'BTN', label: 'BHUTAN' },
    { value: 'BOL', label: 'BOLIVIA' },
    { value: 'BIH', label: 'BOSNIA AND HERZEGOVINA' },
    { value: 'BWA', label: 'BOTSWANA' },
    { value: 'BRA', label: 'BRAZIL' },
    { value: 'BRN', label: 'BRUNEI DARUSSALAM' },
    { value: 'BGR', label: 'BULGARIA' },
    { value: 'BFA', label: 'BURKINA FASO' },
    { value: 'BDI', label: 'BURUNDI' },
    { value: 'CPV', label: 'CABO VERDE' },
    { value: 'KHM', label: 'CAMBODIA' },
    { value: 'CMR', label: 'CAMEROON' },
    { value: 'CAN', label: 'CANADA' },
    { value: 'CAF', label: 'CENTRAL AFRICAN REPUBLIC' },
    { value: 'TCD', label: 'CHAD' },
    { value: 'CHL', label: 'CHILE' },
    { value: 'CHN', label: 'CHINA' },
    { value: 'COL', label: 'COLOMBIA' },
    { value: 'COM', label: 'COMOROS' },
    { value: 'COG', label: 'CONGO REPUBLIC OF' },
    { value: 'COK', label: 'COOK ISLANDS' },
    { value: 'CRI', label: 'COSTA RICA' },
    { value: 'CIV', label: "IVORY COAST" },
    { value: 'HRV', label: 'CROATIA' },
    { value: 'CUB', label: 'CUBA' },
    { value: 'CYP', label: 'CYPRUS' },
    { value: 'CZE', label: 'CZECHIA' },
    { value: 'PRK', label: 'DEMOCRATIC PEOPLES REPUBLIC OF KOREA' },
    { value: 'COD', label: 'DEMOCRATIC REPUBLIC OF THE CONGO' },
    { value: 'DNK', label: 'DENMARK' },
    { value: 'DJI', label: 'DJIBOUTI' },
    { value: 'DMA', label: 'DOMINICA' },
    { value: 'DOM', label: 'DOMINICAN REPUBLIC' },
    { value: 'ECU', label: 'ECUADOR' },
    { value: 'EGY', label: 'EGYPT' },
    { value: 'SLV', label: 'EL SALVADOR' },
    { value: 'GNQ', label: 'EQUATORIAL GUINEA' },
    { value: 'ERI', label: 'ERITREA' },
    { value: 'EST', label: 'ESTONIA' },
    { value: 'SWZ', label: 'ESWATINI' },
    { value: 'ETH', label: 'ETHIOPIA' },
    { value: 'FJI', label: 'FIJI' },
    { value: 'FIN', label: 'FINLAND' },
    { value: 'FRA', label: 'FRANCE' },
    { value: 'GAB', label: 'GABON' },
    { value: 'GMB', label: 'GAMBIA' },
    { value: 'GEO', label: 'GEORGIA' },
    { value: 'D', label: 'GERMANY' },
    { value: 'GHA', label: 'GHANA' },
    { value: 'GRC', label: 'GREECE' },
    { value: 'GRD', label: 'GRENADA' },
    { value: 'GTM', label: 'GUATEMALA' },
    { value: 'GIN', label: 'GUINEA' },
    { value: 'GNB', label: 'GUINEA-BISSAU' },
    { value: 'GUY', label: 'GUYANA' },
    { value: 'HTI', label: 'HAITI' },
    { value: 'HND', label: 'HONDURAS' },
    { value: 'HKG', label: 'HONG KONG' },
    { value: 'HUN', label: 'HUNGARY' },
    { value: 'ISL', label: 'ICELAND' },
    { value: 'IND', label: 'INDIA' },
    { value: 'IDN', label: 'INDONESIA' },
    { value: 'IRN', label: 'IRAN' },
    { value: 'IRQ', label: 'IRAQ' },
    { value: 'IRL', label: 'IRELAND' },
    { value: 'ISR', label: 'ISRAEL' },
    { value: 'ITA', label: 'ITALY' },
    { value: 'JAM', label: 'JAMAICA' },
    { value: 'JPN', label: 'JAPAN' },
    { value: 'JOR', label: 'JORDAN' },
    { value: 'KAZ', label: 'KAZAKHSTAN' },
    { value: 'KEN', label: 'KENYA' },
    { value: 'KIR', label: 'KIRIBATI' },
    { value: 'KWT', label: 'KUWAIT' },
    { value: 'KGZ', label: 'KYRGYZSTAN' },
    { value: 'LAO', label: 'LAO PEOPLES DEMOCRATIC REPUBLIC' },
    { value: 'LVA', label: 'LATVIA' },
    { value: 'LBN', label: 'LEBANON' },
    { value: 'LSO', label: 'LESOTHO' },
    { value: 'LBR', label: 'LIBERIA' },
    { value: 'LBY', label: 'LIBYA' },
    { value: 'LTU', label: 'LITHUANIA' },
    { value: 'LUX', label: 'LUXEMBOURG' },
    { value: 'MDG', label: 'MADAGASCAR' },
    { value: 'MWI', label: 'MALAWI' },
    { value: 'MYS', label: 'MALAYSIA' },
    { value: 'MDV', label: 'MALDIVES' },
    { value: 'MLI', label: 'MALI' },
    { value: 'MLT', label: 'MALTA' },
    { value: 'MHL', label: 'MARSHALL ISLANDS' },
    { value: 'MRT', label: 'MAURITANIA' },
    { value: 'MUS', label: 'MAURITIUS' },
    { value: 'MEX', label: 'MEXICO' },
    { value: 'FSM', label: 'MICRONESIA' },
    { value: 'MCO', label: 'MONACO' },
    { value: 'MNG', label: 'MONGOLIA' },
    { value: 'MNE', label: 'MONTENEGRO' },
    { value: 'MAR', label: 'MOROCCO' },
    { value: 'MOZ', label: 'MOZAMBIQUE' },
    { value: 'MMR', label: 'MYANMAR' },
    { value: 'NAM', label: 'NAMIBIA' },
    { value: 'NRU', label: 'NAURU' },
    { value: 'NPL', label: 'NEPAL' },
    { value: 'NLD', label: 'NETHERLANDS' },
    { value: 'NZL', label: 'NEW ZEALAND' },
    { value: 'NIC', label: 'NICARAGUA' },
    { value: 'NER', label: 'NIGER' },
    { value: 'NGA', label: 'NIGERIA' },
    { value: 'MKD', label: 'NORTH MACEDONIA' },
    { value: 'NOR', label: 'NORWAY' },
    { value: 'OMN', label: 'OMAN' },
    { value: 'PAK', label: 'PAKISTAN' },
    { value: 'PLW', label: 'PALAU' },
    { value: 'PAN', label: 'PANAMA' },
    { value: 'PNG', label: 'PAPUA NEW GUINEA' },
    { value: 'PRY', label: 'PARAGUAY' },
    { value: 'PER', label: 'PERU' },
    { value: 'PHL', label: 'PHILIPPINES' },
    { value: 'POL', label: 'POLAND' },
    { value: 'PRT', label: 'PORTUGAL' },
    { value: 'QAT', label: 'QATAR' },
    { value: 'KOR', label: 'REPUBLIC OF KOREA' },
    { value: 'MDA', label: 'REPUBLIC OF MOLDOVA' },
    { value: 'ROU', label: 'ROMANIA' },
    { value: 'RUS', label: 'RUSSIAN FEDERATION' },
    { value: 'RWA', label: 'RWANDA' },
    { value: 'KNA', label: 'SAINT KITTS AND NEVIS' },
    { value: 'LCA', label: 'SAINT LUCIA' },
    { value: 'VCT', label: 'SAINT VINCENT AND THE GRENADINES' },
    { value: 'WSM', label: 'SAMOA' },
    { value: 'SMR', label: 'SAN MARINO' },
    { value: 'STP', label: 'SAO TOME & PRINCIPE' },
    { value: 'SAU', label: 'SAUDI ARABIA' },
    { value: 'SEN', label: 'SENEGAL' },
    { value: 'SRB', label: 'SERBIA' },
    { value: 'SYC', label: 'SEYCHELLES' },
    { value: 'SLE', label: 'SIERRA LEONE' },
    { value: 'SGP', label: 'SINGAPORE' },
    { value: 'SVK', label: 'SLOVAKIA' },
    { value: 'SVN', label: 'SLOVENIA' },
    { value: 'SLB', label: 'SOLOMON ISLANDS' },
    { value: 'SOM', label: 'SOMALIA' },
    { value: 'ZAF', label: 'SOUTH AFRICA' },
    { value: 'SSD', label: 'SOUTH SUDAN' },
    { value: 'ESP', label: 'SPAIN' },
    { value: 'LKA', label: 'SRI LANKA' },
    { value: 'SDN', label: 'SUDAN' },
    { value: 'SUR', label: 'SURINAME' },
    { value: 'SWE', label: 'SWEDEN' },
    { value: 'CHE', label: 'SWITZERLAND' },
    { value: 'SYR', label: 'SYRIAN ARAB REPUBLIC' },
    { value: 'TWN', label: 'TAIWAN' },
    { value: 'TJK', label: 'TAJIKISTAN' },
    { value: 'THA', label: 'THAILAND' },
    { value: 'TMP', label: 'TIMOR-LESTE' },
    { value: 'TGO', label: 'TOGO' },
    { value: 'TON', label: 'TONGA' },
    { value: 'TTO', label: 'TRINIDAD AND TOBAGO' },
    { value: 'TUN', label: 'TUNISIA' },
    { value: 'TUR', label: 'TURKEY' },
    { value: 'TKM', label: 'TURKMENISTAN' },
    { value: 'TUV', label: 'TUVALU' },
    { value: 'UGA', label: 'UGANDA' },
    { value: 'UKR', label: 'UKRAINE' },
    { value: 'ARE', label: 'UNITED ARAB EMIRATES' },
    { value: 'GBR', label: 'UNITED KINGDOM' },
    { value: 'TZA', label: 'UNITED REPUBLIC OF TANZANIA' },
    { value: 'USA', label: 'UNITED STATES' },
    { value: 'URY', label: 'URUGUAY' },
    { value: 'UZB', label: 'UZBEKISTAN' },
    { value: 'VUT', label: 'VANUATU' },
    { value: 'VEN', label: 'VENEZUELA' },
    { value: 'VNM', label: 'VIET NAM' },
    { value: 'YEM', label: 'YEMEN' },
    { value: 'ZMB', label: 'ZAMBIA' },
    { value: 'ZWE', label: 'ZIMBABWE' }
];

export const dataLayerEventActions = {
    // general
    PAGE_VIEW: 'pageView',
    ERROR: 'error',
    OFFER_CLICKED: 'offerClicked',
    CLICK: 'click',
    // welcome page
    WELCOME_PG: 'welcomePg',
    OPT_IN_Checkbox: 'optInCheckbox',
    // eSIM eligibility
    ESIM_OFFER_ELIGIBLE: 'eligibleForEsimOffer',
    ESIM_OFFER_NOT_ELIGIBLE: 'notEligibleForEsimOffer',
    ORDER_CONFIRM_PAGE: 'orderConfirmPg',
    // Sim details page
    DETAILS_PG: 'detailsPg',
    ELIGIBLE_FOR_ACTIVATION: 'eligibleForActivation',
    ESIM_ELIGIBLE_FOR_ACTIVATION: 'eSimEligibleForActivation',
    // Keep number page
    EXISTING_NUMBER_PG: 'existingNumberPg',
    PORTING_TERMS_PG: 'portingTermsPg',
    // Id details page
    CREDITCHECK_PG: 'creditcheckPg',
    BUSINESS_NAME_ENTERED: 'businessNameEntered',
    // Contact details page
    CONTACT_DETAILS_PG: 'contactDetailsPg',
    ADDRESS_DETAILS_PG: 'addressDetailsPg',
    EMAIL_ENTERED: 'emailEntered',
    // Offer details page
    OFFERDETAILS_PG: 'offerDetailsPg',
    SKIP_STEP: 'skipStep',
    PROD_VIEW: 'prodView',
    OPTED_FOR_AUTORECHARGE: 'optedForAutorecharge',
    DECLINED_AUTORECHARGE: 'declinedAutorecharge',
    // Payment page
    PAYMENT_PG: 'paymentPg',
    PAYPAL_PAYMENT_METHOD: 'paypalPaymentMethod',
    CARD_PAYMENT_METHOD: 'cardPaymentMethod',
    CREDIT_CARD: 'Credit card',
    PAYPAL: 'PayPal',
    // Confirm details page
    ORDERCONFIRM_PG: 'orderconfirmPg',
    // Confirmation page
    PREPAIDACTIVATION: 'prepaidactivation'
};

export const userProfileInfoMeta = {
    BASE_PRICE: 'basePrice',
    CART_TOTAL: 'cartTotal',
    IDENTITY_TYPE: 'identityType',
    LOGGED_IN_USING: 'loggedInUsing',
    ABN_ACN: 'abnAcn',
    COMPANY_NAME: 'companyName',
    PAYMENT_TYPE: 'paymentType',
    USER_CONTACT_UUID: 'userContactUUID',
    SOURCE_SYSTEM: 'sourceSystem',
    USER_TYPE:'userType',
    USER_PROFILE_TYPE: 'userProfileType'
};

export const IdTypeDataLayerMap = {
    AUS_DRIVERS_LICENSE: 'Drivers License',
    AUS_PASSPORT: 'Passport',
    MEDICARE_CARD: 'Medicare',
    AUS_VISA: 'Visa',
    IMMI_CARD: 'ImmiCard'
};

/* eslint-disable max-len */
export const FORGOT_TELSTRA_URL =
    'https://myid.telstra.com/forgottenUsername/?clientCode=b2c-prepaidactivation&gotoUrl=https://myid.telstra.com/identity/as/authorization.oauth2?response_type=token&client_id=b2c-prepaidactivation&redirect_uri=https://www.my.telstra.com.au/myaccount/home=openid+profile';
export const REGISTER_TELSTRA_URL = 'https://myid.telstra.com/register/?clientCode=b2c-prepaidactivation&gotoUrl=https://www.my.telstra.com.au/myaccount/home';
export const DOWNLOAD_APP_IOS = 'https://apps.apple.com/au/app/my-telstra/id543829966';
export const DOWNLOAD_APP_ANDROID = 'https://play.google.com/store/apps/details?id=com.telstra.mobile.android.mytelstra&hl=en_AU&gl=US';
export const GET_THE_APP = 'https://www.telstra.com.au/mytelstra/';
export const SIGN_IN = 'https://open.mytelstra.app/BJRB/e10d2cfa';
export const CONTACT_US = 'https://www.telstra.com.au/contact-us';
export const ANDROID_MYT_PROFILE = 'mytelstra://activate.esim?activationCode=';
export const IOS_MYT_PROFILE_DEV = 'com.telstra.dev.mytelstra://mytesim/activate?activationCode=';
export const IOS_MYT_PROFILE_PROD = 'com.telstra.mytelstra://mytesim/activate?activationCode=';
export const ISIPHONE = 'IPHONE';
export const IPHONE_DEV_REDIRECT_URL_PAYMENT_FAILURE = 'com.telstra.dev.mytelstra://mytesim/error';
export const IPHONE_PROD_REDIRECT_URL_PAYMENT_FAILURE = 'com.telstra.mytelstra://mytesim/error';
export const ANDROID_REDIRECT_URL_PAYMENT_FAILURE = 'mytelstra://manage.esim/error';


export const BRANDS = {
    Telstra: {
        name: 'Telstra',
        displayName: 'Telstra'
    },
    JBHiFi: {
        name: 'JB Hi-Fi',
        displayName: 'JB Hi-Fi Mobile'
    }
};

export const OTC_VERIFY_PURPOSE = {
    PERSONAL_CONTACT_EMAIL_UPDATE: 'CONTACT_EMAILUPDATE_ACCESS_VERIFICATION',
    PERSONAL_CONTACT_SMS_UPDATE: 'CONTACT_SMSUPDATE_ACCESS_VERIFICATION',
    BUSINESS_CONTACT_EMAIL_UPDATE: 'ACCOUNT_EMAILUPDATE_ACCESS_VERIFICATION',
    BUSINESS_CONTACT_SMS_UPDATE: 'ACCOUNT_SMSUPDATE_ACCESS_VERIFICATION',
    CONTACT_EMAIL_ACCESS_VERIFICATION: 'CONTACT_IDENTITY_EMAIL_ACCESS_VERIFICATION'
};
