export const telstraAuthConfig = () => {
    const environment = window.appConfig;
    const { PPA_OKAPI_BASE_URL = '', PPA_WEBSITE_URL = '' } = environment || {};
    const telstraAuthConfigConstant = {
        logLevel: 'INFO',
        oidc: {
            authority: `${PPA_OKAPI_BASE_URL}/identity`,
            metadataPath: `${PPA_OKAPI_BASE_URL}/identity/.well-known/openid-configuration`,
            metadata: {
                issuer: `${PPA_OKAPI_BASE_URL}/identity`,
                jwks_uri: `${PPA_OKAPI_BASE_URL}/identity/pf/JWKS`,
                authorization_endpoint: `${PPA_OKAPI_BASE_URL}/identity/as/authorization.oauth2`,
                userinfo_endpoint: `${PPA_OKAPI_BASE_URL}/identity/idp/userinfo.openid`,
                token_endpoint: `${PPA_OKAPI_BASE_URL}/identity/as/token.oauth2`,
                end_session_endpoint: `${PPA_OKAPI_BASE_URL}/identity/idp/startSLO.ping`
            },
            client_id: 'b2c-prepaidactivation',
            redirect_uri: `${PPA_WEBSITE_URL}`, // URL where login will redirect
            post_logout_redirect_uri: `${PPA_WEBSITE_URL}`, // URL where logout will redirect
            response_type: 'code',
            scope: 'openid profile',
            accessTokenExpiringNotificationTime: 800,
            automaticSilentRenew: true,
            // need validation from okapi auth component
            silent_redirect_uri: `${PPA_WEBSITE_URL}callback.htm`,
            checkSessionInterval: 300,
            filterProtocolClaims: false,
            loadUserInfo: false,
            inactiveTimeout: 60
        },
        editContactConfig: {
            acr_values: 'LOA2',
            redirect_uri: `${PPA_WEBSITE_URL}`,
        }
    };
    return telstraAuthConfigConstant;
};

export default telstraAuthConfig;
