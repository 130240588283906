import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';

import {
    ADDRESS_DETAILS_INIT_DONE,
    ADDRESS_DETAILS_INIT_ERROR,
    ADDRESS_LOOKUP_FAILURE,
    ADDRESS_LOOKUP_SUCCESS,
    ADDRESS_VERIFICATION_FAILURE,
    ADDRESS_VERIFICATION_INIT,
    ADDRESS_VERIFICATION_REQUIRED,
    ADDRESS_VERIFICATION_REQUIRED_STATUS,
    ADDRESS_VERIFICATION_SUCCESS,
    CUSTOMER_CHECK_DONE,
    CUSTOMER_CHECK_ERROR,
    FORM_FIELD_CHANGE,
    LOCK_STATE,
    RESET_ADDRESS_DETAILS_FORM
} from '../actions';
import { notifyAnalytics, requestCodeMeta } from '../../adapters/analytics-utils';

import { callingModuleMeta } from '../../adapters/constants';

// Reducer
const initialState = {
    loading: {
        appLoading: true,
        isModuleLoaded: false,
        lockAddressField: false,
        verificationInProgress: false,
        customerValidationComplete: false,
        formProcessed: false
    },
    locale: {},
    errors: { hasError: false, errorCode: null, hasServerError: false },
    address: { isVerificationRequired: false, addressVerificationOptions: [] }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADDRESS_DETAILS_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true },
                locale: action.data
            };

        case ADDRESS_DETAILS_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };

        // Address lookup faliure
        case ADDRESS_LOOKUP_FAILURE:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR }
            };

        // creates a customer verification address array
        // & changing the loading state of page.
        case ADDRESS_VERIFICATION_REQUIRED: {
            return {
                ...state,
                address: { ...state.address, isVerificationRequired: true },
                loading: { ...state.loading, verificationInProgress: false }
            };
        }

        case ADDRESS_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: false },
                // reset the address verification options box because the main address might have changed.
                address: { ...state.address, isVerificationRequired: false, addressVerificationOptions: [] }
            };

        case ADDRESS_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: false }
            };

        // turns the loading state of verification on
        case ADDRESS_VERIFICATION_INIT:
            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: true }
            };

        // storing customer validation failed
        case CUSTOMER_CHECK_ERROR: {
            if (action.callingModule === callingModuleMeta.address) {
                return {
                    ...state,
                    errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST },
                    loading: { ...state.loading, formProcessed: true, lockFields: action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.BAD_REQUEST }
                };
            }
            return state;
        }

        // when customer validation succeeds.process the form
        case CUSTOMER_CHECK_DONE:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true }
            };

        // lock fields
        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true, lockAddressField: true, formProcessed: true }
            };

        //  Reset address details
        case RESET_ADDRESS_DETAILS_FORM:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false }
            };

        // Unlock address field and confirm button to retry finding address
        case FORM_FIELD_CHANGE: {
            const { meta: { form, field } = {}, payload } = action;
            if (form === 'addressDetailsForm' && field === 'verifyAddress' && payload === 'addressNotFound') {
                return Object.assign({}, state, {
                    loading: { ...state.loading, lockAddressField: false }
                });
            }
            return state;
        }

        default:
            return state;
    }
};

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchAemData = (dataFactory = client.fetchLocale) => {
    const resourcePath = 'addressdetails';
    return dispatch => {
        dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
    };
};

/**
 * Fetches address object from telstra address lookup API
 * Note: this is the one off case where the promise is returned
 * to the calling component.
 * @param {string} [addressInput='']
 * @param {any} [dataFactory=client.fetchAddressDetails]
 * @returns promise which will resolve in address data object
 */
export const getAddress = (addressInput = '', dataFactory = client.fetchAddressDetails) => {
    return dispatch => {
        return dataFactory(dispatch, addressLookupSuccess, addressLookupFailure, { addressInput });
    };
};

/**
 * Verifies the address selected
 * Return address array from service if further verification
 *
 * @param {string} [addressInput='']
 * @param {any} [dataFactory=client.fetchAddressVerification]
 * @returns promise which will resolve in address data object
 */
export const verifyAddress = (addressId = '', dataFactory = client.fetchAddressVerification) => {
    return dispatch => {
        dispatch({ type: ADDRESS_VERIFICATION_INIT });
        return dataFactory(dispatch, addressVerificationSuccess, addressVerificationFailure, { addressId });
    };
};

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = data => {
    return { type: ADDRESS_DETAILS_INIT_DONE, data };
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: ADDRESS_DETAILS_INIT_ERROR, data: httpStatus };
}

/**
 * Exports object for address lookup success
 *
 * @export
 */
export const addressLookupSuccess = () => {
    return { type: ADDRESS_LOOKUP_SUCCESS };
};

/**
 * Exports object for address lookup failure
 *
 * @export
 */
export const addressLookupFailure = httpStatus => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    notifyAnalytics(requestCodeMeta.addressFailure, errorCode);

    return { type: ADDRESS_LOOKUP_FAILURE, errorCode };
};

/**
 * Process the package received from service call
 * IF
 *  One address is received from the server and the confidence level
 *  is above 90% the address verification will succeed and the user will be
 *  allowed to move on the page further
 * ELSE
 *  The address verification data will be dispatch for the verify address
 *  select box
 * @param {object} packageData
 * @returns
 */
export const processAddressVerificationPackage = verificationData => {
    const firstAddress = 0;
    const isVerificationRequired = !(verificationData.length === 1 && verificationData[firstAddress].confidence > 90);
    return { isVerificationRequired, data: verificationData };
};

/**
 * Exports object for address verification success or failure
 *
 * @export
 */
export const addressVerificationSuccess = verificationDetails => {
    const { isVerificationRequired = false, data = {} } = processAddressVerificationPackage(verificationDetails.data);

    if (isVerificationRequired) {
        data.forEach(value => {
            value.label = `${value.streetNumber} ${value.streetName} ${value.suburb}`;
            value.value = value.addressId;
        });
        return { type: ADDRESS_VERIFICATION_REQUIRED, data };
    }

    return { type: ADDRESS_VERIFICATION_SUCCESS, data, serviceAuthToken: data.token };
};

/**
 * Exports object for address verification failure
 *
 * @export
 */
export const addressVerificationFailure = httpStatus => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    notifyAnalytics(requestCodeMeta.addressFailure, errorCode);

    return { type: ADDRESS_VERIFICATION_FAILURE, errorCode };
};

/**
 * Reset address details form
 *
 * @export
 * @returns
 */
export function resetForm() {
    return { type: RESET_ADDRESS_DETAILS_FORM };
}

/**
 * complete address verification required successfully
 *
 * @export
 * @returns
 */
export function addressVerifiedStatus(verificationStatus) {
    return { type: ADDRESS_VERIFICATION_REQUIRED_STATUS, verificationStatus };
}
