import React, { Component } from 'react';
import { getVisaCheckoutConfig } from '../../../adapters/utils';
import { Loader } from '../../../components';
import './visa-checkout.css';

class VisaCheckout extends Component {
    componentWillMount() {
        const { authoriseVisaPayment, declineVisaPayment } = this.props.actions;
        const { serviceId = '', amount } = this.props;
        window.V.on('payment.success', paymentReference => {
            const { callid: callId = '' } = paymentReference || {};
            const payload = {
                serviceInfo: {
                    callId,
                    accountNumber: serviceId,
                    amount: parseInt(amount, 10) * 100
                }
            };
            authoriseVisaPayment(payload);
        });
        window.V.on('payment.error', (payment, error) => {
            declineVisaPayment(payment, error);
        });
    }
    render() {
        const { showProgress = false } = this.props;
        const { buttonImage: visaButtonImage } = getVisaCheckoutConfig();
        const initatePayment = () => {
            window.V.init({
                paymentRequest: {
                    currencyCode: 'AUD',
                    total: 30,
                    subtotal: 30
                }
            });
        };
        return (
            <div className="visa-checkout-button">
                <img src={visaButtonImage} alt="Visa Checkout" className="v-button" role="presentation" onClick={initatePayment} />
                {showProgress && <Loader />}
            </div>
        );
    }

    initialtePaymentCallbacks() {
        // Determine event emitter
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const listener = window[eventMethod];
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        listener(messageEvent, event => {
            if (event.data === 'billpay-payment-done') {
                const { enquireBillpay } = this.props.actions;
                const { billPayTokenId = '' } = this.state;
                const { serviceId: accountNumber = '0415678988', amount = 0 } = this.props;
                const payload = {
                    serviceInfo: {
                        accountNumber,
                        amount: parseInt(amount, 10) * 100
                    },
                    billPayTokenId
                };
                enquireBillpay(payload);
            }
        });
    }
}

export default VisaCheckout;
