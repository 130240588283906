import './esim-microsoft.css';
import React from 'react';
import { TextStyle, ActionButton } from '@able/react';
import ESIM_MICROSOFT_LOCALE from '../../assets/locale/esim-microsoft-locale';

const EsimMicrosoft = (props) => {
    const queryString = props.location.search ? props.location.search : "";
    const urlParams = queryString ? new URLSearchParams(queryString) : "";
    const imei = urlParams ? urlParams.get('imei') : "";
    const eid = urlParams ? urlParams.get('eid') : "";
    const {
        title: { heading1, heading2 },
        newCustomers: {
            header,
            para1: newcustomerText1,
            para2: newcustomerText2,
            simPlan: { text: simPlantext, link: simPlanlink },
            prepaidMobileBroadBandPlan: { text: prepaidMobileBroadBandPlanText, link: prepaidMobileBroadBandPlanLink }
        },
        existingCustomer: {
            header: existingCustomerHeader,
            para1: existingCustomerText1,
            para2: existingCustomerText2,
            esimFAQ,
            esimFAQLink
        },
        help: {
            header: helpHeader,
            helpAndSupport: { text: helpAndSupportText, link: helpAndSupportLink },
            contactTesltra: { text: contactTesltraText, link: contactTesltraLink },
            telstraEnterprise: { text: telstraEnterpriseText, link: telstraEnterpriseLink }
        }
    } = ESIM_MICROSOFT_LOCALE;
    return (
        <div className="esim-microsoft-container">
            <TextStyle element={'h1'} alias="HeadingA">{heading1}</TextStyle>
            <TextStyle element={'h2'} alias="HeadingB">{heading2}</TextStyle>
            <div className="newcustomer-section">
                <TextStyle element={'h3'} alias="HeadingC">{header}</TextStyle>
                <TextStyle alias="FinePrint" element="p" className="subtext-section-one">
                    {newcustomerText1}
                </TextStyle>
                <TextStyle alias="FinePrint" element="p" className="subtext-section-two">
                    {newcustomerText2}
                </TextStyle>
                <div className="subtext-newc-section-one">
                    <ActionButton variant="LowEmphasis" element="a" href={simPlanlink} opensNewTab={true} id="simPlanlink" label={simPlantext} />
                </div>
                <div className="subtext-newc-section-two">
                    <ActionButton variant="LowEmphasis" element="a" href={prepaidMobileBroadBandPlanLink} opensNewTab={true} id="prepaidMobileBroadBandPlanLink" label={prepaidMobileBroadBandPlanText} />
                </div>
            </div>
            <div className="existingcustomer-section">
                <TextStyle element={'h3'} alias="HeadingC">{existingCustomerHeader}</TextStyle>
                <TextStyle alias="FinePrint" element="p" className="subtext-section-one">
                    {existingCustomerText1}
                </TextStyle>
                <TextStyle alias="FinePrint" element="p" className="subtext-section-two">
                    {existingCustomerText2}
                </TextStyle>
                <div className="subtext-ec-section-one">
                    <ActionButton variant="LowEmphasis" element="a" href={esimFAQLink} opensNewTab={true} id="esimFAQLink" label={esimFAQ} />
                </div>
            </div>
            <div className="helpandsupport-section">
                <TextStyle element={'h3'} alias="HeadingC">{helpHeader}</TextStyle>
                <div className="subtext-help-section-one">
                    <ActionButton variant="LowEmphasis" element="a" href={`${helpAndSupportLink}${eid}`} opensNewTab={false} id="helpAndSupportLink" label={helpAndSupportText} />
                </div>
                <div className="subtext-help-section-two">
                    <ActionButton variant="LowEmphasis" element="a" href={contactTesltraLink} opensNewTab={true} id="contactTesltraLink" label={contactTesltraText} />
                </div>
                <div className="subtext-help-section-three">
                    <ActionButton variant="LowEmphasis" element="a" href={telstraEnterpriseLink} opensNewTab={true} id="telstraEnterpriseLink" label={telstraEnterpriseText} />
                </div>
            </div>
        </div>
    );
};

export default EsimMicrosoft;
