import { Spacing, TextStyle } from '@able/react';
import React from 'react';

export default function ReadOnlyField({
    label = '',
    value = '',
    valueClassName = '',
    ...props
}) {
    const bottomSpacing = value ? 'spacing4x' : null;
    return (
        <Spacing bottom={bottomSpacing} {...props}>
            <TextStyle alias="Label">{label}</TextStyle>
            <TextStyle alias="TextBodyShort" className={valueClassName}>{value}</TextStyle>
        </Spacing>
    );
}
