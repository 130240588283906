import { getdayMonthYearFromDate } from "../../adapters/utils";
import { isEmpty, isMaximumYear, validateDOB, validateName, validateNameDuplicateChar } from "../../adapters/validations";
import ID_PAGE_CONSTANTS from "./constants";

import drivers_licence_nsw from '../../assets/img/idv/drivers-licence_nsw.svg';
import drivers_licence_qld from '../../assets/img/idv/drivers-licence_qld.svg';
import drivers_licence_nt from '../../assets/img/idv/drivers-licence_nt.svg';
import drivers_licence_sa from '../../assets/img/idv/drivers-licence_sa.svg';
import drivers_licence_act from '../../assets/img/idv/drivers-licence_act.svg';
import drivers_licence_wa from '../../assets/img/idv/drivers-licence_wa.svg';
import drivers_licence_tas from '../../assets/img/idv/drivers-licence_tas.svg';
import drivers_licence_vic from '../../assets/img/idv/drivers-licence_vic.svg';
import medicare from '../../assets/img/idv/Medicare.svg';
import intlPassport from '../../assets/img/idv/Intl-Passport.svg';
import auPassport from '../../assets/img/idv/Au-Passport.svg';
import evImmiCard from '../../assets/img/idv/EvImmiCardPurple.svg';
import ausImmiCard from '../../assets/img/idv/AusImmiCardGreen.svg';

const {
    idDetails: { drivingLicense: {
        dlFirstNameElement, dlMiddleNameElement, dlLastNameElement, dobElement, dlSingleNameElement
    } = {}, }
} = ID_PAGE_CONSTANTS;

export function validateSingleName(singleName) {
    if (isEmpty(singleName)) {
        return dlSingleNameElement.error.empty;
    } else if (validateName(singleName) && !validateNameDuplicateChar(singleName)) {
        return dlSingleNameElement.error.invalid_Duplicate;
    } else if (!validateName(singleName)) {
        return dlSingleNameElement.error.invalid;
    }
}

export function validateFirstName(firstName) {
    if (isEmpty(firstName)) {
        return dlFirstNameElement.error.empty;
    } else if (validateName(firstName) && !validateNameDuplicateChar(firstName)) {
        return dlFirstNameElement.error.invalid_Duplicate;
    } else if (!validateName(firstName)) {
        return dlFirstNameElement.error.invalid;
    }
}

export function validateLastName(familyName) {
    if (isEmpty(familyName)) {
        return dlLastNameElement.error.empty;
    } else if (validateName(familyName) && !validateNameDuplicateChar(familyName)) {
        return dlLastNameElement.error.invalid_Duplicate;
    } else if (!validateName(familyName)) {
        return dlLastNameElement.error.invalid;
    }
}

export function validateMiddleName(middleName) {
    if (isEmpty(middleName)) {
        return dlMiddleNameElement.error.empty;
    } else if (validateName(middleName) && !validateNameDuplicateChar(middleName)) {
        return dlMiddleNameElement.error.invalid_Duplicate;
    } else if (!validateName(middleName)) {
        return dlMiddleNameElement.error.invalid;
    }
}

export function validateBirthDate(birthDate) {
    const dateOfBirth = getdayMonthYearFromDate(birthDate);
    const dobYear = dateOfBirth[2] ? dateOfBirth[2].trim() : '';
    const month = dateOfBirth[1] ? dateOfBirth[1].trim() : '';
    if (isEmpty(birthDate)) {
        return dobElement.error.empty;
    } else if (month.length !== 2) {
        return dobElement.error.invalid;
    } else if (!isEmpty(dobYear) && !validateDOB(birthDate)) {
        return isMaximumYear(birthDate) ? dobElement.error.dobMaxError : dobElement.error.dobMinError;
    }
}

export const splitDOB = (dob) => {
    const res = {};
    const dateOfBirth = getdayMonthYearFromDate(dob);
    res.dobDay = dateOfBirth[0] ? dateOfBirth[0].trim() : '';
    res.dobMonth = dateOfBirth[1] ? dateOfBirth[1].trim() : '';
    res.dobYear = dateOfBirth[2] ? dateOfBirth[2].trim() : '';

    return res;
}

export const IDENTITY_TYPE_IMAGES = {
    drivers_licence_nsw,
    drivers_licence_qld,
    drivers_licence_nt,
    drivers_licence_sa,
    drivers_licence_act,
    drivers_licence_wa,
    drivers_licence_tas,
    drivers_licence_vic,
    medicare,
    intlPassport,
    auPassport,
    evImmiCard,
    ausImmiCard
}