import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';

// Actions
import {
    CUSTOMER_DATA_FETCH_ERROR,
    CUSTOMER_DATA_FETCH_INIT,
    CUSTOMER_DATA_FETCH_SUCCESS,
    GO_BACK,
    LOCK_STATE,
    NUMBER_CHECK_DONE,
    NUMBER_CHECK_ERROR,
    REQUEST_NUMBER_CHECK,
    RESET_ERRORS,
    WELCOME_INIT_DONE,
    WELCOME_INIT_ERROR,
    ESIM_OFFER_ELIGIBILITY_CHECK_SUCCESS,
    ESIM_OFFER_ELIGIBILITY_CHECK_ERROR,
    PRE_PORT_VERIFICATION_INIT,
    PRE_PORT_VERIFICATION_SUCCESS,
    PRE_PORT_VERIFICATION_ERROR,
    RECIEVE_SMS_CODE_INIT,
    RECIEVE_SMS_CODE_ERROR,
    RECIEVE_SMS_CODE_SUCCESS,
    RESET_PORTING_STATE,
    SHOW_PORTING_MODAL,
    SET_SELECTED_DEVICE,
    UPDATE_CURRENT_PLAN
} from '../actions';
import { getIdType, getSerialNumber, storeProcessToken, nextPage, isSelectedDeviceMobile } from '../../adapters/utils';
import store from '../../store';
import { addDataLayerEventInfo, addDataLayerSimDetails, errorDataLayerAddition } from '../../adapters/analytics-utils';
import { dataLayerEventActions } from '../../adapters/constants';
import { updateStepperCustomData } from '../../components/Progress/module';
import { ORDER_IN_PROGRESS, SIM_ORDER_COMPLETED } from '../../adapters/errorCode';

// Reducer
const initialState = {
    // loading state
    loading: { appLoading: true, isModuleLoaded: false, lockFields: false, formProcessed: false, hasVerifiedEsimFreeOfferEligibility: false },
    // validation
    validations: {
        isFetchingCustomerData: false,
        isValidatingNumber: false,
        isNumberChecked: false,
        failedAttempts: 0,
        simIdIsSerialNumber: false,
        isPrePortNumberVerified: false,
        isPortingInProgress: false,
        isSmsCodeVerified: false,
        resendCodeAttempts: 0,
        isVerifySmsInProgress: false,
        completionDate: null
    },
    // ui error state
    errors: { hasNumberError: false, numberErrorCode: null },
    // local data
    locale: {},
    deviceData: { selectedDevice: null },
    areMultipleDevicesAvailable: false,
    showPortingModal: false,
    portingNumber: null
};

function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case WELCOME_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true }
            };
        case WELCOME_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case CUSTOMER_DATA_FETCH_INIT:
            return {
                ...state,
                validations: { ...state.validations, isFetchingCustomerData: true }
            };
        case CUSTOMER_DATA_FETCH_SUCCESS:
            return {
                ...state,
                validations: { ...state.validations, isFetchingCustomerData: false }
            };
        case CUSTOMER_DATA_FETCH_ERROR:
            return {
                ...state,
                validations: { ...state.validations, isFetchingCustomerData: false }
            };
        case REQUEST_NUMBER_CHECK:
            return {
                ...state,
                validations: { ...state.validations, isValidatingNumber: true, simIdIsSerialNumber: action.simIdIsSerialNumber }
            };
        case NUMBER_CHECK_DONE:
            return {
                ...state,
                validations: { ...state.validations, isValidatingNumber: false, isNumberChecked: true },
                loading: { ...state.loading, formProcessed: true },
                deviceData: Object.assign({}, state.deviceData, action.data),
                areMultipleDevicesAvailable: action.areMultipleDevicesAvailable
            };
        case NUMBER_CHECK_ERROR:
            return {
                ...state,
                validations: { ...state.validations,
                    isValidatingNumber: false,
                    failedAttempts: state.validations.failedAttempts + 1
                },
                errors: { ...state.errors,
                    hasNumberError: true,
                    numberErrorCode: action.errorCode }
            };

        case RESET_ERRORS:
            return {
                ...state,
                validations: { ...state.validations, isValidatingNumber: false },
                errors: { ...state.errors, hasNumberError: false, numberErrorCode: null, hasError: null, errorCode: null }
            };

        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true }
            };

        case GO_BACK: {
            let lockFields = state.loading.lockFields;
            if (lockFields && action.path === '/welcome') {
                lockFields = false;
            }
            return {
                ...state,
                loading: { ...state.loading, lockFields }
            };
        }
        case ESIM_OFFER_ELIGIBILITY_CHECK_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, hasVerifiedEsimFreeOfferEligibility: true }
            };
        case ESIM_OFFER_ELIGIBILITY_CHECK_ERROR:
            return {
                ...state,
                loading: { ...state.loading, hasVerifiedEsimFreeOfferEligibility: true }
            };
        case PRE_PORT_VERIFICATION_INIT:
            return {
                ...state,
                validations: { ...state.validations, isPrePortNumberVerified: false, isPortingInProgress: true },
                errors: { ...state.errors, hasError: false },
                portingNumber: action.portingNumber
            };
        case PRE_PORT_VERIFICATION_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, errorHttpStatus: action.errorHttpStatus },
                validations: { ...state.validations, isPortingInProgress: false, isPrePortNumberVerified: false, resendCodeAttempts: state.validations.resendCodeAttempts }
            };
        case PRE_PORT_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true },
                validations: { ...state.validations, isPrePortNumberVerified: true, isPortingInProgress: false, resendCodeAttempts: state.validations.resendCodeAttempts + 1 }
            };
        case RECIEVE_SMS_CODE_INIT:
            return {
                ...state,
                validations: { ...state.validations, isVerifySmsInProgress: true }
            };
        case RECIEVE_SMS_CODE_ERROR:
            const { data: { completionDate = null } = {} } = action;
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode },
                validations: { ...state.validations, isPrePortNumberVerified: true, isSmsCodeVerified: false, isVerifySmsInProgress: false, completionDate: completionDate }
               
            };
        case RECIEVE_SMS_CODE_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true },
                validations: { ...state.validations, isSmsCodeVerified: true, isVerifySmsInProgress: false }
            };
        case RESET_PORTING_STATE:
            return {
                ...state,
                errors: { ...state.errors, hasError: null, errorCode: null },
                validations: { ...state.validations, isPrePortNumberVerified: false, resendCodeAttempts: 0, isSmsCodeVerified: false },
                portingNumber: null
            };
        case SHOW_PORTING_MODAL:
            return {
                ...state,
                showPortingModal: action.data
            };

        default:
            return state;
    }
}
export default reducer;

// Action Creators
/**
 * Fetech the AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
// export const fetchAemData = (dataFactory = client.fetchLocale) => {
//     const { isEsimActivation = false } = getUiState();
//     const resourcePath = isEsimActivation ? 'esim' : 'welcome';
//     // return WELCOME_LOCALE;
//     return dispatch => {
//         dataFactory(dispatch, moduleInitialised, moduleInitialisationError, resourcePath);
//     };
// };

/**
 * Fetech the WelcomePage Content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const fetchWelcomeLocale = () => {
    return { type: WELCOME_INIT_DONE };
};

/**
 * Service call to check is the sim number entered is valid
 *
 * @param {string} number sim id number
 * @param {any} [fetcher=client.fetchPackageDetails]
 * @returns
 */
export const isNumberValid = (number, fetcher = client.fetchPackageDetails) => {
    let sanitizedNumber = number;
    const numberType = getIdType(number);

    // Trim number if id type is sim serial number
    if (numberType === 'SIM_SERIAL_NUMBER') {
        if (number.length === 10) {
            sanitizedNumber = sanitizedNumber.substring(0, 9);
        } else if (number.length === 14) {
            sanitizedNumber = sanitizedNumber.substring(0, 13);
        }
    }

    const resourcePath = `${numberType}/${sanitizedNumber}`;
    return (dispatch) => {
        dispatch(requestNumberValidation(numberType));
        return fetcher(dispatch, receiveNumberValidation, receiveNumberValidationError, resourcePath);
    };
};

/**
 * Return dispatch object to validate number
 *
 * @param {string} [numberType=''] sim id type
 * @returns
 */
export const requestNumberValidation = (numberType = '') => {
    return { type: REQUEST_NUMBER_CHECK, simIdIsSerialNumber: !(numberType === 'MSISDN') };
};

/**
 * Process the package received from service call
 *
 * @param {object} packageData
 * @returns
 */
export const processPackage = (packageDataDetails) => {
    let errorCode = '';
    const { data: packageData, status } = packageDataDetails;
    const state = store.getState();
    const { isEsimActivation } = state.app.appData.uiState;

    if (typeof status === 'string' && status.toLowerCase() !== 'success') {
        if (status.indexOf('FATAL') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.FATAL_ERROR, data: packageData };
        } else if (status.indexOf('DXPPOQ-EX-INVALIDPROFILE-Boost') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.INVALID_SIM, data: packageData };
        } else if (status.indexOf('DSIMA-EX-404') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.PACKAGE_NOT_AVAILABLE, data: packageData };
        } else if (status.indexOf('DSIMA-EX-ACTIVE') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.ORDER_IN_PROGRESS, data: packageData };
        } else if (status.indexOf('DSIMA-EX-EXPIRED') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_IN_USE_ERROR, data: packageData };
        } else if (status.indexOf('DSIMA-EX-412') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_PRE_CONDITION_NO_MATCH_ERROR, data: packageData };
        } else if (status.indexOf('SIM-ORDER-COMPLETED') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_ORDER_COMPLETED, data: packageData };
        } else if (status.indexOf('SIM-ORDER-INPROGRESS') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_ORDER_INPROGRESS, data: packageData };
        } else if (status.indexOf('DSIMA-EX-UNSOLD') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_IS_UNSOLD, data: packageData };
        } else if (status.indexOf('SIM-ORDER-COMPLETED') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_ORDER_COMPLETED, data: packageData };
        } else if (status.indexOf('SIM-ORDER-INPROGRESS') !== -1) {
            return { hasError: true, errorCode: errorCodeMeta.SIM_ORDER_INPROGRESS, data: packageData };
        }
    }
    if (!isEsimActivation) {
        packageData.simSerial = getSerialNumber(packageData.simSerial);
        if (packageData.orderInProgress) {
            errorCode = errorCodeMeta.ORDER_IN_PROGRESS;
        } else if (!packageData.available) {
            errorCode = errorCodeMeta.SIM_IN_USE_ERROR;
        } else if (packageData.available && packageData.validProfile) {
            errorCode = '';
        } else {
            errorCode = errorCodeMeta.PACKAGE_NOT_AVAILABLE;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData };
};

// DISPATCH OBJECTS
/**
 * Returns dipatch object after processing the http response from
 * service call
 *
 * @param {string} httpStatus
 * @returns
 */
export const receiveNumberValidationError = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 404:
            errorCode = errorCodeMeta.PACKAGE_NOT_AVAILABLE;
            break;
        case 412:
            errorCode = errorCodeMeta.SIM_PRE_CONDITION_NO_MATCH_ERROR;
            break;
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    errorDataLayerAddition(errorCode, 'Validation');
    if(errorCode === "SIM_ORDER_INPROGRESS" || errorCode === "SIM_ORDER_COMPLETED") {
        return { type: NUMBER_CHECK_ERROR, errorCode, data };
    }
    return { type: NUMBER_CHECK_ERROR, errorCode};
    
};

/**
 * Processes the response from service call which check the
 * validity of entered sim id dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receiveNumberValidation = (packageDetails) => {
    const { hasError, errorCode, data } = processPackage(packageDetails);
    const state = store.getState();
    const { isEsimActivation } = state.app.appData.uiState;
    let areMultipleDevicesAvailable;

    if (hasError) {
        return receiveNumberValidationError(errorCode, data);
    }
    storeProcessToken(data.token);
    isEsimActivation ? addDataLayerEventInfo(dataLayerEventActions.ESIM_ELIGIBLE_FOR_ACTIVATION) : addDataLayerEventInfo(dataLayerEventActions.ELIGIBLE_FOR_ACTIVATION);
    addDataLayerSimDetails();

    return (dispatch) => {
        if (data.deviceOffers.length === 1) {
            areMultipleDevicesAvailable = false;
            dispatch({ type: SET_SELECTED_DEVICE, device: data.deviceOffers[0].deviceValue });
            dispatch({ type: NUMBER_CHECK_DONE, data, serviceAuthToken: data.token, areMultipleDevicesAvailable });
            if (!isSelectedDeviceMobile(data.deviceOffers[0].deviceValue)) nextPage(updateStepperCustomData);
        } else {
            areMultipleDevicesAvailable = true;
            dispatch({ type: NUMBER_CHECK_DONE, data, serviceAuthToken: data.token, areMultipleDevicesAvailable });
        }
    };
};

/**
 * Returns dispatch object for page load complete
 *
 * @export
 * @param {any} data
 * @returns
 */
export const moduleInitialised = (data) => ({ type: WELCOME_INIT_DONE, data });

/**
 * * Returns dispatch object for page load error
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: WELCOME_INIT_ERROR, data: httpStatus };
}

/**
 * return dispatch object to
 * resets the global appdata error
 *
 * @export
 * @returns
 */
export function resetErrors() {
    return { type: RESET_ERRORS };
}

export const checkEsimFreeOfferEligibility = (params, fetcher = client.checkEsimFreeOfferEligibility) => {
    return (dispatch) => {
        return fetcher(dispatch, receiveEsimOfferEligibilty, receiveEsimOfferEligibilityError, params);
    };
};

export const receiveEsimOfferEligibilty = (packageDetails) => {
    const { hasError, errorCode, data } = processEsimOfferPackage(packageDetails.data);

    storeProcessToken(data.token);
    if (hasError) {
        const action = receiveEsimOfferEligibilityError(errorCode);
        return { ...action, data };
    }

    addDataLayerEventInfo(dataLayerEventActions.ESIM_OFFER_ELIGIBLE);

    return {
        type: ESIM_OFFER_ELIGIBILITY_CHECK_SUCCESS,
        isEligibleForFreeOffer: true,
        data
    };
};

/**
 * Process the package received from service call
 *
 * @param {object} packageData
 * @returns
 */
export const processEsimOfferPackage = (packageData) => {
    let errorCode = '';
    const { eligibleForFreeOffer = false } = packageData || {};

    if (!eligibleForFreeOffer) errorCode = errorCodeMeta.NOT_ELIGIBLE_ESIM_FREE_OFFER;

    return { hasError: Boolean(errorCode.length), errorCode, data: packageData };
};

/**
 * Returns dipatch object after processing the http response from
 * service call
 *
 * @param {string} httpStatus
 * @returns
 */
export const receiveEsimOfferEligibilityError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    addDataLayerEventInfo(dataLayerEventActions.ESIM_OFFER_NOT_ELIGIBLE);
    return { type: ESIM_OFFER_ELIGIBILITY_CHECK_ERROR, isEligibleForFreeOffer: false, errorCode };
};

/**
 * Validates porting details
 *
 * @param {any} document
 * @param {any} [dataFactory=client.fetchDocumentValidation]
 * @returns
 */
export const validatePrePortVerfication = (number, fetcher = client.prePortVerification) => {
    const mobileNumber = `${number}`;
    return (dispatch) => {
        dispatch({ type: PRE_PORT_VERIFICATION_INIT, portingNumber: mobileNumber });
        return fetcher(dispatch, receivePrePortVerfication, receivePrePortVerficationError, mobileNumber);
    };
};
/**
 * Processes the response from service call which check the
 * validity of entered number dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receivePrePortVerfication = (packageDetails) => {
    const { hasError, errorCode, data, errorHttpStatus } = processPrePortVerficationResponse(packageDetails);
    if (hasError) {
        return receivePrePortVerficationError(errorCode, errorHttpStatus);
    }
    return {
        type: PRE_PORT_VERIFICATION_SUCCESS,
        data
    };
};
/**
 * process response from service call
 * for document validation
 *
 * @param {any} packageData
 * @returns
 */
const processPrePortVerficationResponse = (packageData) => {
    let errorCode = '', errorHttpStatus = '';
    const portingError = packageData.status;
    if (typeof portingError === 'string' && portingError.toLowerCase() !== 'success') {
        if (portingError === 'ERROR-TELSTRAPRE') {
            errorCode = errorCodeMeta.TELSTRAPRE_NUMBER_ERROR;
        } else if (portingError === 'ERROR-TELLEGPRE') {
            errorCode = errorCodeMeta.TELLEGPRE_NUMBER_ERROR;
        } else if (portingError === 'ERROR-TELSTRAPOST') {
            errorCode = errorCodeMeta.TELSTRAPOST_NUMBER_ERROR;
        } else if (portingError === 'ERROR-TELLEGPOST') {
            errorCode = errorCodeMeta.TELLEGPOST_NUMBER_ERROR;
        } else if (portingError === 'ERROR-BOOSTLEG') {
            errorCode = errorCodeMeta.BOOSTLEG_NUMBER_ERROR;
        } else if (portingError === 'ERROR-TEM') {
            errorCode = errorCodeMeta.TEM_NUMBER_ERROR;
        } else if (portingError.indexOf('PPVGS-FATAL-4121006') > -1) {
            errorCode = errorCodeMeta.PORT_NOT_ALLOWED_ERROR;
        } else if (portingError.indexOf('NPSQ-FATAL') > -1 || portingError.indexOf('CPIT-FATAL') > -1) {
            errorCode = errorCodeMeta.PORT_GENERIC_FATAL_ERROR;
        } else if (portingError === 'ERROR-SAMEPRE') {
            errorCode = errorCodeMeta.SAMEPRE_NUMBER_ERROR;
        } else if (portingError.indexOf('ERROR-NONTPOST') > -1) {
            errorCode = errorCodeMeta.NONTPOST_NUMBER_ERROR;
            errorHttpStatus = portingError;
        } else {
            errorCode = errorCodeMeta.SEND_CODE_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data, errorHttpStatus };
};

/**
 * Returns dispatch object for failed porting validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const receivePrePortVerficationError = (httpStatus, errorHttpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SEND_CODE_ERROR;
            break;
        case 429:
            errorCode = errorCodeMeta.MAX_ATTEMPT_ERROR;
            break;
        case 412:
            errorCode = errorCodeMeta.MAX_ATTEMPT_ERROR;
            break;
        case 404:
            errorCode = errorCodeMeta.PORT_NOT_ALLOWED_ERROR;
            break;
        case 422:
            errorCode = errorCodeMeta.TEM_NUMBER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: PRE_PORT_VERIFICATION_ERROR, errorCode, errorHttpStatus };
};
/**
 * Validates porting details
 *
 * @param {any} document
 * @param {any} [dataFactory=client.fetchDocumentValidation]
 * @returns
 */
export const validateSmsCode = (prePortingDetails, dataFactory = client.verifySmsCode) => {
    return (dispatch) => {
        dispatch({ type: RECIEVE_SMS_CODE_INIT });
        dataFactory(dispatch, receiveSmsCode, receiveSmsCodeError, prePortingDetails);
    };
};
/**
 * Processes the response from service call which check the
 * validity of entered number dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const receiveSmsCode = (packageDetails) => {
    const { hasError, errorCode, data } = processSmsCodeResponse(packageDetails);

    if (hasError) {
        return receiveSmsCodeError(errorCode, data);
    }
    return {
        type: RECIEVE_SMS_CODE_SUCCESS,
        data
    };
};
/**
 * process response from service call
 * for document validation
 *
 * @param {any} packageData
 * @returns
 */
const processSmsCodeResponse = (packageData) => {
    let errorCode = '';
    const portingError = packageData.status;
    if (typeof portingError === 'string' && portingError.toLowerCase() !== 'success') {
        if (portingError === 'PPVVC-EX-4091003') {
            errorCode = errorCodeMeta.INVALID_CODE_ERROR;
        } else if (portingError === 'PPVVC-EX-4121008') {
            errorCode = errorCodeMeta.CODE_EXPIRED_ERROR;
        } else if (portingError === 'PPVVC-FATAL-4291009') {
            errorCode = errorCodeMeta.MAX_ATTEMPT_ERROR;
        } else if (portingError.indexOf('PPVVC-FATAL') >= 0) {
            errorCode = errorCodeMeta.VALIDATE_CODE_ERROR;
        } else if (portingError === 'MIS-ORDER-INPROGRESS') {
            errorCode = errorCodeMeta.MIS_ORDER_INPROGRESS;
        } else {
            errorCode = errorCodeMeta.VALIDATE_CODE_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

/**
 * Returns dispatch object for failed porting validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const receiveSmsCodeError = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.VALIDATE_CODE_ERROR;
            break;
        // case 409:
        //     errorCode = errorCodeMeta.INVALID_CODE_ERROR;
        //     break;
        // case 429:
        //     errorCode = errorCodeMeta.CODE_EXPIRED_ERROR;
        //     break;
        // case 412:
        //     errorCode = errorCodeMeta.CODE_EXPIRED_ERROR;
        //     break;
        default:
            errorCode = httpStatus;
            break;
    }
    if(errorCode === errorCodeMeta.MIS_ORDER_INPROGRESS) return { type: RECIEVE_SMS_CODE_ERROR, errorCode, data };
    return { type: RECIEVE_SMS_CODE_ERROR, errorCode };
};

export function resetPorting() {
    return { type: RESET_PORTING_STATE };
}

export function triggerPortingModal(data) {
    return { type: SHOW_PORTING_MODAL, data };
}

export function updateCurrentPlan(data) {
    return {
        type: UPDATE_CURRENT_PLAN,
        data
    };
}
