/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { getCurrentRouteName, focusOnHeading, getBrandDetails, getAbleSpriteSheet, focusOnElement, getPlanTypeFromPlanId } from '../../adapters/utils';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { dataLayerEventActions, BRANDS } from '../../adapters/constants';
import CardList from './components/card-list';
import SpinnerCustom from './components/spinner-custom';
import store from '../../store';
import './recharge-plan.css';
import FullPageError from '../../components/FullPageError/FullPageError';
import { FRAUD_DECLINED_ERROR, UPDATE_CART_FATAL_ERROR, CREATE_CART_FATAL_ERROR, SERVER_ERROR, NETWORK_FAILURE } from '../../adapters/errorCode';
import { updateStepperCustomData } from '../../components/Progress/module';
import { redirectToMyTelstra } from '../../adapters/esim-utils';
import Modal from 'react-modal';
import SkipRechargeModal from './SkipRechargeModal';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import { ActionButton, MessageInline, MessageSection, ModalContent, SegmentedView, TextStyle } from '@able/react';
import Table from '../../components/Table';
import PAYMENT_PAGE_CONSTANTS from '../payment/constants';
import SpinningButton from '../../components/SpinningButton/SpinningButton';

const ableSpriteSheet = getAbleSpriteSheet();
const categoriesTabId = 'recharge-options-tabs';
const { priceComparisonTableConfig } = PAYMENT_PAGE_CONSTANTS;
export default class OfferDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            timesClicked: 1,
            isPlanModalVisible: false,
            isLoadingButton:false,
            plan: {},
            planTableData: {},
            categories: []
        };
    }

    componentDidMount() {
        this.loadKountURL();
        const customerAccountUUID = '0987654321';
        const {
            actions: { createShoppingCart },
            appData: {
                uiState: { selectedActivationFlow = '', isEsimActivation }
            }
        } = this.props;
        const portingFlow = selectedActivationFlow === 'portNumber';
        createShoppingCart({ customerAccountUUID, portingFlow });
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        focusOnHeading();
        focusOnElement('#recharge-page-toast-1', 500);
        setTimeout(() => {
            const firstRechargeCard = document.getElementsByClassName('recharge-card-container')[0];
            if (firstRechargeCard) {
                firstRechargeCard.focus();
            }
        }, 3000);
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: 5,
            isLastStepCompleted: false
        };
        store.dispatch(updateStepperCustomData(stepperData));
    }

    componentDidUpdate(prevProps) {
        const isOffersLoaded = this.props.loading.isOffersLoaded;
        if (!prevProps.loading.isOffersLoaded && isOffersLoaded) {
            this.setState({ categories: this.props.customerOffers.map((offer) => offer.tabName) });
        }
    }

    componentWillUnmount() {
        this.props.actions.resetOffer();
    }
    render() {
        const { appData: { uiState: { isEsimActivation, userSelectedDevice } = {}, serviceNumber: { starterCredit } = {} } = {} } = this.props;
        const toggleModal = () => {
            this.setState({ modalOpen: !this.state.modalOpen });
            this.props.actions.triggerSkipRechargeModal(false);
        };
        const isOffersLoaded = this.props.loading.isOffersLoaded;
        const { showSkipRechargeModal } = this.props;
        let starterCreditComponent;
        let rechargeLater;

        if (starterCredit) {
            starterCreditComponent = this.renderstarterCredit(starterCredit, userSelectedDevice);
        } else if(!isEsimActivation) {
            rechargeLater = this.renderRechargeLater(this.props);
        }

        const { errors = {} } = this.props;
        const { didUserUpdateMobile, diffMobileUpdated } = this.props.yourContactDetails.updateMobileStatus;

        const hasErrorOccurred = store.getState().app.hasErrorOccurred;

        let errorProps = {};
        if (errors && (errors.hasError || errors.errorCode)) {
            let errorMessageText = '';
            if (errors.errorCode === FRAUD_DECLINED_ERROR) {
                errorMessageText = 'We’re unable to process the request at this time.';
            } else if (errors.errorCode === UPDATE_CART_FATAL_ERROR || errors.errorCode === SERVER_ERROR || errors.errorCode === CREATE_CART_FATAL_ERROR || errors.errorCode === NETWORK_FAILURE) {
                errorMessageText = 'We’re unable to process the request at this time. Please try again later.';
            }
            errorProps = {
                errorText: 'Something went wrong',
                errorMessage: errorMessageText
            };
        }

        const {
            appData: {
                uiState: { loggedIn = false }
            }
        } = this.props;

        let toastMessage;
        if (diffMobileUpdated) {
            toastMessage = 'Your mobile number has been updated.';
        } else if (didUserUpdateMobile) {
            toastMessage = 'Your mobile number will be updated when your SIM activation is complete.';
        }
        const {
            loading: { updateCartInProgress }
        } = this.props;
        if (!hasErrorOccurred) {
            return (
                <div className="container ppa-recharge-block">
                    <GlobalBanner onPage="recharge" className="infobanner-recharge" />
                    {!loggedIn && didUserUpdateMobile && (
                        <RechargeInfoContainer id="recharge-page-toast-1">
                            <MessageSection
                                developmentUrl={ableSpriteSheet}
                                variant="Success"
                                description={toastMessage}
                                titleElement="h2"
                            />
                        </RechargeInfoContainer>
                    )}
                    <TextStyle tabIndex="-1" element="h1" alias="HeadingA" className="mb-4">
                        Select a recharge
                    </TextStyle>
                    {!isOffersLoaded ? (
                        <SpinnerCustom size="medium" label="loading recharge plans" />
                    ) : (
                        <div>
                            {starterCreditComponent}
                            {this.state.categories.length === 1
                                ? this.getTabPaneContent(this.state.categories[0], rechargeLater)
                                : <SegmentedView
                                    id={categoriesTabId}
                                    ariaLabel='Select a recharge'
                                    categoryListItems={this.state.categories.map(catName => ({
                                        title: catName,
                                        panelContent: () => this.getTabPaneContent(catName, rechargeLater)
                                    }))} />
                            }
                            {showSkipRechargeModal ? (
                                <Modal
                                    parentSelector={() => document.querySelector('#modal-ppv')}
                                    shouldCloseOnOverlayClick={false}
                                    isOpen={this.state.modalOpen}
                                    className="modal-dialog modal-lg ppv-modal"
                                    role="dialog"
                                    onRequestClose={toggleModal}
                                >
                                    {<SkipRechargeModal {...this.props} toggleModal={toggleModal} showContentValue={this.getActiveTabName()} />}
                                </Modal>
                            ) : null}
                        </div>
                    )}
                    
                    <ModalContent
                        className='price-rise-modal'
                        title={priceComparisonTableConfig.title}
                        bodyContent={
                            <>
                                <Table className='ppa-price-rise-table' columns={this.state.planTableData.columns} data={this.state.planTableData.data} />
                            </>
                        }
                        footerContent={
                            <div className="d-flex justify-content-between  mt-4">
                                <ActionButton
                                    className="mt-4"
                                    element="button"
                                    variant="MediumEmphasis"
                                    label="Cancel"
                                    onClick={this.togglePlanModal} />
                                <SpinningButton
                                    variant="HighEmphasis"
                                    isLoading={updateCartInProgress && this.state.isLoadingButton}
                                    onSubmit={this.selectPlan}
                                    isDisabled={updateCartInProgress}
                                    buttonText="Continue"
                                    stylingClass="price-rise-continueBtn"
                                />
                            </div>
                        }
                        variant='Comfortable'
                        isShowing={this.state.isPlanModalVisible}
                        developmentUrl={ableSpriteSheet}
                        setHideDialog={this.togglePlanModal}
                    />
                </div>
            );
        } else if (hasErrorOccurred || errors.errorCode === NETWORK_FAILURE) {
            return isEsimActivation ? redirectToMyTelstra() : <FullPageError {...errorProps} />;
        }
        return null;
    }
    
    selectPlan = () => {
        addDataLayerEventInfo('click','clickTrack','PPAPriceModal','Continue');
        this.setState({isLoadingButton: true});
        const { starterCredit } = this.state.plan.appData.serviceNumber;
        const { shoppingCartId } = this.state.plan.appData.shoppingCart;

        const offerDetails = {
            productOfferingId:  this.state.plan.productOfferingId,
            rechargeId:  this.state.plan.plan.id,
            starterCredit,
            shoppingCartId
        };
        addDataLayerEventInfo(dataLayerEventActions.PROD_VIEW);
        this.props.actions.updateSelectedOffer({ productOfferingId:  this.state.plan.productOfferingId, ... this.state.plan.plan });
        this.props.actions.updateCart(offerDetails, false);
    };
    togglePlanModal = () => {
        this.setState({isPlanModalVisible: !this.state.isPlanModalVisible}, () => {
            if(this.state.isPlanModalVisible){
                addDataLayerEventInfo('impression','modalContent','PPAPriceModal','Recharge options are changing');
            }else{
                addDataLayerEventInfo('click','clickTrack','PPAPriceModal','Cancel');
            }
        });
    };
    setPlan = (selectedPlan) => {
        this.setState({
            plan: selectedPlan,
            planTableData: priceComparisonTableConfig.tableData[getPlanTypeFromPlanId(selectedPlan.plan.id)] || {},
        });
    };
    getTabPaneContent = (categoryName, children) => {
        const {
            deviceData: { simVersion = '', communityPlan = false },
            appData: { uiState: { isEsimActivation } = {}, serviceNumber: { starterCredit } = {} } = {}
        } = this.props;

        let isJBHiFi = false;
        let priceRise = false;
        priceRise = simVersion === 'PRE_PRICE_RISE';
        isJBHiFi = getBrandDetails().brandName === BRANDS.JBHiFi.name;

        const description = 'This change will be applied from the next time you recharge.';
        const titleText = 'Our recharge prices have changed';
        const actionText = 'View new recharge options';
        const actionLink = 'https://www.telstra.com.au/consumer-advice/price-changes#pre-paid';

        const newTabForRechargeInfo = () => {
            window.open(actionLink, '_blank');
        };

        return (
            <Fragment>
                {!isEsimActivation && starterCredit === 0 && (
                    <RechargeInfoContainer>
                        <MessageSection
                            developmentUrl={ableSpriteSheet}
                            variant="Information"
                            description={'Your recharge starts after your SIM is activated.'}
                            titleText={'Important'}
                            titleElement="h2"
                        />
                    </RechargeInfoContainer>
                )}
                {!isJBHiFi && priceRise && !communityPlan && (
                    <RechargeInfoContainer>
                        <MessageSection
                            developmentUrl={ableSpriteSheet}
                            variant="Information"
                            description={description}
                            titleText={titleText}
                            titleElement="h2"
                            actionId="recharge-info"
                            actionElement="button"
                            actionText={actionText}
                            actionCallback={newTabForRechargeInfo}
                        />
                    </RechargeInfoContainer>
                )}
                {this.renderPlanContent(categoryName)}
                {children}
            </Fragment>
        );
    }

    loadKountURL() {
        const env = window.appConfig.PPA_ENV_MODE;
        let kountHost = '';
        if (env === 'prod' || env === 'staging') {
            kountHost = 'https://ssl.kaptcha.com/collect/sdk?m=603250&s=';
        } else {
            kountHost = 'https://tst.kaptcha.com/collect/sdk?m=888889&s=';
        }

        // Fetching Session ID for KOUNT and Framing KOUNT URL with Merchant ID and Session ID
        const state = store.getState();
        let sessionId = state.app.sessionToken;
        // Removing Hyphen to create 32 character String
        sessionId = sessionId.replace(/-/g, '');
        const kountURL = kountHost + sessionId;

        const headElement = document.getElementsByTagName('head')[0];
        const kountDataScript = document.createElement('script');
        kountDataScript.src = kountURL;
        kountDataScript.type = 'text/javascript';
        kountDataScript.async = false;
        headElement.appendChild(kountDataScript);

        kountDataScript.onload = () => {
            if (window.ka) {
                const client = new window.ka.ClientSDK();
                if (client) {
                    client.collectData();
                }
            }
        };
    }

    renderstarterCredit(starterCredit, userSelectedDevice) {
        const starterCopy = starterCredit === 1.0 ? 'Your device includes a recharge to get you started.' : `Your SIM kit includes a $${starterCredit} recharge`;
        return (
            <div className="row no-gutters">
                <TextStyle alias="HeadingD" aria-level="2" className="col-sm-12 value-sim-label1">
                    {starterCopy}
                </TextStyle>
                {starterCredit !== 1.0 && userSelectedDevice !== 'Watch' && (
                    <TextStyle alias="TextBodyShort" className="value-sim-label2 col-sm-12">
                        Just choose how you’d like to spend this amount.
                    </TextStyle>
                )}
            </div>
        );
    }

    renderRechargeLater() {
        const {
            loading: { updateCartInProgress },
            actions: { triggerSkipRechargeModal }
        } = this.props;

        return (
            <div className="recharge-later">
                <ActionButton
                    element="button"
                    variant="LowEmphasis"
                    disabled={updateCartInProgress}
                    onClick={() => {
                        if (this.state.timesClicked > 1) {
                            const { shoppingCartId } = this.props.appData.shoppingCart;
                            const activeTabName = this.getActiveTabName();
                            const selectedProduct = this.props.customerOffers.find((product) => product.tabName === activeTabName);
                            const offerDetails = {
                                productOfferingId: selectedProduct.tabId,
                                rechargeId: null,
                                starterCredit: 0,
                                shoppingCartId
                            };
                            this.props.actions.updateSelectedOffer({ productOfferingId: selectedProduct.tabId, ...this.props.plan });
                            addDataLayerEventInfo(dataLayerEventActions.SKIP_STEP);
                            this.props.actions.updateCart(offerDetails, true);
                        } else {
                            triggerSkipRechargeModal(true);
                            this.setState({
                                modalOpen: true,
                                timesClicked: this.state.timesClicked + 1
                            });
                        }
                    }}
                    label="Continue without recharging"
                />
            </div>
        );
    }

    renderPlanContent(categoryName) {
        const offer = this.props.customerOffers.find(offDetails => offDetails.tabName === categoryName);
        if (!offer) {
            return null;
        }
        return (
            <div role="list" id={offer.tabName.replace(/\s/g, '').toLowerCase()} key={offer.tabId} className="row no-gutters">
                {this.renderCardComponent(offer)}
                {offer.plans.length <= 1 ? (
                    <div className="col-md-5 col-xs-12 div-image card-container mx-auto">
                        <svg className="phone-illustration-image" />
                    </div>
                ) : null}
            </div>
        );
    }

    renderCardComponent(offer) {
        let hidePrice = false;
        const {
            appData: {
                uiState: { userSelectedDevice }
            }
        } = this.props;
        if (userSelectedDevice === 'Watch') hidePrice = true;
        return offer.plans.map((item) => <CardList hidePrice={hidePrice} {...this.props} productOfferingId={offer.tabId} plan={item} key={item.id} togglePlanModal = {this.togglePlanModal} setPlan = {this.setPlan} />);
    }

    getActiveTabName() {
        return this.state.categories.length === 1
            ? this.state.categories[0]
            : document.querySelector(`#${categoriesTabId} [role="tablist"] button[aria-selected="true"]`)?.textContent;
    }
}

function RechargeInfoContainer({ children, id }) {
    return (
        <div className="row no-gutters recharge-info-container mb-4">
            <div className="col-md-5 col-xs-12" tabIndex="-1" id={id}>
                {children}
            </div>
        </div>
    );
}
