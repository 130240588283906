import React, { useRef, useState } from 'react';
import { TextField } from '@able/react';
import './editFieldModal.css';
import { getAbleSpriteSheet } from '../../../../adapters/utils';
const ableSpriteSheet = getAbleSpriteSheet();

function EditName({
    modalFooter = () => { },
    input: {
        label, name: inputName, key: inputKey, validate, helpText, id, autocomplete
    } = {}
}) {

    const inputRef = useRef();
    const [name, setName] = useState({ value: '', error: '' });

    const onChangeName = ({ target: { value } = {} }) => {
        const newName = name;
        newName.value = value;
        if (name.touched) {
            newName.error = validate(name.value);
        }
        setName({ ...newName });
    }

    const normalizeValue = (inputValue) => {
        const normalizedValue = inputValue ? inputValue.toString().replace(/^\s+|\s+$|\s+(?=\s)/g, '') : inputValue ;
        onChangeName({ target: { value: normalizedValue } });
    };

    const validateName = ({ focus, event } = {}) => {
        if (event) {
            normalizeValue(event?.target?.value);
        }
        const error = validate(name.value);
        setName({
            ...name,
            touched: true,
            error,
            isValid: !error
        });
        if (focus) inputRef.current.focus();
        return {
            isValid: !error, error, data: {
                value: name.value,
                key: inputKey
            }
        };
    };

    const onBlur = () => {
        setTimeout(validateName, 100);
    }

    return (
        <React.Fragment>
            <div className="mt-4xl mb-3xl">
                <TextField
                    ref={inputRef}
                    name={inputName}
                    label={label}
                    required
                    maxLength={inputName === 'familyName' ? 50 : 40}
                    developmentUrl={ableSpriteSheet}
                    touched={name.touched}
                    defaultValue=""
                    invalidInputText={name.error}
                    invalid={!!name.error}
                    value={name.value}
                    onChange={onChangeName}
                    onBlur={(event) => onBlur({ focus: true, event })}
                    helpText={helpText}
                    id={id}
                    autoComplete={autocomplete}
                />
            </div>
            {modalFooter(() => validateName({ focus: true }))}
        </React.Fragment>
    );
}

export default EditName; 