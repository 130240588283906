import './existingNumber.css';
import { Error, Input, Select, Checkbox } from '../../components';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { MessageSection, TextStyle } from '@able/react';
import React, { Component } from 'react';
import { checkSimPattern, isEmpty, validateDOB, isMaximumYear } from '../../adapters/validations';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import { nextPage, getCurrentRouteName, focusOnHeading, submitFailure, getdayMonthYearFromDate, scrollToBody, focusOnElement, getBrandDetails, applyBrandDataInTemplate, getAbleSpriteSheet } from '../../adapters/utils';
import { SERVER_ERROR, PORTING_FAILED, CANNOT_PORT, TERMS_NOT_ACCEPTED, PORTING_DECLINED } from '../../adapters/errorCode';
import { notifyAnalytics, requestCodeMeta, addDataLayerEventInfo, addDataLayerApplicationItem, addApplicationLayer } from '../../adapters/analytics-utils';
import { BRANDS, dataLayerEventActions } from '../../adapters/constants';
import RadioBar from '../../components/RadioBar';
import PORT_CONSTANTS from './constants';
import BirthDate from '../../components/BirthDate/BirthDate';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import { updateStepperCustomData } from '../../components/Progress/module';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';

const ableSpriteSheet = getAbleSpriteSheet();
class ExistingNumber extends Component {
    constructor(props) {
        super(props);
        this.clearFormFields = this.clearFormFields.bind(this);
        this.state = {
            portingTerm: false
        };
    }

    componentDidMount() {
        const that = this;
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        scrollToBody();
        focusOnHeading();
        const {
            appData: {
                uiState: { isEsimActivation }
            }
        } = this.props;
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: 2,
            isLastStepCompleted: false
        };
        this.props.actions.updateStepperCustomData(stepperData);
        window.addEventListener('popstate', that.clearFormFields, false);
    }

    componentDidUpdate() {
        const { selectedServiceType, submitFailed, invalid, hasError, errorCode } = this.props;
        if (submitFailed && !selectedServiceType && !invalid) {
            focusOnElement('#error-description');
        }
        if (hasError && errorCode === PORTING_DECLINED) {
            focusOnElement('#error-description');
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Porting is declined by the process');
        }
        if (submitFailed && !selectedServiceType) {
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Required field. Please select your current service type.');
        }
    }

    render() {
        const {
            loading: { lockFields },
            errors: { hasError, errorCode }
        } = this.props;

        const {
            porting: { portingInProgress }
        } = this.props.appData;
        const { currentServiceProvider, handleSubmit } = this.props;
        // LOCAL VALIDATION
        const showOtherServiceProvider = currentServiceProvider === 'other';

        // Content
        const {
            form: { providersDropdown: providers, otherServiceProvider: otherServiceProviderLocale },
            errors: { portErrorTitle, portErrorInfo }
        } = PORT_CONSTANTS;

        const { brandName } = getBrandDetails();
        const providersList = Object.assign([], providers.providers);
        if (brandName == BRANDS.JBHiFi.name) {
            providersList.splice(2, 0, providers.telstraProvider);
        } else {
            providersList.splice(2, 0, providers.jbHiFiProvider);
        }

        return (
            <form className="existing-number-form" onSubmit={handleSubmit(this.submitExistingNumberForm)}>
                <div className="container port-block">
                    <GlobalBanner onPage="portingflow" className="infobanner-portingflow" />
                    <div className="row">
                        <div className="col-md-6">
                            <MessageSection
                                className="mt-3"
                                variant="Success"
                                developmentUrl={ableSpriteSheet}
                                description="Your number has been verified" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TextStyle element="h1" alias="HeadingA" className="port-block-header" tabIndex="-1">
                                Service details
                            </TextStyle>

                            {hasError && errorCode === PORTING_DECLINED && (
                                <div id="error-description" tabIndex="-1">
                                    <MessageSection
                                        className="mbxl"
                                        variant="Error"
                                        developmentUrl={ableSpriteSheet}
                                        titleText={portErrorTitle}
                                        description={Parser(portErrorInfo)} />
                                </div>
                            )}
                            <p className="title-message">You can use your current service until the transfer is complete.</p>
                            <TextStyle alias="TextBodyShort" className="mbxl" >
                                We just need a few more details about your current provider.
                            </TextStyle>
                            <fieldset className="service-data" disabled={lockFields}>
                                <Field name="currentServiceProvider" component={Select} prompt={providers.placeholder} label={providers.label} options={providersList} aria-required="true" />
                                {showOtherServiceProvider && <Field name="otherServiceProvider" component={Input} label={otherServiceProviderLocale.label} aria-required="true" />}
                                {this.renderCurrentServiceType()}
                            </fieldset>
                        </div>
                        {this.renderTransferTerms()}
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <SpinningButton
                                type="submit"
                                onSubmit={handleSubmit(this.submitExistingNumberForm)}
                                stylingClass="mob-button"
                                isDisabled={portingInProgress}
                                isLoading={portingInProgress}
                                buttonText="Continue"
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    clearFormFields() {
        const that = this;
        const href = window.location.href;
        const pathname = href.substring(href.lastIndexOf('/'));
        if (pathname === '/welcome') {
            this.props.dispatch(this.props.reset());
        }
        window.removeEventListener('popstate', that.clearFormFields);
    }

    /**
     * Personal form submit action
     *
     * @param {any} values
     * @memberof YourDetails
     */
    submitExistingNumberForm(values, dispatch, props) {
        const { selectedServiceType, currentServiceProvider, existingAccountNumber, dateOfBirth } = values;
        const { validatePortingDetails } = props.actions;
        const birthDate = getdayMonthYearFromDate(dateOfBirth);
        const dobDay = birthDate[0] ? birthDate[0].trim() : '';
        const dobMonth = birthDate[1] ? birthDate[1].trim() : '';
        const dobYear = birthDate[2] ? birthDate[2].trim() : '';
        const date = dobYear.concat(dobMonth, dobDay);
        const {
            loading: { formProcessed }
        } = props;
        let portInType = '';
        if (selectedServiceType && selectedServiceType === 'existingPlan') {
            portInType = 'postpaid';
        } else if (selectedServiceType && selectedServiceType === 'existingPrepaid') {
            portInType = 'prepaid';
        }

        const portingData = {
            data: {
                portInType,
                serviceProviderID: currentServiceProvider,
                accountNumber: selectedServiceType === 'existingPlan' ? existingAccountNumber : '',
                dateOfBirth: selectedServiceType === 'existingPrepaid' ? date : ''
            }
        };
        if (!formProcessed) validatePortingDetails(portingData, 'existing-number');
        else nextPage(updateStepperCustomData);
    }

    /**
     * Renders Form Errors
     *
     * @returns
     * @memberof ExistingNumber
     */
    renderErrors() {
        const {
            currentServiceProvider,
            errors: { hasError, errorCode },
            otherServiceProvider
        } = this.props;
        const { providerSelectionError, pageError, validationError = '' } = PORT_CONSTANTS.errors;

        let errorText = '';
        const { submitFailed, invalid } = this.props;

        if (submitFailed && invalid) {
            errorText = validationError;
            notifyAnalytics({ request: requestCodeMeta.validationUiFailure, errorText }, TERMS_NOT_ACCEPTED);
        } else if (currentServiceProvider === 'telstra' || currentServiceProvider === 'boost') {
            errorText = providerSelectionError[currentServiceProvider];
            notifyAnalytics({ request: requestCodeMeta.cannotPortOnline, errorText }, CANNOT_PORT);
        } else if (!isEmpty(otherServiceProvider) && (otherServiceProvider === 'telstra' || otherServiceProvider === 'boost') && currentServiceProvider === 'other') {
            errorText = providerSelectionError[otherServiceProvider];
        } else if (hasError) {
            switch (errorCode) {
                case SERVER_ERROR:
                    errorText = pageError.maxAttemptFailure;
                    notifyAnalytics({ request: requestCodeMeta.portingFailure, errorText }, SERVER_ERROR);
                    break;
                case PORTING_FAILED:
                    errorText = pageError.intermittentFailure;
                    notifyAnalytics({ request: requestCodeMeta.portingFailure, errorText }, SERVER_ERROR, errorText);
                    break;
                default:
                    break;
            }
        }

        return <Error boldText errorText={errorText} />;
    }

    /**
     * Renders user service type radio buttons
     * Also shows existing account number field on
     * existing plan selection
     *
     * @returns
     * @memberof ExistingNumber
     */
    renderCurrentServiceType() {
        // valdiation
        const { selectedServiceType, submitFailed } = this.props;
        const showAccountNumber = selectedServiceType === 'existingPlan';
        const showDateOfBirth = selectedServiceType === 'existingPrepaid';
        // Contents
        const { providerStatusOptions, existingAccountNumber, dobElement } = PORT_CONSTANTS.form;
        return (
            <div>
                <div className="form-group sim-details-radio-grp" role="radiogroup" aria-atomic="true" aria-live="polite" aria-label={providerStatusOptions.description} aria-required="true">
                    <TextStyle alias="Label" id="provider" className="mbm">{providerStatusOptions.description}</TextStyle>
                    {submitFailed && !selectedServiceType && (
                        <div id="error-description" tabIndex="-1">
                            <MessageSection
                                className="mbxl"
                                variant="Error"
                                developmentUrl={ableSpriteSheet}
                                titleText="Required field"
                                description="Please select your current service type." />
                        </div>
                    )}
                    <Field type="radio" id="prepaid" name="selectedServiceType" isActive={selectedServiceType === 'existingPrepaid'} value="existingPrepaid" label="Pre-Paid" component={RadioBar} />
                    <Field type="radio" id="postpaid" name="selectedServiceType" isActive={selectedServiceType === 'existingPlan'} value="existingPlan" label="Postpaid" component={RadioBar} />
                </div>
                {showAccountNumber && (
                    <div>
                        <Field className="existingAccountNumber" name="existingAccountNumber" component={Input} label={existingAccountNumber.label} aria-required="true" />
                        <MessageSection
                            className="mt-3"
                            variant="Information"
                            developmentUrl={ableSpriteSheet}
                            description="You can find this on your current provider’s phone bill." />
                    </div>
                )}
                {showDateOfBirth && (
                    <div>
                        <TextStyle alias="TextBodyShort" className="date-info">{dobElement.info}</TextStyle>
                        <Field className="existingAccountNumber" name="dateOfBirth" component={BirthDate} type="date" label={'Date of birth'} aria-required="true" />
                    </div>
                )}
                {/* {selectedServiceType && this.renderAccordian()} */}
            </div>
        );
    }

    updateAnaytics(e) {
        if (e.target.checked) {
            addDataLayerEventInfo(dataLayerEventActions.PORTING_TERMS_PG);
        }
    }

    renderTransferTerms() {
        const { acceptTermsNote0, acceptTermsNote1, acceptTermsNote2, acceptTermsNote3, acceptTermsNote4, acceptTermsNote5, label } = PORT_CONSTANTS.portingTerms;
        const { portingTerm } = this.props;
        const termsChecked = portingTerm ? 'active' : '';

        return (
            <div>
                <TextStyle element="h3" alias="HeadingD" className="terms">Transfer terms</TextStyle>
                <TextStyle element="h3" alias="TextBodyShort" className="transfer-terms" id="style-2">
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote0))}</span>
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote1))}</span>
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote2))}</span>
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote3))}</span>
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote4))}</span>
                    <span>{Parser(applyBrandDataInTemplate(acceptTermsNote5))}</span>
                </TextStyle>
                <div className={`terms-checked ${termsChecked}`}>
                    <Field name="portingTerm" value={this.state.portingTerm} id="portingTerm" component={Checkbox} onChange={this.updateAnaytics} label={Parser(applyBrandDataInTemplate(label))} aria-required="true" />
                </div>
            </div>
        );
    }
}

/**
 * Valdiation of current form state
 *
 * @param {any} values - Form values
 * @param {any} props - error messages
 */
const validate = (values, props) => {
    const { selectedServiceType, currentServiceProvider } = props;
    const { existingNumberField: msisdn = {}, existingAccountNumber, otherServiceProvider = {}, providersDropdown, dobElement } = PORT_CONSTANTS.form;
    const { portingTerms } = PORT_CONSTANTS;
    const checkExistingAccountNumber = selectedServiceType === 'existingPlan';
    const checkDateOfBirth = selectedServiceType === 'existingPrepaid';
    const errors = {};
    const ALPHANUMERIC_PATTERN = new RegExp(/^[0-9A-Za-z]*$/);
    if (isEmpty(values.msisdn)) {
        errors.msisdn = msisdn.errors.required;
    } else if (checkSimPattern(values.msisdn)) {
        errors.msisdn = msisdn.errors.pattern;
    }
    if (isEmpty(currentServiceProvider)) {
        errors.currentServiceProvider = providersDropdown.errors.required;
    }
    if (!checkExistingAccountNumber && !checkDateOfBirth) {
        errors.selectedServiceType = 'Please select serive type';
    }
    if (checkExistingAccountNumber && isEmpty(values.existingAccountNumber)) {
        errors.existingAccountNumber = existingAccountNumber.errors.required;
    } else if (values.existingAccountNumber && (values.existingAccountNumber.length < 4 || values.existingAccountNumber.length > 30)) {
        errors.existingAccountNumber = existingAccountNumber.errors.other;
    } else if (values.existingAccountNumber && !ALPHANUMERIC_PATTERN.test(values.existingAccountNumber)) {
        errors.existingAccountNumber = existingAccountNumber.errors.pattern;
    }
    if (currentServiceProvider === 'other' && isEmpty(values.otherServiceProvider)) {
        errors.otherServiceProvider = otherServiceProvider.errors.required;
    } else if (currentServiceProvider === 'other' && (values.otherServiceProvider.length < 3 || values.otherServiceProvider.length > 50)) {
        errors.otherServiceProvider = otherServiceProvider.errors.pattern;
    }
    if (checkDateOfBirth) {
        const dob = getdayMonthYearFromDate(values.dateOfBirth);
        const dobYear = dob[2] ? dob[2].trim() : '';
        const dobMonth = dob[1] ? dob[1].trim() : '';
        if (isEmpty(values.dateOfBirth)) {
            errors.dateOfBirth = dobElement.errors.empty;
        } else if (dobMonth.length !== 2) {
            errors.dateOfBirth = dobElement.errors.invalid;
        } else if (!isEmpty(dobYear) && !validateDOB(values.dateOfBirth)) {
            errors.dateOfBirth = isMaximumYear(values.dateOfBirth) ? dobElement.errors.dobMaxError : dobElement.errors.dobMinError;
        }
    }
    if (isEmpty(values.portingTerm) || !values.portingTerm) {
        errors.portingTerm = portingTerms.errors.empty;
    }
    return errors;
};

const onChange = (values, dispatch, props) => {
    const {
        loading: { formProcessed },
        actions: { portingResetForBack },
        currentServiceProvider = '',
        selectedServiceType = ''
    } = props;
    addApplicationLayer();
    let serviceTypeSelected;
    addDataLayerApplicationItem('provider', currentServiceProvider);
    if (selectedServiceType.toLowerCase() === 'existingprepaid') {
        serviceTypeSelected = 'Pre-Paid';
    } else if (selectedServiceType.toLowerCase() === 'existingplan') {
        serviceTypeSelected = 'Postpaid';
    }
    addDataLayerApplicationItem('serviceType', serviceTypeSelected);
    if (formProcessed) {
        portingResetForBack();
        if (values.portingTerm) props.clearFields(false, false, 'portingTerm');
    }
};

const fieldList = ['msisdn', 'currentServiceProvider', 'otherServiceProvider', 'selectedServiceType', 'existingAccountNumber', 'portingTerm', 'dateOfBirth'];

const ExistingNumberRedux = reduxForm({
    form: 'existingNumberForm',
    validate,
    destroyOnUnmount: false,
    fields: fieldList,
    onSubmitFail: submitFailure(fieldList),
    onChange
})(ExistingNumber);

/**
 * getting values from the same form
 *
 */
const selector = formValueSelector('existingNumberForm');
const ConnectStateAndForm = connect((state) => {
    const currentServiceProvider = selector(state, 'currentServiceProvider');
    const selectedServiceType = selector(state, 'selectedServiceType');
    const portingTerm = selector(state, 'portingTerm');
    const otherServiceProviderValue = selector(state, 'otherServiceProvider');
    const otherServiceProvider = !isEmpty(otherServiceProviderValue) ? otherServiceProviderValue.toLowerCase() : '';
    return { currentServiceProvider, selectedServiceType, otherServiceProvider, portingTerm };
})(ExistingNumberRedux);

export default ConnectStateAndForm;
