import './app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'url-polyfill';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import store, { persistor, history } from './store';
// import AddressDetails from './routes/address-details';
// import Register from './routes/register';
// import AuthorisedRepresentative from './routes/authorised-representative';
// import PortingTerms from './routes/porting-terms';
// import EsimOffers from './routes/esim-offers';
import App from './routes/app';
import Welcome from './routes/welcome';
import ExistingNumber from './routes/existing-number';
import YourIdDetails from './routes/your-id-details';
import YourContactDetails from './routes/your-contact-details';
import OfferDetails from './routes/recharge-plans';
import Payment from './routes/payment';
import ReviewDetails from './routes/review-details';
import ActivationGuide from './routes/activation-guide';
import ESIMMICROSOFT from './routes/esim-microsoft/presenter';
import { PrivateRoute } from './components';
import SessionTimeout from './components/SessionTimeout';
import Loader from './components/Loader/loader';
import { historyLocationListener, initLiveChat } from './adapters/utils';
import GlobalHeader from './components/GlobalHeader/globalHeader';
import loadConfig from './environment/environment';
import GlobalFooter from './components/GlobalFooter/GlobalFooter';
import { TelstraAuth } from './components/TelstraAuthLogin/telstra-auth';
import Progress from './components/Progress';
import { telstraAuthConfig } from './components/TelstraAuthLogin/okapiConfig';
import Usabilla from './usabilla/Usabilla';
// THIS IS A WORKAROUND TO LOAD BABEL-POLYFILL CONDITIONALLY WITHOUT EJECTING PROJECT IN CREATE-REACT-APP.
// In the dev environment telstra okapi auth loads babel-pollyfill's instance, so the local dependency load fails.
// telstra okapi auth is a MOCK ONLY LIBRARY for development and to replicate logged in user. IT IS NOT PUBLISHED IN PRODUCTION.
// thus, local babel dependency will always load in production with the code below.

if (!global._babelPolyfill) require('babel-polyfill'); // eslint-disable-line global-require, no-underscore-dangle

const PPA = () => {

    const [appReadyToMount, setAppReadyToMount] = useState(null);

    const loadScript = (url) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.head.appendChild(script);
        return script;
    };

    const setMyTComponentOmnitureEnvVariable = (env = 'dev') => {
        sessionStorage.setItem('application_environment', env === 'prod' ? 'production' : env);
    };

    const setupPrerequisites = async () => {
        try {
            const environment = await loadConfig();
            if (!window.appConfig) {
                window.appConfig = environment;
            }
            const { PPA_WEB_MESSENGER_URL, PPA_WEB_ANALYTICS_URL, PPA_ENV_MODE } = window.appConfig || {};
            setMyTComponentOmnitureEnvVariable(PPA_ENV_MODE);

            loadScript(PPA_WEB_ANALYTICS_URL);

            const chatScript = loadScript(PPA_WEB_MESSENGER_URL);
            chatScript.onload = () => {
                initLiveChat();
            };

            const telstraAuthConfiguration = telstraAuthConfig();
            window.TelstraAuth = new TelstraAuth();
            window.TelstraAuth.init(telstraAuthConfiguration);

            history.listen(historyLocationListener);
            setAppReadyToMount(true);
        } catch {
            setAppReadyToMount(false);
        }
    }
    useEffect(() => {
        setupPrerequisites();
    }, []);

    if (appReadyToMount === null) {
        return null;
    }
    if (appReadyToMount === false) {
        return <div style={{ marginTop: '40px' }}>Failed to load configuration! Please try again later.</div>;
    }
    return (
        <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
                <Router history={history}>
                    <div>
                        <GlobalHeader uniqueid="GlobalHeader" logoTheme="magenta" />
                        <Progress />
                        <Switch>
                            <Route exact path="/" component={App} />
                            <PrivateRoute exact path="/esim-microsoft-landing" component={ESIMMICROSOFT} />
                            <PrivateRoute exact path="/welcome" component={Welcome} />
                            <PrivateRoute exact path="/existing-number" component={ExistingNumber} />
                            {/* <PrivateRoute exact path="/porting-terms" component={PortingTerms} /> */}
                            {/* <PrivateRoute exact path="/authorised-representative" component={AuthorisedRepresentative} /> */}
                            {/* <PrivateRoute exact path="/register" component={Register} /> */}
                            {/* <PrivateRoute exact path="/address-details" component={AddressDetails} /> */}
                            {/* <PrivateRoute exact path="/esim-offers" component={EsimOffers} /> */}
                            <PrivateRoute exact path="/recharge" component={OfferDetails} />
                            <PrivateRoute exact path="/payment" component={Payment} />
                            <PrivateRoute exact path="/review-details" component={ReviewDetails} />
                            <PrivateRoute exact path="/activation-guide" component={ActivationGuide} />
                            <PrivateRoute exact path="/your-id-details" component={YourIdDetails} />
                            <PrivateRoute exact path="/your-contact-details" component={YourContactDetails} />
                            <Redirect from="/" to="/welcome" />
                        </Switch>
                        <SessionTimeout timeout={1000 * 60 * 20} permitRefresh />
                        <SessionTimeout timeout={1000 * 60 * 40} permitRefresh={false} />
                        <Usabilla />
                        <GlobalFooter />
                    </div>
                </Router>
            </PersistGate>
        </Provider>
    );
};

const container = document.getElementById('ppa');
const root = createRoot(container);
root.render(<PPA />);