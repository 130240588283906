import React from 'react';
import { TextStyle } from '@able/react';
import AUTORECHARGE_LOCALE from '../../../assets/locale/autorecharge-locale';
import './autorechargeenabled.css';
import { getExpiry } from '../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import defaultImgSrc from '../../../assets/img/calendar-icon.svg';

function AutoRechargeEnabledCard(props) {
    const {
        loggedIn = false, hasTdiLogin = false, newOfferSelected: {
            usageQuota = '', autoRechargeBonusData = '', autoRechargePercentage = '', autoRechargePrice = '', num
        } = {}, actualAmount = 0, starterCredit = 0, priceBeforeDiscount = 0
    } = props;
    const {
        AutoRechargeEnabledCard: {
            autoRechargeContent = '', authPara1 = '', unauthpara1 = '', title = ''
        }
    } = AUTORECHARGE_LOCALE;
    const price = priceBeforeDiscount || actualAmount || starterCredit;
    const expiry = getExpiry(usageQuota);
    if (!hasTdiLogin) {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - New TDI');
    } else {
        addDataLayerEventInfo('impression', 'auto recharge module', 'success', 'Auto recharge enabled - Existing TDI');
    }
    return (
        <div className="auto-recharge-details">
            <img className="cal-image" alt="Calender" src={defaultImgSrc} />
            <TextStyle className="pt-24 mtxxl" element="h3" alias="HeadingC">
                {title}
            </TextStyle>
            <hr />
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {autoRechargeContent}${price} every {expiry}.
            </TextStyle>
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {autoRechargeBonusData
                    ? `You'll receive ${autoRechargeBonusData} bonus data on your next ${num} recharges as part of your auto recharge special offer.`
                    : autoRechargePrice
                        ? `You'll receive ${autoRechargePrice} off your next ${num} recharges.` : autoRechargePercentage
                            ? `You'll receive ${autoRechargePercentage} off your next ${num} recharges.`
                            : null}
            </TextStyle>
            <TextStyle className="mtxxl" alias="TextBodyShort">
                {loggedIn || hasTdiLogin ? authPara1 : unauthpara1}
            </TextStyle>
        </div>
    );
}

export default AutoRechargeEnabledCard;
