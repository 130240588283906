import './welcome.css';
import React, { Component } from 'react';
import $ from 'jquery';
import { Loader, AppDisabled } from '../../components';
import ServiceDetails from './ServiceDetails/servicedetails';
import { isEmpty } from '../../adapters/validations';
import { focusOnHeading, focusOnProgress, isSelectedDeviceMobile } from '../../adapters/utils';
import { WELCOME_PAGE_TITLE } from '../../adapters/constants';
import WELCOME_LOCALE from '../../assets/locale/v1/welcome';
import { saveStateAndLoginWelcome } from '../../adapters/authentication-utils';
import FullPageError from '../../components/FullPageError/FullPageError';
import { GlobalMessage } from './GlobalMessage';

class Welcome extends Component {
    /**
     * @param {*} props
     * @memberof Welcome
     */
    constructor(props) {
        super(props);
        this.state = {
            showServiceDetails: false,
            deviceValidationError: false,
            activationError: false,
            scrolledToContinue: false
        };
        this.deviceTypeFocused = React.createRef(false);
        this.numberOptionFocused = React.createRef(false);
        this.UpdateDeviceError = this.UpdateDeviceError.bind(this);
        this.UpdateActivationFlow = this.UpdateActivationFlow.bind(this);
    }

    /**
     * when the component is mounted fetch AEM data
     * AND
     * if the customer logged in flag from is set to true, we want to
     * fetch the existing customer data at this stage.
     *
     * @memberof Welcome
     */
    UNSAFE_componentWillMount() {
        const {
            loading: { isModuleLoaded }
        } = this.props;
        const {
            appData: {
                uiState: { isEsimActivation, isCaimanLogin, isEsimAuth }
            }
        } = this.props;
        if (!isModuleLoaded) {
            this.props.actions.fetchWelcomeLocale();
        }
        if (isEsimActivation && !isCaimanLogin && isEsimAuth) {
            saveStateAndLoginWelcome();
        }
    }

    componentDidMount() {
        document.title = WELCOME_PAGE_TITLE;
        const {
            appData: {
                uiState: { isEsimActivation }
            }
        } = this.props;
        // To make Telstra Logo accessible through Screen Reader
        const x = document.querySelector("div.mt-logo.mt-logo--magenta svg[role='img']");
        x != null && x.setAttribute('aria-hidden', false);

        focusOnHeading();
        const stepperData = {
            totalSteps: isEsimActivation ? 7 : 6,
            currentStep: 1,
            isLastStepCompleted: false
        };
        this.props.actions.updateStepperCustomData(stepperData);

        // Hide usabilla for eSIM activation
        if (isEsimActivation) {
            window.usabilla_live('hide');
        }
    }

    /**
     * Redirects to manage service page when PPA launched with iccid as parameter
     * @param {any} props
     */
    UNSAFE_componentWillReceiveProps(props) {
        const {
            appData: { esimData: { iccid = '' } = {} }
        } = props;

        const {
            locale: { commonContent: { esimManageServicePage = '' } = {} }
        } = props;

        if (!isEmpty(iccid) && !isEmpty(esimManageServicePage)) {
            window.location = `${esimManageServicePage}?iccid=${iccid}`;
        }
        const elementView = document.getElementById('chkSimBtn2');
        if (elementView !== null && !this.state.scrolledToContinue) {
            elementView.scrollIntoView(true);
            $('#activationImage').on('load', () => {
                document.querySelector('body').scrollTop = elementView.offsetTop;
            });
            this.setState({ scrolledToContinue: true });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            errors,
            validations: { isNumberChecked }
        } = this.props;

        const {
            appData: {
                uiState: { selectedDevice, selectedActivationFlow }
            }
        } = this.props;

        const isDeviceTypeMobile = isSelectedDeviceMobile(selectedDevice);

        if (isNumberChecked && !selectedActivationFlow && !this.deviceTypeFocused.current) {
            document.getElementById('sim-details') && document.getElementById('sim-details').focus();
            this.deviceTypeFocused.current = true;
        }

        if (selectedDevice && isDeviceTypeMobile && !selectedActivationFlow && !this.numberOptionFocused.current) {
            document.getElementById('select-activation-flow-container') && document.getElementById('select-activation-flow-container').focus();
            this.numberOptionFocused.current = true;
        }

        if ((prevProps.errors !== errors && errors.hasNumberError) || this.state.deviceValidationError || this.state.activationError) {
            document.getElementById('server-error-description') && document.getElementById('server-error-description').focus();
        }

        // get current customer data
        // if (isEsimActivation && !isEmpty(loggedInCustomerData) && loggedIn !== prevProps.appData.uiState.loggedIn) {
        //     this.props.actions.getCustomerData();
        // }
    }

    /**
     * Lock field state on unmount
     *
     * @param {any} props
     * @memberof CustomerDetails
     */

    render() {
        const {
            appData,
            app: { hasServiceError },
            loading: { appLoading, lockFields },
            appData: {
                esimData: { customerAccountId, eSIMhealthCheckFailed },
                uiState: { isEsimAuth }
            },
            simValidationForm
        } = this.props;

        // global
        if (appLoading) return <Loader />;

        const { locale: { disableApp = {} } = {} } = this.props;
        if (disableApp.enabled) return <AppDisabled modalOpen />;

        // errors
        const { errors } = this.props;
        // props
        const { deviceData, validations, showPortingModal, areMultipleDevicesAvailable, app } = this.props;
        const { msisdn } = appData;
        // actions
        const { actions } = this.props;

        if (this.state.showServiceDetails) {
            focusOnProgress();
        }

        // Fix for reload esim issue
        let reloadedEsimFlow = false;
        const isEsimFlow = window.localStorage.getItem('esimFlow');
        if (window.performance && isEsimFlow) {
            if (window.performance.navigation.type === 1) {
                reloadedEsimFlow = true;
            } else {
                console.log('page not refreshed');
            }
        }
        let errorProps = {};
        if (hasServiceError) {
            errorProps = {
                errorMessage: 'You’ve reached the maximum number of Pre-Paid services on your account.',
                errorMessage1: 'Currently we can only support the activation of 35 services on a single account.',
                errorText: 'Unable to process request',
                isEsimFlow
            };
        } else {
            errorProps = {
                errorText: 'Something went wrong',
                errorMessage: 'We’re unable to process the request at this time.',
                isEsimFlow
            };
        }
        return (
            <div className="container ppa-block welcome-page">
                {(reloadedEsimFlow || hasServiceError) ? <FullPageError {...errorProps} /> : (
                    (!eSIMhealthCheckFailed && ((isEsimAuth && customerAccountId) || !isEmpty(msisdn) || !isEsimAuth)) && (
                        <React.Fragment>
                            <GlobalMessage />
                            <ServiceDetails
                                appData={appData}
                                app={app}
                                lockFields={lockFields}
                                actions={actions}
                                {...WELCOME_LOCALE.serviceDetails}
                                validations={validations}
                                errors={errors}
                                deviceData={deviceData}
                                areMultipleDevicesAvailable={areMultipleDevicesAvailable}
                                showPortingModal={showPortingModal}
                                UpdateDeviceError={this.UpdateDeviceError}
                                deviceValidationError={this.state.deviceValidationError}
                                UpdateActivationFlow={this.UpdateActivationFlow}
                                activationError={this.state.activationError}
                                simValidationForm={simValidationForm}
                            />
                        </React.Fragment>
                    ))}
            </div>
        );
    }

    UpdateDeviceError(isError) {
        this.setState({ deviceValidationError: isError });
    }

    UpdateActivationFlow(isError) {
        this.setState({ activationError: isError });
    }
}

export default Welcome;
