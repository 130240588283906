import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import View from './presenter';
import {
    initApp, completeInit, storeUserAccessToken, recheckAuthentication, getCustomerData, loginCheckStatusUpdate, storeLandingLocation, storeAcrClaim
} from './actions';
import { retrieveEsimParams } from './esim-actions';

export function mapStateToProps(state) {
    return {
        loading: state.app.loading,
        isAppLoaded: state.app.isAppLoaded,
        loginCheckInProgress: state.app.loginCheckInProgress,
        appData: state.app.appData
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                initApp,
                completeInit,
                storeUserAccessToken,
                storeAcrClaim,
                recheckAuthentication,
                getCustomerData,
                loginCheckStatusUpdate,
                storeLandingLocation,
                retrieveEsimParams
            },
            dispatch
        )
    };
}

const App = connect(mapStateToProps, mapDispatchToProps)(View);

export default App;
