import reactDom from 'react-dom';
import { MessageGlobal } from '@able/react';
import React from 'react';
import { getAbleSpriteSheet, isOutageWindow } from '../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

export function GlobalMessage() {

    const { PPA_GLOBAL_BANNER_TEXT, PPA_GLOBAL_BANNER_START_TIME, PPA_GLOBAL_BANNER_END_TIME } = window.appConfig;
    const show = isOutageWindow(PPA_GLOBAL_BANNER_START_TIME, PPA_GLOBAL_BANNER_END_TIME);

    const globalMessages = [];

    // Global banner message
    if (show) {
        globalMessages.push(
            <MessageGlobal
                key="global-banner"
                developmentUrl={ableSpriteSheet}
                variant="Attention"
                description={PPA_GLOBAL_BANNER_TEXT}
            />
        );
    }

    // 3G exit global banner
    globalMessages.push(
        <MessageGlobal
            developmentUrl={ableSpriteSheet}
            variant="Attention"
            description={
                <>
                    Before you activate, you must ensure your mobile device is compatible with our 4G/5G network and is able to make emergency calls to all services, including 000.
                    3G devices and some 4G devices will no longer work after the 3G network closure.{' '}
                    <a href="https://www.telstra.com.au/support/mobiles-devices/3g-closure" target="_blank">More about the 3G closure.</a>
                </>
            }
        />
    );

    return reactDom.createPortal(
        <div>
            {globalMessages}
        </div>,
        document.getElementById('global-message')
    );
}
