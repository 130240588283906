import './Select.css';

import React, { useEffect } from 'react';
import { Error } from '../../';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';

export const Select = ({ options, prompt, disabled, input = {}, label, meta = {}, type, ...custom }) => {
    const { name } = input;
    const { touched = false, error } = meta;
    let inputClassName = custom.className ? custom.className : '';
    inputClassName = touched && error ? `${inputClassName} has-error` : inputClassName;

    useEffect(() => {
        const { touched: tch = false, error: latestError, submitFailed: failed = false } = meta;
        if (failed && tch && latestError) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', latestError);
        }
    }, [meta]);

    return (
        <div className="form-element tppa-select">
            <label className="text-grey-dark" htmlFor={name}>
                {label}
            </label>
            <select {...input} {...custom} id={name} className={inputClassName} disabled={disabled} autoComplete="on" aria-describedby={`${name}-error-description`}>
                {prompt && (
                    <option value="" disabled>
                        {prompt}
                    </option>
                )}
                {options.map((option) => {
                    return (
                        <option disabled={option.disabled} key={Math.random(2125325)} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
            <i className="chev-down" aria-label="Select Option" />
            {touched && error && <Error errorDescriptionId={name} isValidationError errorText={error} errorIconClassName={'icon-alert-solid-round'} />}
            {<span aria-live="polite" aria-atomic="true" className="sr-only" id={`${name}Error`} />}
        </div>
    );
};

export default Select;
