/* eslint-disable */
import Arbiter from 'promissory-arbiter';
import Oidc from 'oidc-client';
import Logger from './logger';
import Feature from './feature';
let _self;
let _user;
let _mgr;
let _config;

// Patch ES6 promise with finally method
// eslint-disable-next-line
Promise.prototype.finally = function (callback) {
    let p = this.constructor;
    return this.then(
        (value) => p.resolve(callback()).then(() => value),
        (reason) =>
            p.resolve(callback()).then(() => {
                throw reason;
            })
    );
};

export class TelstraAuth {
    /**
     * Construct TelstraAuth objects and assign publish/subscribe methods from Arbiter.
     */
    constructor() {
        Object.assign(this, Arbiter);
    }

    /**
     * Initialize Auth module.
     * @param {Object} config Configuration options.
     * @returns {Promise<User>} A promise with the user data.
     */
    init(config) {
        _self = this;

        // If cookies are disabled on the browser then don't initialize this module
        if (!Feature.testCookies()) {
            return Promise.resolve();
        }

        // If module is initialized from an iframe then use parent's configuration
        if (Feature.iframe) {
            config = window.parent.TelstraAuth.config;
        }

        // TelstraAuth configuration
        config.logger = config.logger || console;
        config.logLevel = config.logLevel || 'NONE';
        config.logMsgPrefix = config.logMsgPrefix || '[TelstraAuth]:';
        config.arbiter = config.arbiter || { persist: true };
        config.component = config.component || {};
        config.component.selector = config.component.selector || '.account-info';
        config.component.registerUrl = config.component.registerUrl;
        config.oidc.userStore = new Oidc.WebStorageStateStore({ store: window.localStorage });

        // Oidc configuration
        config.oidc.automaticSilentRenew = config.automaticSilentRenewTimeout || !config.oidc.automaticSilentRenew ? false : config.oidc.automaticSilentRenew;
        if (config.oidcLogs) {
            Oidc.Log.logger = config.logger;
            Oidc.Log.level = Oidc.Log[config.logLevel];
        }

        // Set private variables
        _config = this.config = config;

        // Initialize logger
        Logger.init(_config.logger, _config.logLevel, _config.logMsgPrefix);

        // Create the user manager
        _mgr = new Oidc.UserManager(_config.oidc);

        // Call silent signin callback to inform the parent that this action has been invoked
        if (Feature.iframe) {
            return _mgr.signinSilentCallback();
        }

        // Set Oidc event listeners to track user session status
        _setEventListeners();

        // Execute logic to build/inject the account info component into the global header and mobile nav placeholers
        return _run()
            .then(() => {
                return _user;
            })
            .catch((error) => {
                return _user;
            });
    }

    /**
     * Trigger a redirect of the current window to the authorization endpoint.
     * @param {Object} [event] Browser Event object use to preventDefault behaviour on links.
     * @returns {Promise}
     */
    login(event) {
        // prevent propagation when this method is invlked by a DOM event
        if (event) {
            event.preventDefault();
        }
        return _mgr
            .signinRedirect({ redirect_uri: `${_config.oidc.redirect_uri}`, state: _config.oidc.state })
            .then(() => Logger.info('login: redirecting to the login page...'))
            .catch((error) => {
                Logger.error('login: error while redirecting to the login page.', error);
                return Promise.reject(error);
            });
    }

    /**
     * Trigger a redirect of the current window to the authorization endpoint.
     * @param {Object} [event] Browser Event object use to preventDefault behaviour on links.
     * @returns {Promise}
     */
     loginWithMfa(event) {
        Logger.info('loggin in with mfa...', _config.editContactConfig)
        // prevent propagation when this method is invlked by a DOM event
        if (event) {
            event.preventDefault();
        }
        return _mgr
            .signinRedirect({ redirect_uri: _config.oidc.redirect_uri, state: _config.oidc.state, acr_values: _config.editContactConfig.acr_values})
            .then(() => Logger.info('login: redirecting to the login page...'))
            .catch((error) => {
                Logger.error('login: error while redirecting to the login page.', error);
                return Promise.reject(error);
            });
    }

    /**
     * Trigger a redirect of the current window to the end session endpoint.
     * @param {Object} [event] Browser Event object use to preventDefault behaviour on links.
     * @returns {Promise}
     */
    logout(event) {
        if (event) {
            event.preventDefault();
        } // prevent propagation when this method is invlked by a DOM event

        return _mgr
            .signoutRedirect({ extraQueryParams: { TargetResource: _config.oidc.post_logout_redirect_uri } })
            .then(() => {
                Logger.info('logout: redirecting to the logout page ...');
                sessionStorage.clear();
                localStorage.clear();
            })
            .catch((error) => {
                Logger.error('logout: error while redirecting to the logout page.', error);
                return Promise.reject(error);
            });
    }

    logoutWhileExpiredSession(currentPathName) {
        const redirectURI = `https://fn1.myid-nonprod.telstra.com/identity/as/authorization.oauth2?response_type=token&client_id=b2c-prepaidrecharge&redirect_uri=https://recharge.fn1.np.in.telstra.com.au${currentPathName}&scope=openid%20profile`;
        return _mgr
            .signoutRedirect({ extraQueryParams: { TargetResource: redirectURI } })
            .then(() => Logger.info('logout: redirecting to the logout page ...'))
            .catch((error) => {
                Logger.error('logout: error while redirecting to the logout page.', error);
                return Promise.reject(error);
            });
    }

    /**
     * Check if the user is logged in and has a valid customer segment.
     * @returns {Promise<boolean>} A promise with the check status.
     */
    isUserValid() {
        return _mgr
            .getUser()
            .then((user) => {
                Logger.info('isUserValid: validating customer segment...');
                // return !!user && _config.invalidCustomerSegments.indexOf(user.profile.cust_segment) === -1;
                return true;
            })
            .catch((error) => {
                Logger.error('isUserValid: error while validating customer segment.', error);
                return Promise.reject(error);
            });
    }

    silentSigninCallback() {
        return _mgr
            .signinSilentCallback()
            .then((user) => {
                console.log('silent signin callback successful');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Check if the user is logged in.
     * @returns {Promise<boolean>} A promise with the check status.
     */
    isUserLoggedIn() {
        return _mgr
            .getUser()
            .then((user) => {
                Logger.info('isUserLoggedIn: checking if user is logged in...');
                return !!user;
            })
            .catch((error) => {
                Logger.error('isUserLoggedIn: error while checking if user is logged in.', error);
                return Promise.reject(error);
            });
    }
}

/** Run main logic to check if the user is authenticated and build the account info component */
function _run() {
    return _mgr
        .signinRedirectCallback()
        .then((user) => {
            // if this is a signin redirect action then set the user info
            Logger.info('signinRedirectCallback: Successful redirect signin.');
            localStorage.removeItem('oidc.user:' + _config.oidc.authority + ':' + _config.oidc.client_id);
            return (_user = user);
        })
        .catch((error) => {
            // otherwise this is a page reload
            Logger.info('signinRedirectCallback: Page reload.', error);
        })
        .finally(() => _createAccountInfoElement(_user));
}

/** Set user manager event listeners */
function _setEventListeners() {
    // Check if user session hs been established/re-established
    _mgr.events.addUserLoaded((user) => {
        Logger.info('events.addUserLoaded: Session has been established/re-established:');
        Arbiter.publish('auth:user_loaded', user.access_token, _config.arbiter);

        /** Enable automatic silent renew based on user iteractions with the page.
         *  This configuration has been added to the version of the oidc-client library provided by the RAA team.
         *  I will leave this line commented out because I disagree with making changes to the original oidc-client opensource library which is
         *  certified by the OpenId Fundation, instead I would prefer to write this user iteraction tracking logic in this module.
         */
        // _trackUserIteractions();

        _createAccountInfoElement(user);

        Logger.info('events.addUserLoaded: Automatic token renew:', user.access_token);
        Arbiter.publish('auth:token_renew', user.access_token, _config.arbiter);
    });

    // Check if user session has been terminated
    _mgr.events.addUserUnloaded(() => {
        Logger.info('events.addUserUnloaded: Session has been terminated.');
        Arbiter.publish('auth:user_unloaded', null, _config.arbiter);
    });

    // Check if access token is expiring
    _mgr.events.addAccessTokenExpiring(() => {
        Logger.info('events.addAccessTokenExpiring: Access token is expiring.');
        Arbiter.publish('auth:access_token_expiring', null, _config.arbiter);
    });

    // Check if access token has expired
    _mgr.events.addAccessTokenExpired(() => {
        Logger.info('events.addAccessTokenExpired: Access token has expired.');
        Arbiter.publish('auth:access_token_expired', null, _config.arbiter);
    });

    // Check for silent renew errors
    _mgr.events.addSilentRenewError((error) => {
        Logger.info('events.addSilentRenewError: Silent renew error:', error);
        Arbiter.publish('auth:silet_renew_error', null, _config.arbiter);
    });

    // Check if the user has signed out
    _mgr.events.addUserSignedOut(() => {
        Logger.info('events.addUserSignedOut: User has signed out.');
        Arbiter.publish('auth:user_signedout', null, _config.arbiter);
    });
}

/** Track user inactivity and execute automatic silent renew only if user has been active during the automaticSilentRenewTimeout period */
/** This configuration has been added to the version of the oidc-client library provided by the RAA team.
 *  I will leave this function commented out because I disagree with making changes to the original oidc-client opensource library which is
 *  certified by the OpenId Fundation, instead I would prefer to write this user iteraction tracking logic in this module.
 */
// function _trackUserIteractions() {
//   _renewTimeout = _config.automaticSilentRenewTimeout;
//
//   if (_renewTimeout) {
//     ['click', 'mousemove', 'keypress'].forEach(event => document.addEventListener(event, () => _renewInactiveTime = _renewInactiveTime < _renewTimeout ? 0 : _renewInactiveTime));
//
//     setInterval(() => {
//       Logger.info(`_trackUserIteractions: timeout: ${ _renewTimeout }, inactive_time: ${ _renewInactiveTime }, session_time: ${ _renewSessionTime }`);
//
//       if (_renewTimeout < _renewSessionTime && _renewTimeout > _renewInactiveTime) {
//         Logger.info('_trackUserIteractions: Invoke silent renew.');
//         _renewSessionTime = 0;
//       }
//
//       _renewSessionTime++;
//       _renewInactiveTime++;
//     }, 1 * 1000);
//   }
// }

/** Create account info component */
function _createAccountInfoElement(user) {
    // Emit the ready avent when the module has confirmed the session status of the user
    Arbiter.publish('auth:ready', user, _config.arbiter);
    window.history.replaceState({}, '', _config.oidc.redirect_uri);
    Logger.info('init: trigger ready event.');
}
