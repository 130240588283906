import './reviewdetails.css';
import { Error } from '../../components';
import { reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '../../adapters/validations';
import { getPassedEsimProps, removeEsimJourneyItem } from '../../adapters/store-utils';
import { nextPage, getCustomerState, getCurrentRouteName, focusOnHeading } from '../../adapters/utils';
import { SERVER_ERROR, BLACKLIST_ERROR } from '../../adapters/errorCode';
import OfferCard from './components/offerCard';
import store from '../../store';
import FullPageError from '../../components/FullPageError/FullPageError';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import { TextStyle } from '@able/react';

class ReviewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                serverError: 'We’re experiencing some technical difficulties completing your activation, but we’re working on it. Please try again later',
                blacklistError: 'Your Pre-Paid service activation attempt has been unsuccessful. Please call 1800 870 581 between 8am and 6pm (AEST), Monday to Friday for more information.'
            },
            esimActivationContent: {
                serviceNumberMessage: 'You’ll need this number to recharge and manage your account.',
                endOfTrailMessage: 'At the end of the trial, Telstra will contact you to help you select the best suitable plan and stay connected.',
                reserveEsimMsisdnFaliureError: 'Few of our services are currently undergoing maintainence (CAT-TESTING-LOG: Reserve MSISDN failed)',
                networkRegistrationInterval: 15
            }
        };
    }

    componentWillMount() {
        const {
            actions: { updateStepperCustomData }
        } = this.props;
        updateStepperCustomData({ isLastStepCompleted: false, currentStep: 6 });
    }

    componentDidMount() {
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        focusOnHeading();
    }

    /**
     * PAGE RENDER LOGIC.
     * handles page navigation once the service call for service activation is complete.
     * @param {any} props
     * @memberof ReviewDetails
     */
    componentWillReceiveProps(props) {
        const {
            loading: { formProcessed, lockFields },
            appData: { uiState: { isEsimActivation = false } = {} } = {}
        } = props;
        const {
            errors: { hasServerError }
        } = props;

        const {
            actions: { updateStepperCustomData }
        } = props;
        let navigate = false;

        // Navigate logic:
        // No server error & form is processed & fields are locked
        navigate = !hasServerError && formProcessed && !lockFields && !isEsimActivation;

        // Increased Stepper
        if (navigate) {
            // passing value as true because it is lastpage
            nextPage(updateStepperCustomData, true);
            // updateStepperCustomData({ isLastStepCompleted: true });
        } else if (formProcessed && isEsimActivation && !lockFields) {
            const {
                actions: { lockState },
                activationData = {},
                locale: {
                    esimNotifyPageLink,
                    form: {
                        esimActivationContent: { networkRegistrationInterval }
                    }
                }
            } = props;
            lockState();
            // TODO: Remove post ms config fix.
            window.location = `${esimNotifyPageLink}${getPassedEsimProps()}&activationCode=${activationData.activationCode}&regInt=${networkRegistrationInterval}`;
        }
    }

    /**
     * reset form submit flag to false and
     * lock fields\
     * @param {any} props
     * @memberof CustomerDetails
     */
    componentWillUnmount() {
        const {
            loading: { formProcessed },
            actions: { lockState }
        } = this.props;
        if (formProcessed) {
            lockState();
        }
    }

    render() {
        const {
            handleSubmit,
            appData: {
                loading: { activationInProgress }
            }
        } = this.props;

        const {
            uiState: { selectedActivationFlow }
        } = this.props.appData;

        // Get Service details
        const state = store.getState();
        let actualAmount;
        const { updateCart: { data: { cartTotalPrice = [] } = {} } = {} } = state.app.appData;
        if (cartTotalPrice.length !== 0) {
            const { price: { dutyFreeAmount: { value = '' } = {} } = {} } = cartTotalPrice[0];
            actualAmount = value;
        }
        const newOfferSelected = state.offerDetails.selectedOffer ? state.offerDetails.selectedOffer : {};
        const { portingNumber = '' } = state.welcome;
        const { hasErrorOccurred = false } = this.props.app;
        const { starterCredit, msisdn } = getCustomerState();
        const { skipRecharge } = this.props.appData;
        // validations
        const isPortingFlow = selectedActivationFlow === 'portNumber';

        // custom display
        const serviceNumber = isPortingFlow ? portingNumber : msisdn;
        const errorProps = {
            errorMessage: "We're unable to process the request at this time. Please try again later.",
            errorText: 'Unable to process request',
            context: 'Customer has tried to activate in PPA but error submitting activation order'
        };
        return (
            <React.Fragment>
                {!hasErrorOccurred && (
                    <div className="container review-details">
                        <GlobalBanner onPage="review" className="infobanner-review" />
                        <div className="row">
                            <div className="col-md-5 col-xs-12">
                                <TextStyle tabIndex="-1" className="header" element={'h1'} alias="HeadingA">
                                    {'Review & confirm'}
                                </TextStyle>
                                {starterCredit > 0 ? (
                                    <TextStyle alias="TextBodyShort" className="mbm">
                                        Almost done! Check these details are correct then activate your SIM.
                                    </TextStyle>
                                ) : (
                                    <div>
                                        <TextStyle alias="TextBodyShort" className="mb-3">
                                            You’re about to activate your SIM without recharging.
                                        </TextStyle>
                                        <TextStyle alias="TextBodyShort" className="mb-3">
                                            You won’t be able to make calls (other than emergency calls to 000), send texts or access data.
                                        </TextStyle>
                                        <TextStyle alias="TextBodyShort" className="mb-3">
                                            After 7 days you won’t be able to receive calls.
                                        </TextStyle>
                                    </div>
                                )}
                                {this.renderErrors()}
                            </div>
                            <div className="col-md-5 col-xs-12">{this.getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge)}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <SpinningButton
                                    onSubmit={handleSubmit(this.activateWithoutRecharge)}
                                    stylingClass="button"
                                    isDisabled={activationInProgress}
                                    isLoading={activationInProgress}
                                    buttonText="Activate SIM"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {hasErrorOccurred && (
                    <div>
                        <GlobalBanner onPage="review" className="infobanner-review" />
                        <FullPageError {...errorProps} />
                    </div>
                )}
            </React.Fragment>
        );
    }

    getCardDetails(serviceNumber, starterCredit, actualAmount, newOfferSelected, skipRecharge) {
        return <OfferCard {...this.props} plan={newOfferSelected} msisdn={serviceNumber} starterCredit={starterCredit} price={actualAmount} skipRecharge={skipRecharge} />;
    }

    addAnalyticEvent() {
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'Add recharge');
    }

    /**
     *  Submit review form for cart
     *
     * @param {any} values
     * @memberof YourDetails
     */
    activateWithoutRecharge(values, dispatch, props) {
        const {
            actions: { updateStepperCustomData, activateSim },
            loading: { lockFields },
            errors: { hasError }
        } = props;
        const { appData: { uiState: { isEsimActivation = false } = {} } = {} } = props;

        if (!lockFields) {
            if (isEsimActivation) {
                // Remove session storage flag for esim
                removeEsimJourneyItem();
            }
            activateSim();
        } else if (!hasError && !isEsimActivation) {
            nextPage(updateStepperCustomData);
            // updateStepperCustomData({ isLastStepCompleted: true });
        }
    }
    /**
     * Render page errors
     *
     * @returns
     * @memberof ReviewDetails
     */
    renderErrors() {
        const { errors: { hasError, errorCode } = {} } = this.props;
        const {
            errors: { serverError, blacklistError } = {},
            esimActivationContent: { reserveEsimMsisdnFaliureError = '' }
        } = this.state;
        let errorText = '';
        const { appData: { msisdn, uiState: { isEsimActivation = false } = {} } = {} } = this.props;
        if (hasError) {
            switch (errorCode) {
                case SERVER_ERROR: {
                    errorText = serverError;
                    if (isEmpty(msisdn) && isEsimActivation) errorText = reserveEsimMsisdnFaliureError;
                    break;
                }
                case BLACKLIST_ERROR:
                    errorText = blacklistError;
                    break;
                default:
                    break;
            }
        }
        return <Error errorText={errorText} />;
    }
}

const ReviewDetailsRedux = reduxForm({
    form: 'reviewDetailsForm',
    destroyOnUnmount: false
})(ReviewDetails);

const ConnectedStateAndForm = connect((state) => {
    // trigger form validation only when module is loaded
    const { loading: { isModuleLoaded } = {} } = state.reviewDetails || {};
    return { shouldValidate: () => isModuleLoaded };
})(ReviewDetailsRedux);

export default ConnectedStateAndForm;
