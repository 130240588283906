import './autocomplete.css';
import { Error } from '../../';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import Spinner from '@mytelstra/mt-design-system/lib/Spinner';
import searchDefault from '../../../assets/img/svg/search-default.svg';
import searchOnFocus from '../../../assets/img/svg/search-onFocus.svg';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';

class AutoComplete extends React.Component {
    /**
     * Creates an instance of AutoComplete.
     * Also create a blank array of options in state
     * as per the library requirement.
     * @param {any} props
     * @memberof AutoComplete
     */
    constructor(props) {
        super(props);
        this.state = { value: '', options: [], isLoading: false, itemSelected: false };
        this.handleSearch = this.handleSearch.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ value: this.props.selectedValue.value ? this.props.selectedValue.value : '' });
    }

    componentWillReceiveProps(nextProps) {
        const { meta: { touched = false, error: latestError, submitFailed = false } = {}, fieldType } = nextProps;
        const fieldTypeVal = fieldType === 'date';
        if (submitFailed && touched && latestError && !fieldTypeVal) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', latestError);
        }
        if (this.state.itemSelected && nextProps.formSubmitError) {
            this.setState({ value: '', itemSelected: false });
        }
    }

    /**
     * It renders the react bootstrap Async typeahead component
     * For passed options details visit:
     * http://ericgio.github.io/react-bootstrap-typeahead/
     *
     * Shows a validation tick mark when value of the address is valid
     * @memberof AutoComplete
     */
    render() {
        // passed parent props
        const { placeholder, label, input = {}, className = '', disabled, assistiveText, customErrorMessage = '' } = this.props;
        // Redux form validation variables
        const { touched = false, error, submitFailed = false, active = false } = this.props.meta;
        const hasError = !!customErrorMessage || submitFailed && touched && error;
        const inputClassName = hasError && !active ? `${className} has-error` : className;
        const validMessage = `${label} is valid`;
        const { value, options } = this.state;

        const assistiveTextElem = assistiveText && <p className="mt-text--body-xs-light">{assistiveText}</p>;
        const ariaDescribedByValues = `${input.name}-description ${input.name}-success-description ${input.name}-error-description`

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            id: `autosuggest-${input.name}`,
            className: `react-autosuggest__input ${inputClassName}`,
            placeholder,
            value,
            onChange: (event, { newValue }) => {
                document.getElementById('address-error-description') ? document.getElementById('address-error-description').style.display = 'none' : null;
                if (event.type === 'change' && input.value !== '') input.onChange('');
                if (touched && event.type === 'change' && error !== '') input.onChange('');
                this.setState({ value: newValue });
            },
            disabled,
            'aria-describedBy': ariaDescribedByValues
        };

        return (
            <div className="react-type-ahead">
                <label className="text-grey-dark" htmlFor={input.name}>
                    {label}
                    <span className="sr-only">- type to filter, then select from options below</span>
                </label>
                <div className={`ppa-autocomplete ${inputClassName}`}>
                    <Autosuggest
                        suggestions={options}
                        onSuggestionsFetchRequested={(props) => this.handleSearch(props)}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={(option) => {
                            input.onChange(option);
                            return option.label;
                        }}
                        onSuggestionSelected={() => {
                            this.setState({ itemSelected: true });
                        }}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                        highlightFirstSuggestion
                        focusInputOnSuggestionClick={false}
                        renderInputComponent={this.renderInputComponent}
                        renderSuggestionsContainer={this.renderSuggestionsContainer}
                    />
                </div>
                {
                    <span aria-live="polite" aria-atomic="true" className="sr-only">
                        {!error && input.value ? validMessage : ''}
                    </span>
                }
                {hasError ? <Error errorDescriptionId={input.name} isValidationError errorText={customErrorMessage || error} /> : assistiveTextElem}
                {<span aria-live="polite" aria-atomic="true" className="sr-only" id={`${input.name}Error`} />}
            </div>
        );
    }

    renderSuggestionsContainer({ containerProps, children }) {
        return (
            <div {...containerProps}>
                {children && (
                    <span className="aria-style" role="status" aria-live="assertive" aria-atomic="true">
                        {children.props.items.length} list items available, press enter to choose or use arrows to select another option.
                    </span>
                )}
                {children}
            </div>
        );
    }

    onSuggestionsClearRequested() {
        this.setState({
            options: []
        });
    }

    renderInputComponent(inputProps) {
        const { isLoading } = this.state;
        const searchImage = inputProps.className.includes('react-autosuggest__input--focused') ? searchOnFocus : searchDefault;
        return (
            <div className="select-input">
                <input {...inputProps} />
                {isLoading ? (
                    <Spinner className="select-input-spinner" size="mini" label="loading" />
                ) : (
                    <span className="search-icon">
                        <img aria-hidden="true" src={searchImage} alt="" />
                    </span>
                )}
            </div>
        );
    }

    renderSuggestion(option) {
        return <span>{option.label}</span>;
    }

    /**
     * Callback event when the input box is change to fetch the
     * results from service call.
     *
     * @param {any} query
     * @memberof AutoComplete
     */
    handleSearch({ value }) {
        if (value.trim().length < 5 || (value.trim().length - 5) % 3 !== 0) return;
        this.setState({
            isLoading: true
        });
        this.props.loadOptions(value.trim()).then((response) => {
            this.setState({
                isLoading: false,
                options: response.length > 0 ? response : []
            });
        });
    }
}

export default AutoComplete;
