import React, {useEffect} from 'react';
import { Select } from '@able/react';
import { getAbleSpriteSheet } from '../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
const ableSpriteSheet = getAbleSpriteSheet();

const AbleSelect = ({
  id,
  input={},
  label,
  options,
  allowEmpty,
  required,
  disabled,
  helpText,
  size,
  defaultValue,
  className = '',
  disableFirstOption,
  showValidationInline,
  validationInlineVariant,
  validationInlineText = '',
  "aria-required": ariaRequired,
  meta: {
      touched=false,
      error,
      submitFailed = false
  } = {},
  }) => {
  useEffect(() => {
      if (submitFailed && touched && error) {
          addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
      }
  },[submitFailed, touched, error]);
  return (
      <Select
        {...input}
        id={id}
        label={label}
        options={options}
        allowEmpty={allowEmpty}
        required={required}
        helpText={helpText}
        size={size}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        aria-required={ariaRequired}
        developmentUrl={ableSpriteSheet}
        disableFirstOption={disableFirstOption}
        invalid={!!(touched && error)}
        invalidMessage={touched && error}
        showValidationInline={showValidationInline}
        validationInlineVariant={validationInlineVariant}
        validationInlineText={validationInlineText}
       />
  );
};

export default AbleSelect;

