import { ActionButton, MessageSection, TextStyle } from '@able/react';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { trim } from '../../../../common/utils/userDetails';
import { AbleCheckBox } from '../../../../components/form/AbleCheckBox';
import EmailWrapper from '../emailWrapper/emailWrapper';
import OtcInfo from '../otcInfo/otcInfo';
import OtcVerify from '../otcVerify/otcVerify';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import {
    setBusinessContactEditingField,
    setBusinessContactEditingStep,
    setBusinessContactEditingValue,
    fetchOneTimeCode,
    resetEditContactModal,
    validateOTC,
    resetOTC,
    updateBusinessContactMedium,
    setUpdateBusinessContactsFormErrors
} from '../../module';
import { UpdateAddressModal } from './updateAddress';
import { UpdatePhoneModal } from './updatePhone';
import { updateCustomerBusinessData } from '../../../app/actions';
import FieldError from '../../../../common/components/fieldError/fieldError';
import { getUpdateBusinessContactDetailsErrors } from '../editContactDetails/editContactDetailsUtils';
import { FullModalError } from '../fullModalError/fullModalError';
import Modal from 'react-modal';
import reactDom from 'react-dom';
import { OTC_VERIFY_PURPOSE } from '../../../../adapters/constants';
import { getAbleSpriteSheet } from '../../../../adapters/utils';

const { businessContactForm } = CONTACT_PAGE_CONSTANTS;
const { SameAsPersonalContact, Phone, Email, Address } = businessContactForm;
const ableSpriteSheet = getAbleSpriteSheet();

function EditBusinessContactDetailsForm({
    formData,
    disabled,
    levelOfAuthentication,
    updateBusinessContactsMeta = {},
    contactDetails = {},
    actions: {
        setBusinessContactEditingFieldAction,
        setBusinessContactEditingStepAction,
        setBusinessContactEditingValueAction,
        fetchOneTimeCodeAction,
        resetEditContactModalAction,
        validateOTCAction,
        resetOTCAction,
        updateBusinessContactMediumAction,
        updateCustomerBusinessDataAction,
        setUpdateBusinessContactsFormErrorsAction
    },
    editingField,
    editingStep,
    editingValue,
    hasOtpSent,
    isProcessingOtp,
    isOTCVerified,
    isVerifyOTCInProgress,
    otcCounter,
    errors,
    selectedBusiness,
    isBackNavigation,
    contactMediaUpdating,
    updateBusinessContactsFormErrors: formErrors
}) {
    const contactSameAsPersonal = formData[SameAsPersonalContact.name];

    useEffect(() => {
        if (formErrors !== undefined) {
            const errors = getUpdateBusinessContactDetailsErrors(contactDetails, contactSameAsPersonal);
            setUpdateBusinessContactsFormErrorsAction(errors);
        }
    }, [contactSameAsPersonal]);

    const resetModal = () => {
        setBusinessContactEditingFieldAction('');
        setBusinessContactEditingStepAction(1);
        setBusinessContactEditingValueAction('');
    };

    if (editingField === Email.name && editingStep === 2 && hasOtpSent) {
        setBusinessContactEditingStepAction(3);
        resetEditContactModalAction();
    }

    if (editingField === Email.name && editingStep === 3 && isOTCVerified) {
        const payload = {
            data: {
                activatingMsisdn: false,
                contactMediumType: 'Email',
                contactMediumValue: editingValue,
                businessContacts: true,
                accountNumber: selectedBusiness
            }
        };
        updateBusinessContactMediumAction(payload, ({ success }) => {
            resetModal();
            if (success) {
                updateCustomerBusinessDataAction(selectedBusiness, { businessEmail: editingValue });
            }
        });
        resetOTCAction(false);
    }

    const [showUpdateToast, setUpdateToastVisibility] = useState();

    const openModal = (meta) => {
        setBusinessContactEditingFieldAction(meta.name);
    };

    const onCloseModal = () => {
        resetModal();
    };

    const onCloseErrorModal = () => {
        onCloseModal();
        resetEditContactModalAction();
    };

    const onContinueEmailModal = (email) => {
        setBusinessContactEditingStepAction(2);
        setBusinessContactEditingValueAction(email);
    };

    const onInitiateOtpVerification = () => {
        fetchOneTimeCodeAction({ contactMediumType: 'EMAIL', contactMediumValue: editingValue, otcType: OTC_VERIFY_PURPOSE.BUSINESS_CONTACT_EMAIL_UPDATE });
    };

    const onVerify = (otc) => {
        validateOTCAction({ data: { oneTimePinValue: otc } });
    };

    const onResendCode = () => {
        fetchOneTimeCodeAction({ contactMediumType: 'EMAIL', contactMediumValue: editingValue, otcType: OTC_VERIFY_PURPOSE.BUSINESS_CONTACT_EMAIL_UPDATE });
    };

    const renderUpdateButtonLOA2 = (label = '', onClick, payload) => {
        if (levelOfAuthentication === 'LOA2') {
            return (
                <ActionButton
                    element="button"
                    variant="LowEmphasis"
                    label={label}
                    onClick={onClick.bind(null, payload)}
                />
            );
        }
        return '';
    };

    const renderErrorMessage = (errorMessage) => {
        if (errorMessage) {
            return (
                <FieldError className="mb-3">
                    {errorMessage}
                </FieldError>
            );
        }
        return '';
    };

    const { isBusinessContact, counter, lastUpdatedField } = updateBusinessContactsMeta;
    const { errorCode, updateContactErrorOccured } = errors;

    useEffect(() => {
        setUpdateToastVisibility(isBusinessContact && lastUpdatedField);
    }, [counter, lastUpdatedField]);

    let infoMessage;
    if (isBusinessContact && lastUpdatedField) {
        if (lastUpdatedField === 'Telephone') {
            infoMessage = 'Your contact number has been updated.';
        } else if (lastUpdatedField === 'Address') {
            infoMessage = 'Your address has been updated.';
        } else if (lastUpdatedField === 'Email') {
            infoMessage = 'Your email has been updated.';
        }
    }

    const { addressline1 = '', addressline2 = '', addressline3 = '', localityName = '', state: customerState = '', postcode = '' } = contactDetails;
    return (
        <fieldset disabled={disabled}>
            <TextStyle element="legend" alias="HeadingC" className="mb-0 mt-4 business-subheading">{businessContactForm.title}</TextStyle>
            {showUpdateToast && (
                <Fragment>
                    <MessageSection developmentUrl={ableSpriteSheet} variant="Success" description={infoMessage} className="my-2" />
                    {reactDom.createPortal(infoMessage, document.getElementById('ycd-ariaLive-slot2'))}
                </Fragment>
            )}
            {!isBackNavigation &&
                <Field
                    aria-required
                    label={SameAsPersonalContact.label}
                    checked={contactSameAsPersonal}
                    component={AbleCheckBox}
                    id={SameAsPersonalContact.name}
                    name={SameAsPersonalContact.name}
                />
            }
            {!contactSameAsPersonal &&
                <Fragment>
                    <TextStyle className="mt-2" alias="Label">{Email.label}</TextStyle>
                    {contactDetails.businessEmail
                        ? <TextStyle alias="TextBodyShort">{contactDetails.businessEmail}</TextStyle>
                        : <FieldError showIcon={false}>
                            {Email.errorMessages.updateFormEmptyMessage}
                        </FieldError>}
                    {renderUpdateButtonLOA2(contactDetails.businessEmail ? Email.updateButtonLabel : Email.addButtonLabel, openModal, Email)}
                    {renderErrorMessage(formErrors && formErrors.businessEmail)}
                </Fragment>
            }
            <TextStyle className="mt-2" alias="Label">{Phone.label}</TextStyle>
            {contactDetails.businessPhoneNumber
                ? <TextStyle alias="TextBodyShort">{contactDetails.businessPhoneNumber}</TextStyle>
                : <FieldError showIcon={false}>
                    {Phone.errorMessages.updateFormEmptyMessage}
                </FieldError>}
            {renderUpdateButtonLOA2(contactDetails.businessPhoneNumber ? Phone.updateButtonLabel : Phone.addButtonLabel, openModal, Phone)}
            {renderErrorMessage(formErrors && formErrors.businessPhoneNumber)}
            {!contactSameAsPersonal &&
                <Fragment>
                    <TextStyle className="mt-2" alias="Label">{Address.label}</TextStyle>
                    {contactDetails.businessAddressId
                        ? <TextStyle alias="TextBodyShort">
                            {addressline1 && trim(addressline1)}
                            {addressline2 && `, ${trim(addressline2)}`}
                            {addressline3 && `, ${trim(addressline3)}`}
                            {localityName && `, ${trim(localityName)}`}
                            {customerState && `, ${trim(customerState)}`}
                            {postcode && `, ${postcode}`}
                        </TextStyle>
                        : <FieldError showIcon={false}>
                            {Address.errorMessages.updateFormEmptyMessage}
                        </FieldError>}
                    {renderUpdateButtonLOA2(contactDetails.businessAddressId ? Address.updateButtonLabel : Address.addButtonLabel, openModal, Address)}
                    {renderErrorMessage(formErrors && formErrors.businessAddressId)}
                </Fragment>
            }
            {updateContactErrorOccured
                ? <Modal
                    parentSelector={() => document.getElementById('modal-ppv')}
                    shouldCloseOnOverlayClick={false}
                    isOpen
                    className="modal-dialog modal-lg ppv-modal"
                    contentLabel={errors.errorText}
                    aria={{ labelledBy: 'full-modal-error-content', modal: 'true', role: 'dialog' }}
                    onRequestClose={onCloseErrorModal}
                    shouldCloseOnEsc
                >
                    <FullModalError errorContent={{ errorHeading: errors.errorText, errorText: errors.errorMessage, errorTextLink: errors.errorTextLink }} onCloseModal={onCloseErrorModal} />
                </Modal>
                : <Fragment>
                    {editingField === Email.name && editingStep === 1 && <EmailWrapper onCloseModal={onCloseModal} onContinue={onContinueEmailModal} />}
                    {editingField === Email.name && editingStep === 2 && <OtcInfo resetOTCState={resetOTCAction} errorCode={errorCode} onCloseModal={onCloseModal} onContinue={onInitiateOtpVerification} newEmail={editingValue} isProcessingOtp={isProcessingOtp} />}
                    {editingField === Email.name && editingStep === 3 && <OtcVerify onCloseModal={onCloseModal} onContinue={onVerify} onResendCode={onResendCode} newEmail={editingValue} hasOtpSent={hasOtpSent} isProcessingOtp={isProcessingOtp} isUpdating={contactMediaUpdating} otcCounter={otcCounter} isVerifyOTCInProgress={isVerifyOTCInProgress} errorCode={errorCode} />}
                    {editingField === Address.name && <UpdateAddressModal closeModal={onCloseModal} />}
                    {editingField === Phone.name && <UpdatePhoneModal closeModal={onCloseModal} />}
                </Fragment>}
        </fieldset>
    );
}

export const EditBusinessContactDetails = connect(state => ({
    formData: state.form.businessRegistrationForm ? { ...state.form.businessRegistrationForm.values } : {},
    updateBusinessContactsMeta: state.yourContactDetails.updateBusinessContactsMeta,
    contactDetails: state.app.loggedInCustomerData && state.app.loggedInCustomerData.businessDetails
        .find(item => item.accountNumber === state.yourContactDetails.selectedBusiness.selectedBusiness),
    levelOfAuthentication: state.app.appData.levelOfAuthentication,
    editingField: state.yourContactDetails.businessReigstrationMeta.editingField,
    editingStep: state.yourContactDetails.businessReigstrationMeta.editingStep,
    editingValue: state.yourContactDetails.businessReigstrationMeta.editingValue,
    hasOtpSent: state.yourContactDetails.editContact.hasOtpSent,
    isProcessingOtp: state.yourContactDetails.editContact.isProcessingOtp,
    isOTCVerified: state.yourContactDetails.validations.isOTCVerified,
    isVerifyOTCInProgress: state.yourContactDetails.validations.isVerifyOTCInProgress,
    otcCounter: state.yourContactDetails.validations.otcCounter,
    errors: state.yourContactDetails.errors,
    selectedBusiness: state.yourContactDetails.selectedBusiness.selectedBusiness,
    updateBusinessContactsFormErrors: state.yourContactDetails.updateBusinessContactsFormErrors,
    isBackNavigation: state.yourContactDetails.editContactDetailsFormSubmitted,
    contactMediaUpdating: state.yourContactDetails.updateBusinessContactsMeta.updating
}), dispatch => ({
    actions: bindActionCreators({
        setBusinessContactEditingFieldAction: setBusinessContactEditingField,
        setBusinessContactEditingStepAction: setBusinessContactEditingStep,
        setBusinessContactEditingValueAction: setBusinessContactEditingValue,
        fetchOneTimeCodeAction: fetchOneTimeCode,
        resetEditContactModalAction: resetEditContactModal,
        validateOTCAction: validateOTC,
        resetOTCAction: resetOTC,
        updateBusinessContactMediumAction: updateBusinessContactMedium,
        updateCustomerBusinessDataAction: updateCustomerBusinessData,
        setUpdateBusinessContactsFormErrorsAction: setUpdateBusinessContactsFormErrors
    }, dispatch)
}))(EditBusinessContactDetailsForm);
