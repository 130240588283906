import { isEmpty } from './validations';
import { getAppData } from './store-utils';
import { IPHONE_DEV_REDIRECT_URL_PAYMENT_FAILURE, IPHONE_PROD_REDIRECT_URL_PAYMENT_FAILURE, ANDROID_REDIRECT_URL_PAYMENT_FAILURE, IOS_MYT_PROFILE_PROD, IOS_MYT_PROFILE_DEV, ANDROID_MYT_PROFILE } from './constants';

export const sumbitCancelPayloadToDevice = () => {
    const { esimData: { transactionId, iccid } = {} } = getAppData();
    const purchaseResult = {
        userAccount: 'Bailed',
        purchaseInstrument: 'None',
        line: 'None',
        moDirectStatus: 'None',
        planName: ''
    };

    const notificationPayload = {
        ver: '1',
        success: false,
        activationCode: ''
    };

    if (!isEmpty(transactionId)) notificationPayload.transactionId = transactionId;
    if (!isEmpty(iccid)) notificationPayload.iccid = iccid;

    notificationPayload.purchaseResult = JSON.stringify(purchaseResult);
    const activationPayload = JSON.stringify(notificationPayload);
    notifyDevice(activationPayload);
};

export const sumbitSuccessPayloadToDevice = activationCode => {
    const { esimData: { transactionId, iccid } = {} } = getAppData();
    const purchaseResult = {
        userAccount: 'New',
        purchaseInstrument: 'New',
        line: 'New',
        moDirectStatus: 'Complete',
        planName: 'MyPlan'
    };

    const notificationPayload = {
        ver: '1',
        success: true,
        activationCode
    };

    if (!isEmpty(transactionId)) notificationPayload.transactionId = transactionId;
    if (!isEmpty(iccid)) notificationPayload.iccid = iccid;

    notificationPayload.purchaseResult = JSON.stringify(purchaseResult);
    const activationPayload = JSON.stringify(notificationPayload);

    notifyDevice(activationPayload);
};

export const notifyDevice = payload => {
    try {
        //eslint-disable-next-line
        DataMart.notifyPurchaseResult(payload);
    } catch (e) {
        //eslint-disable-next-line
        console.log(e);
    }
};

const checkEsimSourceIsIosDevice = () => {
    const sourceFromLS = window.localStorage.getItem('source') || '';
    const { esimData: { source = sourceFromLS } = {} } = getAppData();
    const isIosDevice = source.toLowerCase().includes('mytelstraiphone') || source.toLowerCase().includes('mytelstraios');
    return isIosDevice;
};

export const redirectToMyTelstra = () => {
    const isIosDevice = checkEsimSourceIsIosDevice();
    const env = window.appConfig.PPA_ENV_MODE;
    let redirectUrl = '';
    if (isIosDevice) {
        if (env === 'prod' || env === 'staging') {
            redirectUrl = IPHONE_PROD_REDIRECT_URL_PAYMENT_FAILURE;
        } else {
            redirectUrl = IPHONE_DEV_REDIRECT_URL_PAYMENT_FAILURE;
        }
    } else {
        redirectUrl = ANDROID_REDIRECT_URL_PAYMENT_FAILURE;
    }
    window.localStorage.removeItem('source');
    window.localStorage.removeItem('esimFlow');
    window.location.href = redirectUrl;
};

export const getEsimProfileDownloadDeeplink = ({ activationCode, serviceId }) => {
    const isIosDevice = checkEsimSourceIsIosDevice();
    const env = window.appConfig.PPA_ENV_MODE;

    if (isIosDevice) {
        if (env === 'prod' || env === 'staging') {
            return `${IOS_MYT_PROFILE_PROD + activationCode}&serviceId=${serviceId}`;
        }
        return `${IOS_MYT_PROFILE_DEV + activationCode}&serviceId=${serviceId}`;
    }
    return `${ANDROID_MYT_PROFILE + activationCode}&serviceId=${serviceId}`;
};
