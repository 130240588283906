const ESIM_MICROSOFT_LOCALE = {
    title: {
        heading1: 'Thanks for considering Telstra Mobile Plans.',
        heading2: 'We have a few options for you'
    },
    newCustomers: {
        header: 'New to Telstra?',
        para1: 'You can activate your device with a new service and eSIM.',
        para2: 'Find out more about plans here:',
        simPlan: {
            text: 'SIM Only Data Plans',
            link: 'https://www.telstra.com.au/internet/data-plans'
        },
        prepaidMobileBroadBandPlan: {
            text: 'Pre-paid Mobile Broadband',
            link: 'https://www.telstra.com.au/internet/mobile-broadband/prepaid'
        }
    },
    existingCustomer: {
        header: 'Already with Telstra?',
        para1: 'If you have an existing Telstra service you\'d like to convert to an esim,',
        para2: 'find more information here:',
        esimFAQ: 'eSIM FAQs',
        esimFAQLink: 'https://www.telstra.com/esimfaq'
    },
    help: {
        header: 'Need help?',
        helpAndSupport: {
            text: 'eSIM Help and Support',
            link: 'https://support.esim.telstra.com.au/app?eid='
        },
        contactTesltra: {
            text: 'Contact Telstra',
            link: 'https://www.telstra.com.au/contact-us'
        },
        telstraEnterprise: {
            text: 'Getting connected as a Telstra Enterprise or Government Customer',
            link: 'https://www.telstra.com.au/business-enterprise'
        }
    }
};

export default ESIM_MICROSOFT_LOCALE;
