import * as client from '../../adapters/client';

import {
    RETRIEVE_ESIM_PARAMS_ERROR,
    RETRIEVE_ESIM_PARAMS_SUCCESS,
    UPDATE_SELECTED_ESIM_OFFER,
    REQUEST_MSISDN_INIT,
    REQUEST_MSISDN_SUCCESS,
    REQUEST_MSISDN_ERROR,
    ESIM_PROFILE_INIT,
    ESIM_PROFILE_SUCCESS,
    ESIM_PROFILE_ERROR
} from '../actions';
import * as errorCodeMeta from '../../adapters/errorCode';
import { processMsidn, storeProcessToken } from '../../adapters/utils';
import { isEmpty } from '../../adapters/validations';
import { isNumberValid } from '../../routes/welcome/module';
import store from '../../store';
import { redirectToMyTelstra } from '../../adapters/esim-utils';
import { getCustomerData } from './actions';

export const selectOffer = (offerId) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_SELECTED_ESIM_OFFER, offerId });
    };
};

export const retrieveEsimParams = (esimParams, dataFactory = client.retrieveParams) => {
    return (dispatch) => {
        window.localStorage.removeItem('parameters');
        dataFactory(dispatch, retrieveEsimParamsSuccess, retrieveEsimParamsError, esimParams);
    };
};

/**
 * process response from service call
 * for customer validation
 * @param {any} packageData
 * @returns
 */
const processsRetrieveEsimParamsResponse = (packageData, isEsimAuth) => {
    let errorCode = '';
    const { customerAccountId, eSIMhealthStatus } = packageData;
    if (eSIMhealthStatus !== 'OK') {
        errorCode = errorCodeMeta.ESIM_HEALTH_NOT_OK;
    }
    if (isEsimAuth && isEmpty(customerAccountId)) {
        errorCode = errorCodeMeta.SERVER_ERROR;
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData };
};

export const retrieveEsimParamsSuccess = (responseData) => {
    const state = store.getState();
    const { isEsimAuth } = state.app.appData.uiState;
    const { hasError, errorCode, data } = processsRetrieveEsimParamsResponse(responseData.data, isEsimAuth);
    if (hasError) {
        return retrieveEsimParamsError(isEmpty(errorCode) ? errorCode.SERVER_ERROR : errorCode, data);
    }

    storeProcessToken(data.token);
    return (dispatch) => {
        dispatch({
            type: RETRIEVE_ESIM_PARAMS_SUCCESS,
            data,
            serviceAuthToken: data.token
        });
        dispatch(isNumberValid('9999999999999'));
        isEsimAuth && dispatch(getCustomerData());
    };
};

export const retrieveEsimParamsError = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    setTimeout(redirectToMyTelstra, 500);
    return { type: RETRIEVE_ESIM_PARAMS_ERROR, errorCode, data };
};

export const requestEsimMsisdn = (dataFactory = client.requestMsisdn) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_MSISDN_INIT });
        dataFactory(dispatch, requestMsisdnSuccess, requestMsisdnError);
    };
};

/**
 * process response from service call
 * for customer validation
 * @param {any} packageData
 * @returns
 */
const processrequestMsisdnResponse = (packageData) => {
    let errorCode = '';
    const { data: { resourceId } = {} } = packageData || {};

    const requestMsisdnStatus = packageData.status ? packageData.status : '';

    if (requestMsisdnStatus && typeof requestMsisdnStatus === 'string' && requestMsisdnStatus.toLowerCase() !== 'success') {
        if (requestMsisdnStatus.includes('-FATAL-')) {
            errorCode = requestMsisdnStatus;
        }
    }

    return { hasError: Boolean(errorCode.length), errorCode, data: packageData, resourceId };
};

export const requestMsisdnSuccess = (responseData) => {
    const { hasError, errorCode, data = {}, resourceId } = processrequestMsisdnResponse(responseData);
    if (hasError) {
        return requestMsisdnError(isEmpty(errorCode) ? errorCode.SERVER_ERROR : errorCode, data);
    }

    const processedMsisdn = processMsidn(resourceId);

    const payload = {
        type: REQUEST_MSISDN_SUCCESS,
        data,
        msisdn: processedMsisdn
    };

    return payload;
};

export const requestMsisdnError = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: REQUEST_MSISDN_ERROR, errorCode, data };
};

export const downloadEsimProfile = (dataFactory = client.esimProfileDownload) => {
    return (dispatch) => {
        dispatch({ type: ESIM_PROFILE_INIT });
        dataFactory(dispatch, esimProfileSuccess, esimProfileError);
    };
};

/**
 * process response from service call
 * for customer validation
 * @param {any} esimData
 * @returns
 */
const processEsimProfileResponse = (esimData) => {
    let errorCode = '';
    const profileStatus = esimData.status ? esimData.status : '';

    if (profileStatus && typeof profileStatus === 'string' && profileStatus.toLowerCase() !== 'success') {
        if (profileStatus.startsWith('CES-FATAL')) {
            errorCode = profileStatus;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: esimData.data };
};

export const esimProfileSuccess = (responseData) => {
    const { hasError, errorCode, data = {} } = processEsimProfileResponse(responseData);

    if (hasError) {
        return esimProfileError(errorCode);
    }

    return (dispatch) => {
        dispatch({
            type: ESIM_PROFILE_SUCCESS,
            data
        });
    };
};

export const esimProfileError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: ESIM_PROFILE_ERROR, errorCode };
};
