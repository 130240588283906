import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton, TextField, TextStyle } from '@able/react';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import { validateEmail } from './updateEmailUtils';
import { updateContactMedium } from '../../module';
import { updateEmailSuccess, getIsMobileUpdated, getIsMobileUpdateInProgress } from './ducks';
import './updateEmail.css';
import { focusOnElement, getAbleSpriteSheet } from '../../../../adapters/utils';
import { updateDataLayerObject, createDataLayerPage, addDataLayerEventInfo } from '../../../../adapters/analytics-utils';
const ableSpriteSheet = getAbleSpriteSheet();
const UpdateEmail = ({
    onClose,
    onContinue,
    isMobileUpdateInProgress,
    modalRef,
    readerId
}) => {
    const [emailInput, setEmailInput] = useState('');
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        updateDataLayerObject(createDataLayerPage('Enter New Email Address'));
        if (modalRef && modalRef.current) {
            modalRef.current.node.getElementsByClassName("modal-heading")[0].focus();
            modalRef.current.node.getElementsByClassName("ReactModal__Overlay")[0].removeAttribute("aria-modal");
        }
    }, []);

    const onEmailChange = ({ target: { value } = {} }) => {
        setEmailInput(value);
        setEmailError('');
    };

    const validateEmailInput = () => {
        setEmailError(validateEmail(emailInput));
    };

    const onClickContinue = () => {
        const err = validateEmail(emailInput);
        if (err) {
            focusOnElement('#emailInput');
            setEmailError(err);
            addDataLayerEventInfo('error', 'Inline', 'unavailable', err);
            return;
        }
        onContinue(emailInput);
    };

    return (
        <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">
            <React.Fragment>
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onClose} />
                </div>
                <div className="update-email-container">
                    <div tabIndex="-1" className="update-email-item modal-heading" id={readerId}>
                        <TextStyle element={'h2'} alias="HeadingB">Enter new email address</TextStyle>
                    </div>
                    <div className="update-email-item">
                        <TextField
                            id="emailInput"
                            name="emailInput"
                            label="Email address"
                            required
                            defaultValue=""
                            developmentUrl={ableSpriteSheet}
                            invalidInputText={emailError}
                            invalid={!!emailError}
                            value={emailInput}
                            onChange={onEmailChange}
                            onBlur={validateEmailInput}
                        />
                    </div>
                    <div className="update-email-item">
                        <SpinningButton onSubmit={onClickContinue} buttonText="Continue" isDisabled={isMobileUpdateInProgress} isLoading={isMobileUpdateInProgress} />
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
};

export default connect(
    (state) => ({
        isMobileUpdated: getIsMobileUpdated(state),
        isMobileUpdateInProgress: getIsMobileUpdateInProgress(state)
    }),
    {
        updateContactMediumAction: updateContactMedium,
        updateEmailSuccessAction: updateEmailSuccess
    }
)(UpdateEmail);
