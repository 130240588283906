import React, { useEffect } from 'react';
import { saveStateAndLogin } from '../../../adapters/authentication-utils';
import './auth.css';
import { MessageSection } from '@able/react';
import { getAbleSpriteSheet } from '../../../adapters/utils';
const ableSpriteSheet = getAbleSpriteSheet();

const Auth = (props) => {
    const { loginCheckInProgress, outageDisplay } = props;
    useEffect(() => {
        const button = document.getElementById('auth-button');
        if (loginCheckInProgress || outageDisplay) {
            button && button.setAttribute('disabled', true);
        } else button && button.removeAttribute('disabled');
    }, [loginCheckInProgress || outageDisplay]);

    const title = 'Got a Telstra ID?';
    //const desc = 'If you’re activating this service for business use, sign in now to add it to your account.';
    const desc = 'We recommend you sign in so you can add this service to your existing account and manage them easily.';

    return (
        <div id="auth-container">
            <MessageSection
                developmentUrl={ableSpriteSheet}
                variant="Information"
                description={desc}
                titleText={title}
                titleElement="h2"
                actionId="auth-button"
                actionCallback={()=> saveStateAndLogin()}
                actionElement="button"
                actionText="Sign in"
            />
        </div>
    );
};

export default Auth;
