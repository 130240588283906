import * as braintree from 'braintree-web';
import {
    BRAINTREE_DEVICE_DATA,
    PAYPAL_PAYMENT_INIT_DONE,
    PAYPAL_PAYMENT_INIT_ERROR,
    BILLPAY_HOSTED_FIELD_INITIALISED,
    BILLPAY_CARD_ERROR
} from '../../../adapters/constants';
import { ANALYTICS_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD } from '../../../routes/actions';
import { activateSim } from '../../../routes/app/actions';

let paypalCheckout = '';
let deviceData = '';

export const getBraintreePaymentInitialised = (payload) => {
    const AuthToken = payload.token;
    return async (dispatch) => {
        braintree.client
            .create({
                authorization: AuthToken
            })
            .then((clientInstance) => {
                braintree.paypalCheckout
                    .create({
                        client: clientInstance
                    })
                    .then((paypalCheckoutInstance) => {
                        paypalCheckout = paypalCheckoutInstance;
                        dispatch({ type: PAYPAL_PAYMENT_INIT_DONE, data: true });
                        // Set up PayPal with the checkout.js library
                        braintree.dataCollector
                            .create({
                                client: clientInstance,
                                kount: true,
                                paypal: true
                            })
                            .then((dataCollectorInstance) => {
                                deviceData = dataCollectorInstance.deviceData;
                                return dispatch({ type: BRAINTREE_DEVICE_DATA, data: payload });
                            })
                            .catch((error) => {
                                return dispatch({ type: PAYPAL_PAYMENT_INIT_ERROR, error });
                            });
                    });
            })
            .catch((error) => {
                return dispatch({ type: PAYPAL_PAYMENT_INIT_ERROR, error });
            });
    };
};

export const paypalPayment = (paymentData) => {
    return async (dispatch) => {
        return paypalCheckout
            .createPayment(paymentData)
            .then((paymentId) => {
                dispatch({ type: PAYPAL_PAYMENT_INIT_DONE, data: paymentId });
                return paymentId;
            })
            .catch((error) => {
                return dispatch({ type: PAYPAL_PAYMENT_INIT_ERROR, error });
            });
    };
};

export const paypalonAuthorize = (paymentData, paypalData) => {
    return async (dispatch) => {
        paypalCheckout.tokenizePayment(paypalData, async (error, payload) => {
            if (error) {
                return dispatch({ type: BILLPAY_CARD_ERROR, error });
            }
            dispatch({ type: ANALYTICS_PAYMENT_METHOD, data: 'Paypal' });
            dispatch({ type: UPDATE_PAYMENT_METHOD, data: 'PAYPAL' });
            dispatch(activateSim({
                customerUUID: paymentData.customerUUID,
                paymentAmount: paymentData.paymentAmount,
                identityProfileId: paymentData.identityProfileId,
                paymentType: 'NONCE',
                paymentMethodType: 'PAYPAL',
                paymentNonce: payload.nonce,
                deviceData
            }));
        });
    };
};

export const resetHostedFields = () => {
    return (dispatch) => {
        dispatch({ type: BILLPAY_HOSTED_FIELD_INITIALISED, data: false });
    };
};
