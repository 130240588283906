import React from 'react';
import Parser from 'html-react-parser';
import { ActionButton, TextStyle } from '@able/react';
import { isEmpty } from '../../adapters/validations';
import { showWebMessenger, focusOnHeading } from '../../adapters/utils';
import './FullPageError.css';
import { redirectToMyTelstra } from '../../adapters/esim-utils';
import defaultImgSrc from '../../assets/img/svg/error-icon-blue-lg.svg';
import esimErrorImgSrc from '../../assets/img/Illustrations_Scenes_Error.jpg';

/**
 * Displays error messages
 * boldText prop adds 'strong' to class name to get bold effect.
 * @param {any} props
 */
const FullPageError = ({ boldText = false, isSoftStopError, ...props }) => {
    if (isEmpty(props.errorText)) return false;
    if (!isSoftStopError) {
        document.title = props.errorText;
    }
    focusOnHeading();
    return (
        <div className="row fullpage-error-container">
            {!isSoftStopError && (props.isEsimActivation || props.isEsimFlow) ? (
                <div className="row esim-error-flow">
                    <div className="col-sm-6 esim-error-message-div" aria-live="polite" aria-atomic="true" id="esimLiveRegion">
                        <TextStyle alias="HeadingA" element="h1" aria-describedby="esimLiveRegion" className="error-header mbxxl">
                            {props.errorText}
                        </TextStyle>
                        <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{props.errorMessage}</TextStyle>
                        {props.errorMessage1 &&
                            <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message esim-error-message-1">{props.errorMessage1}</TextStyle>}
                        {props.errorMessage2 && <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{Parser(props.errorMessage2)}</TextStyle>}
                        {props.children}
                        {props.context && (
                            <div className="esim-chat-wrapper">
                                <TextStyle className="need-help" alias="TextBodyShort">Need help?</TextStyle>
                                <ActionButton className="message-us" element="button" label="Message us" variant="LowEmphasis" onClick={() => showWebMessenger(props.context || '')} />
                            </div>
                        )}
                        <ActionButton element="button" variant="MediumEmphasis" label="Start over" onClick={() => redirectToMyTelstra()} className="w-100 startover-button" />
                    </div>
                    <div className="col-sm-6" aria-live="polite" aria-atomic="true">
                        <img className="fullpage-error-img img-fluid mbxxl esim-error-img" alt="Error" src={`${props.errorImg ? props.errorImg : esimErrorImgSrc}`} />
                    </div>
                </div>
            ) : (
                <div className="col-sm-12" id="liveRegion">
                    <img className="fullpage-error-img img-fluid" alt="Error" src={`${props.isEsimActivation ? esimErrorImgSrc : props.errorImg ? props.errorImg : defaultImgSrc}`} />
                    <TextStyle alias="HeadingA" element="h1" aria-describedby="liveRegion" tabIndex="-1" className="error-header my-4">
                        {props.errorText}
                    </TextStyle>
                    <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{props.errorMessage}</TextStyle>
                    {props.errorMessage1 && <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{props.errorMessage1}</TextStyle>}
                    {props.errorMessage2 && <TextStyle alias="TextBodyShort" element="p" className="fullpage-error-message">{Parser(props.errorMessage2)}</TextStyle>}
                    {props.children}
                </div>
            )}
            {!props.isEsimActivation && props.context && (
                <div className="chat-wrapper">
                    <TextStyle className="need-help" alias="TextBodyShort">Need help?</TextStyle>
                    <ActionButton className="message-us" element="button" label="Message us" variant="LowEmphasis" onClick={() => showWebMessenger(props.context || '')} />
                </div>
            )}
        </div>
    );
};

export default FullPageError;
