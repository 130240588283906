/* eslint-disable max-len */
const PORT_CONSTANTS = {
    form: {
        existingNumberField: {
            label: 'Your mobile number',
            placeholder: 'Enter your current number',
            errors: {
                pattern: 'Your mobile number must begin with ‘04’ and be 10 digits long. Please try again.',
                required: 'Mobile number cannot be empty.'
            }
        },
        providersField: {
            label: 'Current service provider',
            placeholder: 'Enter your current provider',
            errors: {
                required: 'Provider cannot be empty.'
            }
        },
        providersDropdown: {
            label: 'Current service provider',
            placeholder: 'Please select',
            providers: [
                {
                    label: 'Boost',
                    value: 'boost'
                },
                {
                    label: 'Optus',
                    value: 'optus'
                },
                {
                    label: 'Virgin',
                    value: 'Virgin'
                },
                {
                    label: 'Vodafone',
                    value: 'vodafone'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ],
            telstraProvider: {
                label: 'Telstra',
                value: 'telstra'
            },
            jbHiFiProvider: {
                label: 'JB Hi-Fi',
                value: 'JB Hi-Fi'
            },
            errors: {
                required: 'Required field. Please select your current service provider.',
                selection: 'Please select any one of the services'
            }
        },
        providerStatusOptions: {
            description: 'Current service type',
            existingPrepaid: {
                label: 'Pre-Paid'
            },
            existingPlan: {
                label: 'Plan / Postpaid / Contract'
            }
        },
        existingAccountNumber: {
            label: 'Your current account number',
            placeholder: 'Enter your current account number',
            errors: {
                pattern: 'Invalid input. Please use alphanumeric characters only.',
                required: 'Required field. Please enter your account number.',
                other: 'Your current account number must be between 4 and 30 characters long. Please try again.'
            }
        },
        dobElement: {
            label: 'Date of Birth',
            info: 'The date of birth shown below needs to match the date of birth your current service provider has on file.',
            errors: {
                empty: 'Required field. Please use format DD/MM/YYYY.',
                invalid: 'Invalid input. Please use format DD/MM/YYYY.',
                dobMinError: 'Invalid input. The SIM owner must be at least 10 years old.',
                dobMaxError: 'Invalid input. Please enter a valid date of birth.'
            }
        },
        otherServiceProvider: {
            label: 'Name of service provider',
            placeholder: 'Please select',
            errors: {
                pattern: 'Your current service provider must be between 3 and 50 characters long. Please try again.',
                required: 'Required field. Please enter the name of your current service provider.'
            }
        },
        portingTerm: {
            label: 'I have read and accept the terms and agree to transfer my mobile number to Telstra.',
            errors: {
                required: 'This field is required.'
            }
        },
        nextButton: {
            label: 'Continue',
            placeholder: ''
        },
        liveChat: {
            label: 'Live Chat',
            section: ['telstra', 'dtq', 'prepaid']
        }
    },
    errors: {
        portErrorTitle: 'Porting Error',
        portErrorInfo: 'One time password verification was not successful. Please try again.',
        providerSelectionError: {
            telstra: "If you want to switch from a Telstra plan to a Telstra Pre-Paid plan you'll need to visit one of our Telstra store.",
            boost: 'It looks like you want to switch to Telstra Pre-Paid from Boost Mobile. This request can only be completed by contacting 24x7 Chat.'
        },
        pageError: {
            intermittentFailure: 'There has been an issue in matching your existing phone number with your personal details. Please check the number and try again.',
            maxAttemptFailure:
                'We’re still experiencing difficulties matching your existing service number with your personal details.' +
                'Please contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Existing:Max:Error” target=”_blank”>24x7 Chat</a>'
        },
        validationError: 'There is an error in one or more of the fields above. Please check and try again.',
        currentProviderLockError: "It looks like you've entered your new Telstra Pre-Paid number which you cannot port from. You'll need to enter an existing number you want to port from"
    },
    portingTerms: {
        label: 'I have read and accept the terms and agree to transfer my mobile number to {{displayName}}.',
        acceptTermsNote0: "<h3 className='boost-text--heading-xs mt-2 mb-2'><b>Important information about the transfer</b></h2>",
        acceptTermsNote1:
        "<h4 className='boost-text--heading-xs'><b>You acknowledge and confirm the following:</b></h3><ul className='terms-padding'><li>You’re authorised to request the transfer of the service numbers listed on your application.</li><li>You authorise and request to transfer those services to {{displayName}}. </li><li>Your Customer Authorisation is valid for 30 days from the date you give it.</li><li>You authorise {{displayName}} to disclose your number and transfer details to service providers, suppliers, and financial institutions for call and message routing, complaint handling, managing network faults, preventing and investigating fraud, and to transfer the service.</li><li>By transferring the number, your existing provider may disconnect the service and other services, incentives, and benefits associated with that service may stop. </li><li>You may have an existing contract with your current service provider. That contract may include an obligation to pay early termination charges to them and you may incur costs by transferring this number (such as Port Out Fees or other fees depending on your service).</li></ul>",
        acceptTermsNote2:
        "<h4 className='boost-text--heading-xs'><b>Validation:</b></h3><ul className='terms-padding'><li>We can’t transfer your number if it's not validated.</li><li>Once you agree to these transfer terms, we speak to your existing service provider. We share the transfer details to validate your request. </li><li>If we run into any issues, we contact you. </li><li>{{displayName}} only transfers validated service numbers you list in your application. We can’t transfer services or service identifiers not listed in your application.</li></ul>",
        acceptTermsNote3:
            "<h4 className='boost-text--heading-xs'><b>During the transfer:</b></h3><ul className='terms-padding'><li>Once validated, we begin the transfer. Subject to any cooling off period, the transfer takes a couple of days and your service may be interrupted during this time.</li><li>If your service is faulty whilst it is transferred, please contact your existing service provider. </li><li>Your service stays active with your existing service provider until it’s transferred.</li></ul>",
        acceptTermsNote4:
        "<h4 className='boost-text--heading-xs'><b>After the transfer:</b></h3><ul className='terms-padding'><li>We'll send you a text when the transfer is complete and you can start to use your new service. We’ll also send you details of your new service via email or mail.</li><li>If you are using a non-{{displayName}} device, check your device is compatible via <a href='telstra.com.au/coverage-networks/check-imei' target='_blank'>telstra.com.au/coverage-networks/check-imei</a> or contact us on the below details. </li><li>You may need to unlock or re-set your BYO handset before the transfer.</li></ul>",
        acceptTermsNote5: "<p>To confirm the transfer has occurred, to lodge an enquiry or complaint, or any other transfer related matter, please call us on <a href='tel:132200'>13 22 00.</a></p>",
        errors: {
            empty: 'Required field. Please tick the box to continue.'
        }
    }
};

export default PORT_CONSTANTS;
