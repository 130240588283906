export const hashPersonalDetails = (userDetails) => {
    let dataVal = '';
    const maskedUserDetails = {};
    for (const key of userDetails) {
        dataVal = userDetails[key];
        switch (key) {
            case 'AddressLine1': {
                dataVal = maskAddress(dataVal);
                break;
            }
            case 'AddressLine2': {
                dataVal = maskAddress(dataVal);
                break;
            }
            case 'AddressLine3': {
                dataVal = maskAddress(dataVal);
                break;
            }
            case 'FirstName': {
                dataVal = maskName(dataVal);
                break;
            }
            case 'MiddleName': {
                dataVal = maskName(dataVal);
                break;
            }
            case 'LastName': {
                dataVal = maskName(dataVal);
                break;
            }
            case 'Suburb': {
                dataVal = maskName(dataVal);
                break;
            }
            case 'Email': {
                dataVal = maskEmail(dataVal);
                break;
            }
            case 'ContactNumber': {
                dataVal = maskPhoneNumber(dataVal);
                break;
            }
            case 'PostCode': {
                dataVal = maskPostalCode(dataVal);
                break;
            }
            default:
        }
        maskedUserDetails[key] = dataVal;
    }
    return maskedUserDetails;
};

export const maskAddress = (dataVal) => {
    if (dataVal) {
        dataVal = dataVal + '';
        dataVal = dataVal.trim();
        dataVal = dataVal.startsWith(',') ? dataVal.substring(1) : dataVal;
        dataVal = dataVal.endsWith(',') ? dataVal.substring(0, dataVal.length - 1) : dataVal;

        let group1;
        let group2;
        const pattern = new RegExp('(.*\\d *){0,1}(.*){0,1}');
        const match = pattern.exec(dataVal);
        if (match) {
            group1 = match[1];
            group2 = match[2];
        } else {
            group2 = dataVal;
        }
        dataVal = (group1 ? group1 : '') + (group2 ? group2.substring(0, 1) + '*****' : '');
    }
    return dataVal;
};

export const maskName = (dataVal) => {
    dataVal = dataVal ? dataVal.substring(0, 1) + '*****' : '';
    return dataVal;
};

export const maskEmail = (dataVal) => {
    if (dataVal) {
        const emailArray = dataVal.split('@');
        if (emailArray.length > 1) {
            const emailReversed = emailArray[1].split('').reverse().join('');
            dataVal = emailArray[0].substring(0, 1) + '*****@' + emailArray[1].substring(0, 1) + '***.' + emailReversed.substring(0, emailReversed.indexOf('.')).split('').reverse().join('');
        }
    }
    return dataVal;
};

export const maskPhoneNumber = (dataVal) => {
    if (dataVal) {
        dataVal = dataVal + '';
        if (dataVal.substring(0, 3) === '+61') {
            dataVal = '0' + dataVal.substring(3);
        }
        dataVal = dataVal.substring(0, 2) + '**-***-' + dataVal.substring(7);
    }
    return dataVal;
};

export const maskPostalCode = (dataVal) => {
    dataVal = dataVal ? (dataVal + '').substring(0, 1) + '***' : '';
    return dataVal;
};

export const trim = (str, char = ',') => {
    if (!str) {
        return str;
    }
    str = str.trim();
    const regExp = new RegExp(`^${char}+|${char}+$`, 'g');
    return str.replace(regExp, '').trim();
};
