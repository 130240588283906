import React, { Fragment, useEffect } from 'react';
import { Field, blur, focus, formValueSelector } from 'redux-form';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { ActionButton, MessageInline, Spacing } from '@able/react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validateEmail } from '../updateEmail/updateEmailUtils';
import { isEmpty } from '../../../../adapters/validations';
import OtcVerify from '../otcVerify/otcVerify';
import { EXPIRED_OTC, INVALID_OTC, MAX_ATTEMPTS_OTC, MAX_ATTEMPTS_SEND_OTC } from '../../../../adapters/errorCode';
import { AbleTextField } from '../../../../components/form/AbleTextField';
import { sendPersonalEmailOneTimeCode, resetPersonalEmailValidation, resetPersonalEmail, resendPersonalEmailOneTimeCode, validatePersonalEmailOTC } from '../../module';
import { BRANDS, OTC_VERIFY_PURPOSE } from '../../../../adapters/constants';
import { focusOnElement, getAbleSpriteSheet, getBrandDetails } from '../../../../adapters/utils';
import parse from 'html-react-parser';

const { EmailAddress } = CONTACT_PAGE_CONSTANTS;

function PersonalEmailVerification({
    disabled = false,
    emailAddress,
    isPersonalEmailVerified,
    formSubmitClicked,
    actions: {
        focusField,
        blurField,
        sendPersonalEmailOneTimeCode: sendOneTimeCodeAction,
        resendPersonalEmailOneTimeCode: resendOneTimeCodeAction,
        resetPersonalEmailValidation: resetEmailValidationAction,
        validatePersonalEmailOTC: validateOTCAction,
        resetPersonalEmail: resetPersonalEmailAction
    },
    emailValidationMeta: { isOTCSent, isOTCResent, sendingOTC, resendingOTC, verifyingOTC, vidEmailConflictError, vidConflictEmail, errors = {}, otcCounter, lastVerifiedEmail }
}) {
    useEffect(() => {
        if (!verifyingOTC && !errors.hasError) {
            resetEmailValidationAction();
        } else if (!verifyingOTC && vidEmailConflictError) {
            onCloseModal();
        }
    }, [verifyingOTC]);

    const onCloseModal = () => {
        focusOnElement(`#${EmailAddress.sendCodeButtonId}`, null, true);
        resetEmailValidationAction();
    };

    const sendOTC = () => {
        sendOneTimeCodeAction({ contactMediumType: 'EMAIL', contactMediumValue: emailAddress, otcType: OTC_VERIFY_PURPOSE.CONTACT_EMAIL_ACCESS_VERIFICATION });
    };

    const verifyOTC = (oneTimePinValue) => {
        validateOTCAction({ data: { oneTimePinValue } }, emailAddress);
    };

    const onResendOTC = () => {
        resendOneTimeCodeAction({ contactMediumType: 'EMAIL', contactMediumValue: emailAddress, otcType: OTC_VERIFY_PURPOSE.CONTACT_EMAIL_ACCESS_VERIFICATION });
    };

    const onClickSendCode = () => {
        if (isEmpty(emailAddress) || validateEmail(emailAddress)) {
            focusField('yourContactDetailsForm', 'emailAddress');
            blurField('yourContactDetailsForm', 'emailAddress', emailAddress, true);
            document.getElementById('emailAddress').focus();
        } else {
            resetEmailValidationAction();
            sendOTC();
        }
    };

    const handleChange = () => {
        if (lastVerifiedEmail || vidConflictEmail) {
            resetPersonalEmailAction();
        }
    };

    const renderOTCVerifyModal = () => {
        const errorProps = {};
        if (errors.errorCode === MAX_ATTEMPTS_OTC) {
            errorProps.errorHeading = "You've reached the maximum number of attempts";
            errorProps.errorText = "You've run out of attemps to correctly enter your one-time code. Please try again in 20 minutes.";
        } else if (errors.errorCode === EXPIRED_OTC) {
            errorProps.errorText = 'The code you entered has expired. Please request a new code.';
        } else if (errors.errorCode === INVALID_OTC) {
            errorProps.errorText = 'Incorrect code. Please check the code and try again.';
        } else if (errors.errorCode === MAX_ATTEMPTS_SEND_OTC) {
            errorProps.errorHeading = "You've reached the maximum number of code resends";
            errorProps.errorText = "For your security, we can't send any more codes right now. Please try again in 20 minutes.";
        }
        return (
            <OtcVerify
                onCloseModal={onCloseModal}
                onContinue={verifyOTC}
                onResendCode={onResendOTC}
                errorPropsOverrides={errorProps}
                newEmail={emailAddress}
                hasOtpSent={isOTCResent}
                isProcessingOtp={resendingOTC}
                otcCounter={otcCounter}
                isVerifyOTCInProgress={verifyingOTC}
                errorCode={errors.errorCode}
            />
        );
    };
    const brandDetails = getBrandDetails();
    const errorMessages = {
        customerValidationStr1:
            parse("It looks like you’ve already provided your contact details some time back, but the email you entered does not match the one in our records. <br/> " + 
            "Try using a different email address or for help, we’ll need to assist you " +`${brandDetails.brandName == BRANDS.JBHiFi.name ? ' at your local JB Hi-Fi store.' : ' in store.'}`),
        customerValidationStr2: parse( `${brandDetails.brandName == BRANDS.JBHiFi.name ? " Please remember to bring your ID." : " To secure a specific time with one of our team members, you can book an appointment at your nearest store at <a href='https://www.telstra.com.au/telstra-store/appointments' style='text-decoration:none;color:blue;' target='_blank'>Telstra.com.</a> Please remember to bring your ID."}`)
    };
    const showVidConflictErrorMessage = vidEmailConflictError && vidConflictEmail === emailAddress;

    useEffect(() => {
        if (showVidConflictErrorMessage || isPersonalEmailVerified()) {
            document.getElementById('liveRegion').focus();
        }
    }, [showVidConflictErrorMessage, isPersonalEmailVerified()]);

    return (
        <React.Fragment>
            <div aria-live="polite" tabIndex="-1" id="liveRegion">
                {showVidConflictErrorMessage && (
                    <Spacing bottom="spacing2x">
                        <MessageInline
                            developmentUrl={getAbleSpriteSheet()}
                            variant="Error"
                            description={
                                <Fragment>
                                    {errorMessages.customerValidationStr1}
                                    {errorMessages.customerValidationStr2}
                                </Fragment>
                            }
                        />
                    </Spacing>
                )}
                {formSubmitClicked && !isPersonalEmailVerified() && !showVidConflictErrorMessage && !(isEmpty(emailAddress) || validateEmail(emailAddress)) && (
                    <Spacing id="ACMA-error" tabindex="-1" bottom="spacing2x">
                        <MessageInline developmentUrl={getAbleSpriteSheet()} variant="Error" description={EmailAddress.errorMessages.verificationRequired} />
                    </Spacing>
                )}
                {isPersonalEmailVerified() && (
                    <Spacing id="ACMA-success" tabindex="-1" bottom="spacing2x">
                        <MessageInline developmentUrl={getAbleSpriteSheet()} variant="Success" description={EmailAddress.verificationSuccess} />
                    </Spacing>
                )}
            </div>
            <Field
                name="emailAddress"
                autoComplete={EmailAddress.autocomplete}
                id="emailAddress"
                type="email"
                maxLength="80"
                helpText={EmailAddress.helpText}
                component={AbleTextField}
                autoCapilatize="none"
                label={EmailAddress.label}
                aria-required="true"
                disabled={disabled || sendingOTC}
                onChange={handleChange}
            />
            <Spacing bottom="spacing2x" top="spacing2x">
                <ActionButton
                    disabled={disabled || sendingOTC}
                    variant="MediumEmphasis"
                    className="w-100"
                    element="button"
                    id={EmailAddress.sendCodeButtonId}
                    label="Send code"
                    onClick={onClickSendCode}
                />
            </Spacing>
            {isOTCSent && renderOTCVerifyModal()}
        </React.Fragment>
    );
}

export default connect(
    (state) => {
        return {
            emailAddress: formValueSelector('yourContactDetailsForm')(state, 'emailAddress'),
            emailValidationMeta: state.yourContactDetails.personalEmailVerificationMeta,
            formSubmitClicked: (state.form.yourContactDetailsForm && state.form.yourContactDetailsForm.submitFailed) || (state.form.yourContactDetailsForm && state.form.yourContactDetailsForm.submitSucceeded)
        };
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(
                {
                    focusField: focus,
                    blurField: blur,
                    sendPersonalEmailOneTimeCode,
                    resendPersonalEmailOneTimeCode,
                    resetPersonalEmailValidation,
                    validatePersonalEmailOTC,
                    resetPersonalEmail
                },
                dispatch
            )
        };
    }
)(PersonalEmailVerification);
