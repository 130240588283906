import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { IconButton, TextStyle } from '@able/react';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import { editFieldModalMeta } from './constants';
import './editFieldModal.css';
import EditDOB from './editDOB';
import EditName from './editName';
import { focusOnModal, getAbleSpriteSheet } from '../../../../adapters/utils';
import { addDataLayerEventInfo } from '../../../../adapters/analytics-utils';

const ableSpriteSheet = getAbleSpriteSheet();

function EditFieldModal({
    editingField = '',
    closeModal = () => { },
    onContinue = () => { }
}) {
    const modalRef = useRef();

    useEffect(() => {
        if (modalRef && modalRef.current) focusOnModal(modalRef);
    }, []);

    if (!editingField ||
        (editingField !== 'firstName' &&
            editingField !== 'middleName' &&
            editingField !== 'familyName' &&
            editingField !== 'singleName' &&
            editingField !== 'birthDate')) {
        return '';
    }

    const { readerId, modalTitle, input: inputProps, modalDesc } = editFieldModalMeta[editingField] || {};


    const modalFooter = (validate) => {
        const onClickContinue = () => {
            const { isValid, data, error } = validate();
            if (!isValid) {
                addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
            } else {
                onContinue(data);
            }
        }

        return (
            <div className="edit-modal-footer">
                <SpinningButton
                    onSubmit={onClickContinue}
                    buttonText="Continue" />
            </div>
        );
    }

    const getModalContent = () => {
        switch (editingField) {
            case 'firstName':
            case 'middleName':
            case 'familyName':
            case 'singleName':
                return (
                    <EditName
                        input={inputProps}
                        modalFooter={modalFooter} />
                );
            case 'birthDate':
                return (
                    <EditDOB
                        input={inputProps}
                        modalFooter={modalFooter} />
                );
            default: return null;
        }
    }

    return (
        <Modal
            ref={modalRef}
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen={!!editingField}
            className="modal-dialog modal-lg ppv-modal modal-sm"
            onRequestClose={() => closeModal(editingField)}
            contentLabel={modalTitle}
            aria={{ labelledby: readerId, modal: 'true', role: 'dialog' }}
            shouldCloseOnEsc={true}
        >
            <ContentWrapper
                readerId={readerId}
                title={modalTitle}
                modalDesc={modalDesc}
                onClose={() => closeModal(editingField)}
            >
                {getModalContent()}
            </ContentWrapper>
        </Modal>
    );
}

function ContentWrapper({
    onClose,
    children = '',
    title = '',
    modalDesc,
    readerId = ''
}) {
    return (
        <div className="modal-content skip-recharge-content update-modal-content edit-field-modal-content">
            <div className="modal-header-no-border ppv-header edit-field-modal-header">
                <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onClose} />
            </div>
            <div className="px-5 pb-5">
                <div tabIndex="-1" className="edit-field-title modal-heading mb-4" id={readerId}>
                    <TextStyle element={'h2'} alias="HeadingB">{title}</TextStyle>
                    {modalDesc && <div className="mt-2">{modalDesc}</div>}
                </div>
                {children}
            </div>
        </div>
    );
}

export default EditFieldModal;
