import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import UpdateEmail from '../updateEmail/updateEmail';
import { focusOnModal } from '../../../../adapters/utils';

const EmailWrapper = ({ onCloseModal, onContinue }) => {
    const modal1 = useRef(null);

    useEffect(() => {
        modal1 && modal1.current && focusOnModal(modal1, 0);
    }, []);

    return (
        <Modal
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen
            className="modal-dialog modal-lg ppv-modal"
            contentLabel="Enter new email address"
            aria={{ labelledby: 'enter-email-modal-heading-1', modal: 'true', role: 'dialog' }}
            onRequestClose={onCloseModal}
            shouldCloseOnEsc
            ref={modal1}
        >
            <UpdateEmail readerId={'enter-email-modal-heading-1'} onClose={onCloseModal} onContinue={onContinue} />
        </Modal>
    );
};

export default EmailWrapper;
