import * as React from 'react';
import { generateUUID } from '../adapters/utils';
class Usabilla extends React.Component {
    componentDidMount() {
        this.loadUsabilla();
    }
    render() {
        return null;
    }

    loadUsabilla() {
        // eslint-disable-line
        if (window.usabilla_live) {
            const ppaUuid = generateUUID();
            window.usabilla_live('data', {
                custom: {
                    ppaUuid
                }
            });
        } else {
            setTimeout(() => {
                this.loadUsabilla();
            }, 2000);
        }
    }
}

export default Usabilla;
