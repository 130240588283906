import {
    RETRIEVE_ESIM_PARAMS_ERROR,
    RETRIEVE_ESIM_PARAMS_SUCCESS,
    REQUEST_MSISDN_INIT,
    REQUEST_MSISDN_SUCCESS,
    REQUEST_MSISDN_ERROR,
    ESIM_PROFILE_INIT,
    ESIM_PROFILE_SUCCESS,
    ESIM_PROFILE_ERROR
} from '../actions';
import { isEmpty } from '../../adapters/validations';

const actionHandler = (state = {}, action = {}) => {
    switch (action.type) {
        case RETRIEVE_ESIM_PARAMS_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true },
                    esimData: { ...state.appData.esimData, hasRetrievedParams: true, eSIMhealthCheckFailed: true }
                }
            });
        }

        case RETRIEVE_ESIM_PARAMS_SUCCESS: {
            const { data } = action || {};
            // eslint-disable-next-line no-unneeded-ternary
            const esimLoginStatus = data.customerAccountId ? true : false;
            const sourceValue = data.source ? data.source : state.appData.esimData.source;
            window.localStorage.setItem('source', sourceValue);
            return Object.assign({}, state, {
                serviceAuthToken: data.token,
                appData: {
                    ...state.appData,
                    // userAccessToken: data.customerAccountId,
                    uiState: { ...state.appData.uiState, loggedIn: esimLoginStatus },
                    esimData: {
                        ...state.appData.esimData,
                        source: sourceValue,
                        eid: data.eid ? data.eid : state.appData.esimData.eid,
                        model: data.model ? data.model : state.appData.esimData.model,
                        customerAccountId: data.customerAccountId,
                        encryptionType: data.encryptionType,
                        hasRetrievedParams: true,
                        eSIMhealthStatus: data.eSIMhealthStatus
                    }
                }
            });
        }

        case REQUEST_MSISDN_INIT: {
            const newState = Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    msisdn: action.msisdn,
                    esimData: { ...state.appData.esimData, hasCompletedEsimMsisdnRequest: false, hasRequestMsisdnError: false }
                }
            });
            return newState;
        }

        case REQUEST_MSISDN_SUCCESS: {
            const newState = Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    msisdn: action.msisdn,
                    esimData: { ...state.appData.esimData, hasCompletedEsimMsisdnRequest: true }
                }
            });
            return newState;
        }

        case REQUEST_MSISDN_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: {
                        ...state.appData.uiState,
                        appLock: true,
                        errors: {
                            ...state.errors,
                            hasError: true,
                            errorCode: action.errorCode
                        }
                    },
                    esimData: { ...state.appData.esimData, hasCompletedEsimMsisdnRequest: true, hasRequestMsisdnError: true }
                },
                hasErrorOccurred: true
            });
        }
        case ESIM_PROFILE_INIT: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true },
                    esimData: { ...state.appData.esimData, hasCompletedEsimProfileDownload: false, hasEsimProfileError: false, isProcessingProfile: true }
                }
            });
        }

        case ESIM_PROFILE_SUCCESS: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    msisdn: action.data.msisdn,
                    esimData: {
                        ...state.appData.esimData,
                        activationCode: action.data.activationCode,
                        source: action.data.source,
                        hasCompletedEsimProfileDownload: true,
                        hasEsimProfileError: false,
                        isProcessingProfile: false
                    }
                }
            });
        }

        case ESIM_PROFILE_ERROR: {
            return Object.assign({}, state, {
                appData: {
                    ...state.appData,
                    uiState: { ...state.appData.uiState, appLock: true },
                    esimData: { ...state.appData.esimData, hasCompletedEsimProfileDownload: false, hasEsimProfileError: true, isProcessingProfile: false }
                }
            });
        }

        default:
            return state;
    }
};

export default actionHandler;
