/* eslint-disable max-len */
const WELCOME_LOCALE = {
    allowedAttempts: 5,
    cancelActivation: {
        cancelBtnText: 'Cancel activation',
        simNumberText: 'SIM details: ',
        cancelModalConfig: {
            titleText: 'Are you sure you want to cancel?',
            descriptionText: 'If you would like to cancel, all information will be lost and you can retry later.',
            cancelBtnText: 'Cancel activation',
            continueText: 'Close and continue',
            goToReferer: true,
            explorePageURL: 'https://www.telstra.com.au/mobile-phones/prepaid-mobiles'
        },
        npsContent: {
            npsDesc: 'Help us improve.',
            npsLinkLabel: 'Complete our quick survey',
            npsLinkURL: 'https://feedback.telstra.com.au/?Telstra-Online/&ST=4&SEGT=Consumer&SN=PPA&PN=TD:TR:TR:activate:prepaid%20activation%20-%20my%20account:success'
        }
    },
    sessionTimeout: {
        titleText: 'Your Pre-Paid activation session',
        descriptionText:
            "Need a bit more time?&nbsp;<br><br>Your activation is about to timeout.&nbsp;To extend your session, select Continue. If you don\\'t extend your session within 10 minutes all information entered will be lost.",
        cancelBtnText: 'Cancel activation',
        continueText: 'Continue',
        timedOutTitleText: 'Unfortunately, your activation session has timed-out.',
        timedOutDescriptionText: "To start again, select &#34;Restart&#34;', start again, select &#34;Restart&#34;",
        timedOutBtnText: 'Restart'
    },
    appWarning: {
        enabled: true,
        enableScheduling: true,
        scheduledMessageStartTime: '30/08/18-10:00',
        scheduledMessageEndTime: '01/09/18-21:00',
        message:
            'We are currently unable to verify your identity using your Driver Licence. You can still activate your Pre-Paid service online using your Medicare card, Australian Passport or an International Passport with Australian visa. We apologise for any inconvenience this may cause'
    },
    appBanner: {
        enabled: false,
        message: 'This is a beta message testing. If you would line to go back please click <a href="https://www.telstra.com.au">here</a>'
    },
    disableApp: {
        enabled: false,
        title: 'Service Information',
        description:
            'Due to a planned system upgrade, some Pre-Paid services may take longer than normal to activate. You’ll be notified via SMS and email once your service is active. We apologise for any inconvenience.',
        button: {
            label: 'OK',
            link: 'https://www.telstra.com.au'
        }
    },
    commonContent: {
        esimManageServicePage: 'https://www.telstra.com.au/content/tcom/apps/davincippa/EsimLanding.htm'
    },
    welcome: {
        pageImage: '/img/welcome-id-image.png',
        title: "Let's get you connected.",
        desc: '',
        btnText: "Log in - its's faster",
        loginRegisterText: 'Got a Telstra ID?(Personal Customers Only)',
        titleAltStart: 'Hi ',
        titleAltMain: ', Thanks for taking up a Pre-Paid service',
        showCards: false,
        cards: []
    },
    serviceDetails: {
        title: 'SIM details',
        titleAltStart: 'Welcome back, ',
        numberCaptureMessage: 'We may need to add this number to your contact information.',
        introParagraph: 'Enter your 13-digit SIM serial number. It’s located on the back of your SIM kit packaging.',
        pageImage: 'img/sim-serial-number.png',
        imageAltText:
            'SIM Serial Number is printed on the SIM chip and also on the plastic card that the chip sits in. The SIM Serial Number and the Service Number are also printed on the packaging for the SIM card',
        formData: {
            simIDField: {
                label: 'SIM serial number',
                placeholder: '8000XXXXXXXXXXX',
                errors: {
                    pattern:
                        "Your Service number or SIM serial number is either 10 or 13 digits respectively. Your service number must begin with ‘04’ and be 10 digits long and your sim serial number must begin with '8' and be 13 digits long and cannot include spaces. Please try again.",
                    required: 'Please enter the service number from your SIM Starter Kit.',
                    genericFailure:
                        "<p>The service number or SIM serial you’ve entered is invalid, expired or already active. Please check you’ve entered the number correctly before trying again.</p><p><strong>Already activated?</strong>Remember, it can take up to 4 hours to activate your service. We’ll let you know by email and text as soon as it’s ready</p><p><strong>For $2 SIMs</strong>If you have an invalid or expired $2 SIM, <a href='' target='_blank'>click here to order a new one for free</a></p><p><strong>All other SIMs</strong>For any other invalid or expired SIM, you’ll need to return it to the place you bought it and ask for a refund.</p>",
                    orderInProgress:
                        'Your Pre-Paid activation is already underway. It can take up to 4 hours to activate your service. To find out if your service is already active, ensure that your SIM card is in your device, restart your device, and then try making a call or sending an SMS, or check that you’ve received a confirmation email, SMS or both from us.',
                    terminalFailure:
                        'We’re experiencing technical difficulties progressing to the next step, but we’re working on it. Please try again later or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Sim:Error” target=”_blank”>24x7 Chat</a>',
                    maxAttempts:
                        'We can see that you’re experiencing difficulties trying to activate your SIM card. Please try again later or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Sim:Max:Error” target=”_blank”>24x7 Chat</a>'
                }
            },
            checkNumberButton: {
                text: 'Check number'
            },
            deviceTypeSelect: {
                label: 'What do you want to use your service for?'
            },
            yourPhoneNumberLabel: 'Your phone number',
            selectedFlow: {
                option1: 'Get a new number',
                option2: 'Keep existing number'
            },
            flowSelectionLabel: {
                label: 'Would you like to get a new number or keep your existing one?',
            },
            portingInfoCard: {
                label: 'Keep your existing SIM (from your current provider) in your phone until your service is disconnected. Then insert your {{displayName}} SIM card.',
                esimLabel: 'Keep your existing SIM (from your current provider) in your phone until your service is disconnected.'
            },
            paragraphNewNumber: 'Your number is:',
            paragraphNewNumberhelp: 'We suggest you make a note of this number as you’ll need it to recharge',
            renderNewNumberNote: "Please make a note of this number, you'll need it to recharge.",
            serviceErrors: {
                errorgetMoreSimFailure: 'Sorry there seems to be a problem with our choose a new number feature, please try again later or chat with one of our agents now. We"re available 24x7.',
                errorLockSimFailure: 'Unexpected error has occurred. Please try again',
                errorLockSimMaxAttemptFailure: 'Sorry, we cannot process your request at this time. Please try again later or live chat with us now to help you complete your activation',
                errorGetOffersFailure:
                    'We’re experiencing technical difficulties progressing to the next step, but we’re working on it.  Please try again later or contact We’re experiencing technical difficulties progressing to the next step, but we’re working on it.  Please try again later or contact <a href=”https://livechat.telstra.com/TCOM:Mobile:PPA:Offers:Error” target=”_blank”>24x7 Chat</a>'
            },
            nextButton: {
                label: 'Next',
                placeholder: ''
            },
            liveChat: {
                label: 'Live Chat',
                section: ['telstra', 'dtq', 'prepaid']
            },
            profiles3G: {
                simProfile: ['COLES020'],
                enabled: true,
                popupTitle: 'Did you know?',
                popupContentPara1: 'Telstra will be switching off 3G in 2024.',
                popupContentPara2: 'After switch off, you may not be able to access the Telstra Mobile Network from this device for calls, texts or data. Find out',
                popupContentPara3: 'more',
                hyperlink: 'https://www.telstra.com.au'
            }
        },
        transferNumberform: {
            title: 'Transferring your number to Telstra',
            transferNumberPara1: 'Keep your old SIM card in your mobile phone and make sure you’re still connected to your current provider’s network.',
            transferNumberPara2: 'For extra security, we’ll send an SMS code to the number you’re trying to transfer to verify it’s really you.',
            instructions: {
                label: 'You’ll need:',
                step1: '1. A mobile phone with an active number that you want to transfer to Telstra',
                step2: '2. A valid form of ID'
            },
            nextButtonLabel: 'Next'
        },
        verifyNumberform: {
            verifyNumbertitle: 'Verify access to this number',
            verifyNumberPara: 'Enter the mobile number you’d like to transfer to {{displayName}}. We’ll send you an SMS code shortly.',
            verifyNumberField: {
                label: 'Mobile number ',
                placeholder: 'Number to be transferred ',
                note: 'Eg: 04XXXXXXX',
                errors: {
                    pattern: 'Invalid input. Please use format 04XXXXXXXX ',
                    required: 'Required field. Please enter your mobile number.',
                    telstraNumberNewErrorTitle: 'This is a Telstra prepaid number',
                    telstraNumberErrorTitle: 'This is a Telstra number',
                    telstraNumberErrorInfo: 'Transferring to the Pre-Paid network? Please message us so we can complete your activation.',
                    LegacyErrorTitle: 'Unable to process request',
                    LegacyErrorInfo: 'We’re unable to process this request online. Need help? Message us'
                }
            },
            buttonLabel: 'Send SMS code',
            codeSendingText: 'We’re sending your SMS code...'
        },
        verifySmsform: {
            verifySmsTitle: 'Enter SMS code',
            verifySmsResentTitle: 'We’ve sent you another SMS code',
            verifySmsPara: 'Please enter the code we sent to ',
            verifySmsField: {
                label: 'SMS code',
                note: 'This is a 6-digit number.',
                errors: {
                    pattern: 'Invalid entry. Please enter a valid code.',
                    required: 'Required field. Please enter the 6-digit code.'
                }
            },
            verifySmsButtonLabel: 'Verify',
            resendSmsText: 'Didn’t receive the SMS code? ',
            resendLink: 'Send new SMS code',
            faqText: 'Need help? See our <a href="http://tel.st/ppv-faq" target="_blank">FAQs</a>',
            codeVerifyingText: 'We’re verifying your number...',
            ppvMessenger: {
                textMsg: 'If you are still having trouble, Please ',
                context: 'Customer is porting in (self serve), but can’t receive OTP. Alternate verification reqd. Service id = '
            }
        },
        smsCheckSuccessform: {
            icon: '/img/green-tick.svg',
            successPara: 'SMS check successful',
            continuButtonLabel: 'Continue'
        },
        formErrors: {
            maxAttemptsError: {
                icon: '/img/accesserror.svg',
                label: 'Unable to process request ',
                errorPara: 'You’ve run out of attempts to correctly enter your SMS code.'
            },
            codeExpiredError: {
                icon: '/img/accesserror.svg',
                label: 'Incorrect SMS code',
                errorPara: 'You’ve run out of attempts to correctly enter your SMS code. Request a new one below.',
                buttonText: 'Send new SMS code'
            },
            sendCodeError: {
                icon: '/img/accesserror.svg',
                label: 'Something went wrong',
                errorPara: 'We’re unable to process the request at this time. Please try again later.'
            },
            validateCodeError: {
                icon: '/img/accesserror.svg',
                label: 'Unable to process request',
                errorPara: 'We’re unable to process the request at this time. Please try again later.'
            },
            incorrectCode: {
                label: 'Incorrect SMS code',
                errorPara: 'Please check your SMS code and try again.'
            },
            portError: {
                label: 'Unable to process request',
                errorPara: "You've run out of attempts to correctly enter your SMS code."
            },
            portgenericFatalError: {
                label: 'Unable to process request',
                errorPara: "We're unable to process this request online. Try transferring your existing number later or select a new number."
            },
            misOrderInProgressError: {
                icon: '/img/svg/error-icon-blue-lg.svg',
                label: 'Mobile number already in use',
                errorPara1: "This mobile number is already being transferred to Telstra, submitted on ",
                errorPara2: "To transfer a different number, try again."
            }
        }
    },
    landingPage: {
        cards: [
            {
                cardTitle: 'Already with Telstra ?',
                cardIcon: 'icon-my-profile-tick-presence',
                cardText: 'For a quicker activation, sign in with your Telstra ID. You can manage all of your services with the same ID.',
                btnText: 'Sign in'
            },
            {
                cardTitle: 'New to Telstra ?',
                cardIcon: 'icon-my-profile',
                cardText: "If you're new to Telstra, you can activate your service and sign up for a Telstra ID later.",
                btnText: 'Continue as guest'
            }
        ]
    },
    errorContext: {
        SIM_PRE_CONDITION: 'This is a Blackhawk SIM that hasn’t been unlocked',
        EXCEEDED_ATEMPTS: 'Customer has entered SIM serial no. incorrectly 5 times'
    }
};

export default WELCOME_LOCALE;
