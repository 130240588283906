import React from 'react';
import { RadioButton as AbleRadioButton } from '@able/react';

class RadioButton extends React.Component {

    componentWillReceiveProps(nextProps) {
        const { meta: { error: latestError, submitFailed = false } = {}, updateError } = nextProps;
        if (typeof updateError === 'function') {
            const errorUpdate = !!(submitFailed && latestError);
            updateError(errorUpdate);
        }
    }

    render() {
        const { input, label, isActive, id, radioValue } = this.props;
        return (
            <AbleRadioButton
                {...input}
                className={isActive ? 'isActive' : ''}
                name={input.name}
                id={id || ''}
                onChange={() => input.onChange(radioValue)}
                checked={isActive}
                value={radioValue}
                label={label}
                describedById={`${radioValue}-description`}
            />
        );
    }
}

export default RadioButton;
