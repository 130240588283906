import * as client from '../../adapters/client';
import * as errorCodeMeta from '../../adapters/errorCode';
import {
    NONPHYSICAL_ADDRESS_ERROR,
    EXISTING_CUSTOMER_NOT_AVAILABLE,
    SERVER_ERROR,
    BAD_REQUEST,
    MULTIPLE_CUSTOMERS_FOUND,
    SHOPPING_CART_CREATION_ERROR,
    FATAL_ERROR,
    NETWORK_FAILURE
} from '../../adapters/errorCode';
import {
    EXISTING_CUSTOMER_FETCH_SUCCESS,
    EXISTING_CUSTOMER_FETCH_ERROR,
    CREATE_SHOPPING_CART_SUCCESS,
    CREATE_SHOPPING_CART_ERROR,
    RESET_EXISTING_CUSTOMER_PROCESS,
    CUSTOMER_CHECK_DONE,
    CUSTOMER_CHECK_ERROR,
    YOUR_CONTACT_DETAILS_INIT_DONE,
    YOUR_CONTACT_DETAILS_INIT_ERROR,
    RESET_CONTACT_DETAILS_FORM,
    LOCK_STATE,
    ADDRESS_LOOKUP_FAILURE,
    ADDRESS_LOOKUP_SUCCESS,
    ADDRESS_VERIFICATION_FAILURE,
    ADDRESS_VERIFICATION_INIT,
    ADDRESS_VERIFICATION_REQUIRED,
    ADDRESS_VERIFICATION_REQUIRED_STATUS,
    ADDRESS_VERIFICATION_SUCCESS,
    ADDRESS_VERIFICATION_INIT_UPDATE_CONTACT_DETAILS,
    ADDRESS_VERIFICATION_SUCCESS_UPDATE_CONTACT_DETAILS,
    ADDRESS_VERIFICATION_FAILURE_UPDATE_CONTACT_DETAILS,
    FORM_FIELD_CHANGE,
    CUSTOMER_CHECK_INIT,
    SHOW_UDATE_CONTACT_MODAL,
    RECIEVE_OTC_INIT,
    VALIDATE_OTC_SUCCESS,
    VALIDATE_OTC_ERROR,
    RESET_OTC_STATE,
    RESET_EDIT_CONTACT_STATE,
    OTP_SENT_SUCCESS,
    OTP_SENT_ERROR,
    OTP_SENT_INIT,
    UPDATE_CONTACT_MEDIUM_ERROR,
    UPDATE_CONTACT_MEDIUM_INIT,
    UPDATE_CONTACT_MEDIUM_SUCCESS,
    UPDATE_BIZ_CONTACT_MEDIUM_ERROR,
    UPDATE_BIZ_CONTACT_MEDIUM_INIT,
    UPDATE_BIZ_CONTACT_MEDIUM_SUCCESS,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_MOBILE_SUCCESS,
    CUSTOMER_DATA_FETCH_SUCCESS,
    UNAUTH_UPDATE_ADD_NUMBER_SKIP,
    NO_UPDATE_EXIS_NUM,
    BUSINESS_REGISTRATION_SET_ERROR,
    YOUR_CONTACT_DETAILS_RESET_ERROR,
    ABN_ACN_VALIDATION_REQUEST_INIT,
    ABN_ACN_VALIDATION_REQUEST_SUCCESS,
    ABN_ACN_VALIDATION_REQUEST_ERROR,
    ABN_ACN_VALIDATION_RESET,
    REMOVE_ABN_ACN_VALIDATION_MESAGE,
    ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED,
    RESET_BUSINESS_ADDRESS_META,
    SET_SELECTED_BUSINESS,
    SET_SELECTED_BUSINESS_ERROR,
    SET_MAXED_OUT_BUSINESS_NUMBER,
    SET_BUSINESS_CONTACT_EDITING_FIELD,
    SET_BUSINESS_CONTACT_EDITING_STEP,
    SET_BUSINESS_CONTACT_EDITING_VALUE,
    VERIFY_BUSINESS_ADDRESS_INIT,
    VERIFY_BUSINESS_ADDRESS_ERROR,
    VERIFY_BUSINESS_ADDRESS_SUCCESS,
    CREATE_BUSINESS_ACCOUNT_INIT,
    CREATE_BUSINESS_ACCOUNT_ERROR,
    CREATE_BUSINESS_ACCOUNT_SUCCESS,
    SET_UPDATE_BUSINESS_CONTACTS_FORM_ERRORS,
    ADD_CUSTOMER_BUSINESS,
    EDIT_CONTACT_FORM_SUBMITTED,
    PERSONAL_EMAIL_OTC_SENT_INIT,
    PERSONAL_EMAIL_OTC_SENT_SUCCESS,
    PERSONAL_EMAIL_OTC_SENT_ERROR,
    RESET_PERSONAL_EMAIL_OTC,
    VERIFY_PERSONAL_EMAIL_ERROR,
    VERIFY_PERSONAL_EMAIL_INIT,
    VERIFY_PERSONAL_EMAIL_SUCCESS,
    RESET_PERSONAL_EMAIL,
    SET_PHONE_VER_LIMIT_INFO
} from '../actions';
import { notifyAnalytics, requestCodeMeta } from '../../adapters/analytics-utils';
import { isEmpty } from '../../adapters/validations';
import store from '../../store';
import { change, reset, submit, untouch } from 'redux-form';
import CONTACT_PAGE_CONSTANTS from './constants';
import { setFullPageErrorAppFlag, updateCustomerBusinessData } from '../app/actions';
import { focusOnElement } from '../../adapters/utils';

// Reducer
const initialState = {
    loading: {
        appLoading: true,
        isModuleLoaded: false,
        lockAddressField: false,
        verificationInProgress: false,
        customerValidationComplete: false,
        formProcessed: false,
        lockFields: false,
        customerCheckDone: false,
        hasProcessedCustomerCheck: false,
        cartCreated: false,
        customerCheckInProgress: false,
        showUpdateContactModal: false
    },
    // validation
    validations: {
        isOTCVerified: false,
        isVerifyOTCInProgress: false
    },
    // mobile updation
    updateMobileStatus: {
        isMobileUpdated: false,
        isMobileUpdateInProgress: false,
        didUserUpdateEmail: false,
        didUserUpdateMobile: false,
        didUserUpdateAddress: false,
        unAuthUpdateAddNumberSkip: false,
        updateCounter: 0
    },
    businessReigstrationMeta: {
        showVerifyFormErrorMessage: false,
        isValidationInProgress: false,
        isABNOrACNValid: undefined,
        abnOrAcnError: undefined,
        businessDetails: null,
        editingField: '',
        editingValue: '',
        editingStep: 1
    },
    selectedBusiness: {
        selectedBusiness: undefined,
        error: undefined,
        hasMaxedOutServices: 0
    },
    locale: {},
    errors: {
        hasError: false,
        errorCode: null,
        hasServerError: false,
        hasErrorOccurred: false,
        errorText: undefined,
        errorMessage: undefined
    },
    updateBusinessContactsMeta: {},
    addressVerificationMeta: {},
    submitSMBAccountMeta: {},
    personalEmailVerificationMeta: {
        errors: {},
        sendingOTC: false,
        lastVerifiedEmail: null,
        isEmailVerified: false,
        isOTCSent: false,
        otcCounter: 0
    },
    updateBusinessContactsFormErrors: undefined,
    address: { isVerificationRequired: false, addressVerificationOptions: [], verificationData: {} },
    editContact: { hasOtpSent: false, hasOtpError: false, isProcessingOtp: false },
    phoneNumVerificationLimitInfo: { limitReached: false, errorDetails: {} }
};

function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case YOUR_CONTACT_DETAILS_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true }
            };
        case YOUR_CONTACT_DETAILS_INIT_ERROR:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: false }
            };
        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, lockFields: true, lockAddressField: true, formProcessed: true }
            };
        case CUSTOMER_CHECK_INIT: {
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false, customerCheckDone: false, customerCheckInProgress: true }
            };
        }
        case CUSTOMER_CHECK_DONE: {
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, customerCheckDone: true, customerCheckInProgress: false }
            };
        }

        case CUSTOMER_CHECK_ERROR: {
            const hasServerError = action.errorCode === SERVER_ERROR || action.errorCode === BAD_REQUEST || action.errorCode === FATAL_ERROR;
            const hasErrorOccurred = action.errorCode === 'SFCC-FATAL-422' || action.errorCode.indexOf('SFCC-FATAL-') !== -1 || action.errorCode === 'SFADUP-FATAL-422';
            const { businessReigstrationMeta: { businessDetails = {} } = {} } = state;
            const businessNames = businessDetails ? businessDetails.businessNames : [];
            let hasError = true;
            let errorText = 'Something went wrong';
            let errorMessage = "We're unable to process the request at this time. Please try again later.";

            if (typeof action.errorCode === 'string') {
                if (action.errorCode === 'SFADUP-FATAL-422') {
                    errorText = 'Unable to process request';
                    errorMessage = "We're unable to process the request at this time. Need help? Call us on 13 20 00.";
                } else if (action.errorCode === 'SVCT-EX-SMB') {
                    const {
                        businessNameSelect: {
                            errorMessages: { useDiffAbnAcn, selectAnotherBusinessName }
                        }
                    } = CONTACT_PAGE_CONSTANTS;
                    errorText = '';
                    errorMessage = businessNames && businessNames.length === 1 ? useDiffAbnAcn : selectAnotherBusinessName;
                    hasError = false;
                    focusOnElement('#invalidAbnError', 200);
                }
            }

            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasError,
                    errorCode: action.errorCode,
                    hasServerError,
                    hasErrorOccurred,
                    errorText,
                    errorMessage
                },
                loading: {
                    ...state.loading,
                    formProcessed: true,
                    customerCheckDone: true,
                    customerCheckInProgress: false,
                    lockFields: hasServerError || hasErrorOccurred
                },
                businessReigstrationMeta:
                    action.errorCode === 'SVCT-EX-SMB'
                        ? {
                            ...state.businessReigstrationMeta,
                            isABNOrACNValid: !(businessNames && businessNames.length === 1),
                            abnOrAcnError: errorMessage
                        }
                        : state.businessReigstrationMeta
            };
        }
        case CREATE_SHOPPING_CART_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hasProcessedCustomerCheck: false,
                    lockFields: action.errorCode === SERVER_ERROR || action.errorCode === BAD_REQUEST || action.errorCode === MULTIPLE_CUSTOMERS_FOUND
                },
                appData: { ...state.appData, uiState: { appLock: true } },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: true }
            };

        case EXISTING_CUSTOMER_FETCH_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: true }
            };

        case EXISTING_CUSTOMER_FETCH_ERROR:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: true }
            };
        case CREATE_SHOPPING_CART_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: true, cartCreated: true }
            };

        case RESET_CONTACT_DETAILS_FORM:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false, hasProcessedCustomerCheck: false, customerCheckDone: false }
            };

        case RESET_EXISTING_CUSTOMER_PROCESS:
            return {
                ...state,
                loading: { ...state.loading, hasProcessedCustomerCheck: false, formProcessed: false }
            };

        // Address lookup faliure
        case ADDRESS_LOOKUP_FAILURE:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === SERVER_ERROR }
            };

        case ADDRESS_LOOKUP_SUCCESS:
            return {
                ...state,
                errors: { ...state.errors, hasError: false }
            };

        // creates a customer verification address array
        // & changing the loading state of page.
        case ADDRESS_VERIFICATION_REQUIRED: {
            return {
                ...state,
                address: { ...state.address, isVerificationRequired: true },
                loading: { ...state.loading, verificationInProgress: false }
            };
        }

        case ADDRESS_VERIFICATION_SUCCESS_UPDATE_CONTACT_DETAILS:
        case ADDRESS_VERIFICATION_SUCCESS:
            const verificationData = action.data[0] || {};

            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: false },
                // reset the address verification options box because the main address might have changed.
                address: { ...state.address, isVerificationRequired: false, addressVerificationOptions: [], verificationData }
            };

        case ADDRESS_VERIFICATION_FAILURE_UPDATE_CONTACT_DETAILS:
        case ADDRESS_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: false },
                address: { ...state.address, isVerificationRequired: true },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === SERVER_ERROR },
                hasErrorOccurred: true
            };
        // turns the loading state of verification on
        case ADDRESS_VERIFICATION_INIT_UPDATE_CONTACT_DETAILS:
        case ADDRESS_VERIFICATION_INIT:
            return {
                ...state,
                loading: { ...state.loading, verificationInProgress: true }
            };

        // Unlock address field and confirm button to retry finding address
        case FORM_FIELD_CHANGE: {
            const { meta: { form, field } = {}, payload } = action;
            if (form === 'yourContactDetailsForm' && field === 'verifyAddress' && payload === 'addressNotFound') {
                return Object.assign({}, state, {
                    loading: { ...state.loading, lockAddressField: false }
                });
            }
            return state;
        }

        case RECIEVE_OTC_INIT:
            return {
                ...state,
                validations: { ...state.validations, isVerifyOTCInProgress: true, otcVerifyMediumType: action.contactMediumType }
            };
        case VALIDATE_OTC_ERROR: {
            let newError = {};
            if (action.errorCode === errorCodeMeta.UNKNOWN_ERROR) {
                newError = {
                    updateContactErrorOccured: true,
                    errorTextLink: 'Try again',
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                };
            }
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, ...newError },
                validations: { ...state.validations, isOTCVerified: false, otcCounter: (state.validations.otcCounter || 0) + 1, isVerifyOTCInProgress: false }
            };
        }
        case VALIDATE_OTC_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true },
                validations: { ...state.validations, isOTCVerified: true, otcCounter: (state.validations.otcCounter || 0) + 1, isVerifyOTCInProgress: false }
            };
        case RESET_OTC_STATE:
            const currentStore = store.getState();
            const {
                app: {
                    appData: {
                        uiState: { loggedIn = false }
                    }
                }
            } = currentStore;

            return {
                ...state,
                errors: { ...state.errors, hasError: null, errorCode: null },
                validations: { ...state.validations, isOTCVerified: action.payload !== undefined ? action.payload : !!loggedIn }
            };
        case OTP_SENT_INIT: {
            return Object.assign({}, state, {
                validations: {
                    ...state.validations,
                    otcVerifyMediumType: action.contactMediumType
                },
                editContact: {
                    ...state.editContact,
                    isProcessingOtp: true,
                    hasOtpSent: false
                }
            });
        }

        case OTP_SENT_SUCCESS: {
            return Object.assign({}, state, {
                editContact: {
                    ...state.editContact,
                    hasOtpSent: true,
                    hasOtpError: false,
                    isProcessingOtp: false
                },
                validations: { ...state.validations, otcCounter: (state.validations.otcCounter || 0) + 1 },
                errors: { ...state.errors, hasError: false, errorCode: null }
            });
        }

        case OTP_SENT_ERROR: {
            let newError = {};
            if (action.errorCode === errorCodeMeta.UNKNOWN_ERROR) {
                newError = {
                    updateContactErrorOccured: true,
                    errorTextLink: 'Try again',
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                };
            }
            return Object.assign({}, state, {
                editContact: {
                    ...state.editContact,
                    hasOtpSent: false,
                    hasOtpError: true,
                    isProcessingOtp: false
                },
                validations: { ...state.validations, otcCounter: (state.validations.otcCounter || 0) + 1 },
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, ...newError }
            });
        }

        case RESET_EDIT_CONTACT_STATE:
            return {
                ...state,
                editContact: {
                    ...state.editContact,
                    hasOtpSent: false,
                    hasOtpError: false,
                    isProcessingOtp: false
                },
                errors: { ...state.errors, hasError: false, errorCode: '', updateContactErrorOccured: false },
                validations: { ...state.validations, isOTCVerified: false }
            };

        case SHOW_UDATE_CONTACT_MODAL:
            return {
                ...state,
                showUpdateContactModal: action.data
            };

        case UPDATE_CONTACT_MEDIUM_INIT:
            return {
                ...state,
                updateMobileStatus: { ...state.updateMobileStatus, isMobileUpdateInProgress: true }
            };

        case UPDATE_CONTACT_MEDIUM_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode },
                updateMobileStatus: { ...state.updateMobileStatus, isMobileUpdated: false, isMobileUpdateInProgress: false }
            };

        case UPDATE_CONTACT_MEDIUM_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true },
                updateMobileStatus: {
                    ...state.updateMobileStatus,
                    isMobileUpdated: true,
                    isMobileUpdateInProgress: false,
                    diffEmailUpdated: action.data.diffEmailUpdated,
                    diffMobileUpdated: action.data.diffMobileUpdated
                }
            };
        case UPDATE_EMAIL_SUCCESS:
            return {
                ...state,
                updateMobileStatus: {
                    ...state.updateMobileStatus,
                    isMobileUpdated: false,
                    didUserUpdateEmail: true,
                    didUserUpdateAddress: false,
                    didUserUpdateMobile: false,
                    updateCounter: state.updateMobileStatus.updateCounter + 1
                }
            };
        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                updateMobileStatus: {
                    ...state.updateMobileStatus,
                    isMobileUpdated: false,
                    didUserUpdateAddress: true,
                    didUserUpdateEmail: false,
                    didUserUpdateMobile: false,
                    updateCounter: state.updateMobileStatus.updateCounter + 1
                }
            };
        case UPDATE_MOBILE_SUCCESS:
            return {
                ...state,
                phoneNumVerificationLimitInfo: { limitReached: false },
                updateMobileStatus: {
                    ...state.updateMobileStatus,
                    isMobileUpdated: false,
                    didUserUpdateMobile: true,
                    didUserUpdateEmail: false,
                    didUserUpdateAddress: false,
                    updateCounter: state.updateMobileStatus.updateCounter + 1
                }
            };
        case SET_PHONE_VER_LIMIT_INFO:
            return {
                ...state,
                phoneNumVerificationLimitInfo: {
                    limitReached: action.limitReached,
                    errorDetails: action.errorDetails
                }
            };
        case NO_UPDATE_EXIS_NUM:
            return {
                ...state,
                updateMobileStatus: {
                    ...state.updateMobileStatus,
                    isMobileUpdated: false,
                    didUserUpdateMobile: false,
                    didUserUpdateEmail: false,
                    didUserUpdateAddress: false,
                    unAuthUpdateAddNumberSkip: true
                }
            };
        case CUSTOMER_DATA_FETCH_SUCCESS:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    isOTCVerified: true
                }
            };
        case UNAUTH_UPDATE_ADD_NUMBER_SKIP:
            return {
                ...state,
                updateMobileStatus: { ...state.updateMobileStatus, unAuthUpdateAddNumberSkip: true }
            };
        case YOUR_CONTACT_DETAILS_RESET_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: false, errorCode: null }
            };
        case BUSINESS_REGISTRATION_SET_ERROR:
            return {
                ...state,
                businessReigstrationMeta: { ...state.businessReigstrationMeta, showVerifyFormErrorMessage: true }
            };

        case ABN_ACN_VALIDATION_REQUEST_INIT:
            return {
                ...state,
                businessReigstrationMeta: {
                    ...state.businessReigstrationMeta,
                    showVerifyFormErrorMessage: false,
                    isValidationInProgress: true
                }
            };

        case ABN_ACN_VALIDATION_REQUEST_SUCCESS:
            let businessNamesLength = 0;
            if (action.payload && action.payload.businessNames && action.payload.businessNames.length) {
                businessNamesLength = action.payload.businessNames.length;
            }
            return {
                ...state,
                businessReigstrationMeta: {
                    ...state.businessReigstrationMeta,
                    isValidationInProgress: false,
                    isABNOrACNValid: businessNamesLength ? true : undefined,
                    abnOrAcnError: undefined,
                    businessDetails: businessNamesLength ? action.payload : null
                }
            };

        case ABN_ACN_VALIDATION_REQUEST_ERROR:
            return {
                ...state,
                businessReigstrationMeta: {
                    ...state.businessReigstrationMeta,
                    isValidationInProgress: false,
                    isABNOrACNValid: false,
                    abnOrAcnError: action.payload,
                    businessDetails: null
                },
                errors:
                    action.payload && action.payload.indexOf('SMB-FATAL-') !== -1
                        ? {
                            ...state.errors,
                            hasErrorOccurred: true,
                            errorText: 'Something went wrong',
                            errorMessage: "We can't verify your ABN or ACN at this time. Please try again later."
                        }
                        : state.errors
            };

        case ABN_ACN_VALIDATION_RESET:
            return {
                ...state,
                businessReigstrationMeta: {
                    ...state.businessReigstrationMeta,
                    isValidationInProgress: false,
                    isABNOrACNValid: undefined,
                    abnOrAcnError: undefined,
                    businessDetails: null,
                    showVerifyFormErrorMessage: false
                },
                updateBusinessContactsMeta: {}
            };

        case REMOVE_ABN_ACN_VALIDATION_MESAGE:
            return {
                ...state,
                businessReigstrationMeta: {
                    ...state.businessReigstrationMeta,
                    isABNOrACNValid: undefined
                }
            };

        case ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    hasErrorOccurred: true,
                    errorText: 'Unable to process request',
                    errorMessage: "You've run out of attempts to correctly enter your ABN or ACN. Please try again later."
                }
            };

        case SET_SELECTED_BUSINESS:
            return {
                ...state,
                selectedBusiness: { ...state.selectedBusiness, selectedBusiness: action.payload }
            };

        case SET_SELECTED_BUSINESS_ERROR:
            return {
                ...state,
                selectedBusiness: { ...state.selectedBusiness, error: action.payload }
            };

        case SET_MAXED_OUT_BUSINESS_NUMBER:
            return {
                ...state,
                selectedBusiness: { ...state.selectedBusiness, hasMaxedOutServices: action.payload }
            };

        case SET_BUSINESS_CONTACT_EDITING_FIELD:
            return {
                ...state,
                businessReigstrationMeta: { ...state.businessReigstrationMeta, editingField: action.payload }
            };

        case SET_BUSINESS_CONTACT_EDITING_STEP:
            return {
                ...state,
                businessReigstrationMeta: { ...state.businessReigstrationMeta, editingStep: action.payload }
            };

        case SET_BUSINESS_CONTACT_EDITING_VALUE:
            return {
                ...state,
                businessReigstrationMeta: { ...state.businessReigstrationMeta, editingValue: action.payload }
            };

        case UPDATE_BIZ_CONTACT_MEDIUM_INIT:
            return {
                ...state,
                updateBusinessContactsMeta: { ...state.updateBusinessContactsMeta, updating: true }
            };

        case UPDATE_BIZ_CONTACT_MEDIUM_ERROR:
            return {
                ...state,
                updateBusinessContactsMeta: { ...state.updateBusinessContactsMeta, updating: false, error: true },
                errors: {
                    ...state.errors,
                    hasErrorOccurred: action.errorCode === errorCodeMeta.UPDATE_BIZ_CONTACT_MEDIUM_ERROR,
                    errorTextLink: 'Try again',
                    updateContactErrorOccured: true,
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                }
            };

        case UPDATE_BIZ_CONTACT_MEDIUM_SUCCESS:
            return {
                ...state,
                updateBusinessContactsMeta: {
                    updated: true,
                    counter: (state.updateBusinessContactsMeta.counter || 0) + 1,
                    isBusinessContact: action.payload.data.businessContacts,
                    lastUpdatedField: action.payload.data.contactMediumType
                }
            };

        case RESET_BUSINESS_ADDRESS_META:
            return {
                ...state,
                addressVerificationMeta: {}
            };
        case VERIFY_BUSINESS_ADDRESS_INIT:
            return {
                ...state,
                addressVerificationMeta: { verifying: true }
            };

        case VERIFY_BUSINESS_ADDRESS_ERROR: {
            let newError = {};
            if (action.errorCode !== errorCodeMeta.NONPHYSICAL_ADDRESS_ERROR) {
                newError = {
                    updateContactErrorOccured: true,
                    errorTextLink: 'Try again',
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                };
            }
            return {
                ...state,
                addressVerificationMeta: { error: true, errorCode: action.errorCode },
                errors: {
                    ...state.errors,
                    ...newError
                }
            };
        }
        case VERIFY_BUSINESS_ADDRESS_SUCCESS:
            return {
                ...state,
                addressVerificationMeta: { verifying: false }
            };
        case CREATE_BUSINESS_ACCOUNT_INIT:
            return {
                ...state,
                submitSMBAccountMeta: { submitting: true }
            };

        case CREATE_BUSINESS_ACCOUNT_ERROR:
            let errorText = 'Something went wrong';
            let errorMessage = "We're unable to process the request at this time. Please try again later.";

            if (typeof action.errorCode === 'string') {
                if (action.errorCode === 'SFCC-FATAL-422' || action.errorCode === 'SFADUP-FATAL-422') {
                    errorText = 'Unable to process request';
                    errorMessage = "We're unable to process the request at this time. Need help? Call us on 13 20 00.";
                }
            }

            return {
                ...state,
                submitSMBAccountMeta: { error: true, errorCode: action.errorCode },
                errors: {
                    ...state.errors,
                    hasErrorOccurred: true,
                    errorText,
                    errorMessage
                }
            };

        case CREATE_BUSINESS_ACCOUNT_SUCCESS:
            return {
                ...state,
                submitSMBAccountMeta: { submitted: true }
            };

        case SET_UPDATE_BUSINESS_CONTACTS_FORM_ERRORS:
            return {
                ...state,
                updateBusinessContactsFormErrors: action.errors
            };

        case EDIT_CONTACT_FORM_SUBMITTED:
            return {
                ...state,
                editContactDetailsFormSubmitted: true
            };

        case RESET_PERSONAL_EMAIL_OTC: {
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    sendingOTC: false,
                    isOTCSent: false,
                    isOTCResent: false,
                    resendingOTC: false,
                    verifyingOTC: false,
                    errors: {}
                }
            });
        }

        case PERSONAL_EMAIL_OTC_SENT_INIT: {
            let resendOTCMeta = {};
            if (action.isResend) {
                resendOTCMeta = {
                    ...state.personalEmailVerificationMeta,
                    resendingOTC: true
                };
            }
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    isOTCSent: false,
                    sendingOTC: true,
                    vidEmailConflictError: false,
                    ...resendOTCMeta,
                    otcCounter: (state.personalEmailVerificationMeta.otcCounter || 0) + 1,
                    errors: {}
                }
            });
        }

        case PERSONAL_EMAIL_OTC_SENT_SUCCESS: {
            let resendOTCMeta = {};
            if (action.isResend) {
                resendOTCMeta = {
                    ...state.personalEmailVerificationMeta,
                    resendingOTC: false,
                    isOTCResent: true
                };
            }
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    sendingOTC: false,
                    isOTCSent: true,
                    ...resendOTCMeta,
                    errors: {}
                }
            });
        }

        case PERSONAL_EMAIL_OTC_SENT_ERROR: {
            let resendOTCMeta = {};
            let errors = {};
            if (action.isResend) {
                resendOTCMeta = {
                    ...state.personalEmailVerificationMeta,
                    isOTCResent: false,
                    resendingOTC: false
                };
            }
            if (action.errorCode === errorCodeMeta.MAX_ATTEMPTS_SEND_OTC) {
                errors = {
                    hasErrorOccurred: true,
                    errorText: 'Unable to process request.',
                    errorMessage: "You've reached the maximum attempts for requesting your one-time code. You'll need to wait at least 20 minutes before trying again."
                };
                if (action.isResend) {
                    errors.errorText = "You've reached the maximum number of code resends";
                    errors.errorMessage = "For your security, we can't send any more codes right now. Please try again in 20 minutes.";
                }
            } else {
                errors = {
                    hasErrorOccurred: true,
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                };
            }
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    sendingOTC: false,
                    isOTCSent: false,
                    ...resendOTCMeta,
                    errors: {
                        hasError: true,
                        errorCode: action.errorCode
                    }
                },
                errors: {
                    ...state.errors,
                    ...errors
                }
            });
        }

        case VERIFY_PERSONAL_EMAIL_INIT:
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    verifyingOTC: true,
                    otcCounter: (state.personalEmailVerificationMeta.otcCounter || 0) + 1,
                    errors: {}
                }
            });

        case VERIFY_PERSONAL_EMAIL_ERROR: {
            let errors = {};
            if (action.errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC) {
                errors = {
                    hasErrorOccurred: true,
                    errorText: "You've reached the maximum number of attempts",
                    errorMessage: "You've run out of attempts to correctly enter your one-time code. Please try again in 20 minutes."
                };
            } else if (action.errorCode === errorCodeMeta.SERVER_ERROR || action.errorCode === errorCodeMeta.VALIDATE_OTC_ERROR) {
                errors = {
                    hasErrorOccurred: true,
                    errorText: 'Something went wrong',
                    errorMessage: "We're unable to process the request at this time. Please try again later."
                };
            }
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    verifyingOTC: false,
                    vidConflictEmail: action.emailAddress,
                    vidEmailConflictError: action.errorCode === errorCodeMeta.VID_EMAIL_CONFLICT,
                    errors: {
                        hasError: true,
                        errorCode: action.errorCode
                    }
                },
                errors: {
                    ...state.errors,
                    ...errors
                }
            });
        }

        case VERIFY_PERSONAL_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    verifyingOTC: false,
                    isEmailVerified: true,
                    lastVerifiedEmail: action.emailAddress
                }
            });

        case RESET_PERSONAL_EMAIL:
            return {
                ...state,
                personalEmailVerificationMeta: {
                    ...state.personalEmailVerificationMeta,
                    lastVerifiedEmail: null,
                    vidConflictEmail: null
                }
            };

        default:
            return state;
    }
}

export default reducer;

// Action Creators
/**
 * Fetches AEM authorable content
 *
 * @param {any} [dataFactory=client.fetchLocale]
 * @returns
 */
export const moduleInit = () => {
    return (dispatch) => moduleInitialised(dispatch);
};

// DISPATCH OBJECTS

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = (dispatch) => {
    dispatch({ type: YOUR_CONTACT_DETAILS_INIT_DONE });
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: YOUR_CONTACT_DETAILS_INIT_ERROR, data: httpStatus };
}

/**
 * Reset contact details form
 *
 * @export
 * @returns
 */
export function resetForm() {
    return { type: RESET_CONTACT_DETAILS_FORM };
}

export const checkExistingCustomer = (email, msisdn, fetcher = client.fetchTelstraIdDetails) => {
    const params = { email, msisdn };
    const options = { ...params };
    return (dispatch) => {
        return fetcher(dispatch, receiveExistingCustomer, receiveExistingCustomerError, options);
    };
};

export const receiveExistingCustomer = (packageData) => {
    const { hasError, errorCode, data } = processExistingCustomerData(packageData.data);

    if (hasError) {
        return receiveExistingCustomerError(errorCode);
    }

    return {
        type: EXISTING_CUSTOMER_FETCH_SUCCESS,
        data
    };
};

export const receiveExistingCustomerError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 404:
            errorCode = EXISTING_CUSTOMER_NOT_AVAILABLE;
            break;
        case 500:
            errorCode = SERVER_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return { type: EXISTING_CUSTOMER_FETCH_ERROR, errorCode };
};

const processExistingCustomerData = (packageData) => {
    let errorCode = '';
    if (packageData.telstraIdResult !== 'TID_EXISTS') {
        errorCode = EXISTING_CUSTOMER_NOT_AVAILABLE;
    }

    return { hasError: Boolean(errorCode.length), errorCode, packageData };
};

export const resetExistingCustomerCheckProcess = () => ({ type: RESET_EXISTING_CUSTOMER_PROCESS });

/**
 * Fetches address object from telstra address lookup API
 * Note: this is the one off case where the promise is returned
 * to the calling component.
 * @param {string} [addressInput='']
 * @param {any} [dataFactory=client.fetchAddressDetails]
 * @returns promise which will resolve in address data object
 */
export const getAddress = (addressInput = '', dataFactory = client.fetchAddressDetails) => {
    return (dispatch) => {
        return dataFactory(dispatch, addressLookupSuccess, addressLookupFailure, { addressInput });
    };
};

/**
 * Verifies the address selected
 * Return address array from service if further verification
 *
 * @param {string} [addressInput='']
 * @param {any} [dataFactory=client.fetchAddressVerification]
 * @returns promise which will resolve in address data object
 */
export const verifyAddress = (addressParams, dataFactory = client.fetchAddressVerification) => {
    return (dispatch) => {
        dispatch({ type: ADDRESS_VERIFICATION_INIT });
        return dataFactory(dispatch, addressVerificationSuccess, addressVerificationFailure, { data: { searchData: addressParams } });
    };
};

export const verifyAddressUpdateContactDetails = (addressParams, dataFactory = client.fetchAddressVerification) => {
    return (dispatch) => {
        dispatch({ type: ADDRESS_VERIFICATION_INIT_UPDATE_CONTACT_DETAILS });
        return dataFactory(dispatch, addressVerificationSuccessUpdateContactDetails, addressVerificationFailureUpdateContactDetails, { data: { searchData: addressParams } });
    };
};

export const addressLookupResponse = (data) => {
    let errorCode = '';
    const status = data.status;
    if (typeof status === 'string' || status instanceof String) {
        if (status.includes('FATAL')) {
            errorCode = SERVER_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode };
};

/**
 * Exports object for address lookup success
 *
 * @export
 */
export const addressLookupSuccess = (data) => {
    const { hasError, errorCode } = addressLookupResponse(data);
    if (hasError) {
        return addressLookupFailure(errorCode);
    }
    return { type: ADDRESS_LOOKUP_SUCCESS };
};

/**
 * Exports object for address lookup failure
 *
 * @export
 */
export const addressLookupFailure = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case SERVER_ERROR:
            errorCode = SERVER_ERROR;
            break;
        // case 4000:
        //     errorCode = NETWORK_FAILURE;
        //     break;
        default:
            errorCode = httpStatus;
            break;
    }

    notifyAnalytics(requestCodeMeta.addressFailure, errorCode);

    return { type: ADDRESS_LOOKUP_FAILURE, errorCode };
};

/**
 * Process the package received from service call
 * IF
 *  One address is received from the server and the confidence level
 *  is above 90% the address verification will succeed and the user will be
 *  allowed to move on the page further
 * ELSE
 *  The address verification data will be dispatch for the verify address
 *  select box
 * @param {object} packageData
 * @returns
 */
export const processAddressVerificationPackage = (verification) => {
    let errorCode = '';
    const status = verification.status;
    const isVerificationRequired = isEmpty(verification.data);
    if (typeof status === 'string' || status instanceof String) {
        if (status.includes('FATAL')) {
            errorCode = SERVER_ERROR;
        } else if (status.includes('EX-422')) {
            errorCode = NONPHYSICAL_ADDRESS_ERROR;
        }
    }
    return { isVerificationRequired, data: verification.data, hasError: Boolean(errorCode.length), errorCode };
};

/**
 * Exports object for address verification success or failure
 *
 * @export
 */
export const addressVerificationSuccess = (verificationDetails) => {
    const { data = {}, hasError, errorCode } = processAddressVerificationPackage(verificationDetails);
    const dataProcessed = [];
    if (hasError) {
        return addressVerificationFailure(errorCode);
    }
    dataProcessed.push(data);
    return { type: ADDRESS_VERIFICATION_SUCCESS, data: dataProcessed, serviceAuthToken: data.token };
};

/**
 * Exports object for address verification failure
 *
 * @export
 */
export const addressVerificationFailure = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case SERVER_ERROR:
            errorCode = SERVER_ERROR;
            break;
        case 4000:
            errorCode = NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    notifyAnalytics(requestCodeMeta.addressFailure, errorCode);
    return { type: ADDRESS_VERIFICATION_FAILURE, errorCode };
};

/**
 * complete address verification required successfully
 *
 * @export
 * @returns
 */
export function addressVerifiedStatus(verificationStatus) {
    return { type: ADDRESS_VERIFICATION_REQUIRED_STATUS, verificationStatus };
}

export const addressVerificationSuccessUpdateContactDetails = (verificationDetails) => {
    const { data = {}, hasError, errorCode } = processAddressVerificationPackage(verificationDetails);
    const dataProcessed = [];
    if (hasError) {
        return addressVerificationFailureUpdateContactDetails(errorCode);
    }
    dataProcessed.push(data);
    return { type: ADDRESS_VERIFICATION_SUCCESS_UPDATE_CONTACT_DETAILS, data: dataProcessed, serviceAuthToken: data.token };
};

export const addressVerificationFailureUpdateContactDetails = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case SERVER_ERROR:
            errorCode = SERVER_ERROR;
            break;
        case 4000:
            errorCode = NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    notifyAnalytics(requestCodeMeta.addressFailure, errorCode);
    return { type: ADDRESS_VERIFICATION_FAILURE_UPDATE_CONTACT_DETAILS, errorCode };
};

export const createShoppingCart = (customerAccountUUID, fetcher = client.createShoppingCartData) => {
    const params = { data: { customerAccountUUID: `${customerAccountUUID}` } };
    const options = { ...params };
    return (dispatch) => {
        return fetcher(dispatch, shoppingCartCreationSuccess, shoppingCartCreationError, options);
    };
};

export const shoppingCartCreationSuccess = (cartData) => {
    const { hasError, errorCode, data } = processShoppingCartData(cartData.data);
    if (hasError) {
        return shoppingCartCreationError(errorCode);
    }
    return {
        type: CREATE_SHOPPING_CART_SUCCESS,
        data
    };
};

const processShoppingCartData = (cartData) => {
    let errorCode = '';
    if (!cartData || (cartData && !cartData.id)) {
        errorCode = SHOPPING_CART_CREATION_ERROR;
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: cartData };
};

export const shoppingCartCreationError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = SERVER_ERROR;
            break;
        case 400:
            errorCode = BAD_REQUEST;
            break;
        case 404:
            errorCode = SERVER_ERROR;
            break;
        case 405:
            errorCode = SERVER_ERROR;
            break;
        case 4000:
            errorCode = NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }

    return { type: CREATE_SHOPPING_CART_ERROR, errorCode };
};

export function resetEditContactModal() {
    return { type: RESET_EDIT_CONTACT_STATE };
}

export function triggerUpdateContactModal(data) {
    return { type: SHOW_UDATE_CONTACT_MODAL, data };
}

/**
 * Validates otc
 *
 * @param {any} document
 * @param {any} [dataFactory=client.verifyOneTimeCode]
 * @returns
 */
export const validateOTC = (otcInput, dataFactory = client.verifyOneTimeCode) => {
    return (dispatch) => {
        const { contactMediumType } = otcInput;
        delete otcInput.contactMediumType;
        dispatch({ type: RECIEVE_OTC_INIT, contactMediumType });
        dataFactory(dispatch, validateOTCSuccess, validateOTCError, otcInput);
    };
};

/**
 * Processes the response from service call which check the
 * validity of the otc
 *  dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const validateOTCSuccess = (packageDetails) => {
    const { hasError, errorCode, data } = processOTCResponse(packageDetails);

    if (hasError) {
        return validateOTCError(errorCode, data);
    }
    return {
        type: VALIDATE_OTC_SUCCESS,
        data
    };
};

/**
 * process response from service call
 * for otc validation
 *
 * @param {any} packageData
 * @returns
 */
const processOTCResponse = (packageData) => {
    let errorCode = '';
    const otcVerifyError = packageData.status;
    if (typeof otcVerifyError === 'string' && otcVerifyError.toLowerCase() !== 'success') {
        if (otcVerifyError === 'OTCV-EX-4222002') {
            errorCode = errorCodeMeta.INVALID_OTC;
        } else if (otcVerifyError === 'OTCV-EX-4222003') {
            errorCode = errorCodeMeta.EXPIRED_OTC;
        } else if (otcVerifyError === 'OTCV-EX-4222004') {
            errorCode = errorCodeMeta.MAX_ATTEMPTS_OTC;
        } else if (otcVerifyError === 'OTCV-FATAL-500') {
            errorCode = errorCodeMeta.VALIDATE_OTC_ERROR;
        } else if (otcVerifyError.startsWith('CM-EX-DUP')) {
            errorCode = errorCodeMeta.VID_EMAIL_CONFLICT;
        } else {
            errorCode = errorCodeMeta.VALIDATE_OTC_ERROR;
        }
    }

    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

/**
 * Returns dispatch object for failed porting validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const validateOTCError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.VALIDATE_OTC_ERROR;
            break;
        case 404:
        case 4000:
            errorCode = errorCodeMeta.UNKNOWN_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    focusOnElement('#otcInput', 200);
    return { type: VALIDATE_OTC_ERROR, errorCode };
};

export function resetOTC(payload) {
    return { type: RESET_OTC_STATE, payload };
}

// EDIT Contact --> Send code
export const fetchOneTimeCode = (otpParams, dataFactory = client.sendOneTimeCode) => {
    return (dispatch) => {
        dispatch({ type: OTP_SENT_INIT, contactMediumType: otpParams.contactMediumType });
        dataFactory(dispatch, retrieveOtpSuccess, retrieveOtpError, otpParams);
    };
};

/*
 * for customer validation
 * @param {any} packageData
 * @returns
 */
const processsRetrieveOtpResponse = (packageData) => {
    let errorCode = '';
    const otpStatus = packageData.status;
    if (typeof otpStatus === 'string' && otpStatus.toLowerCase() !== 'success') {
        if (otpStatus === 'OTCG-EX-4222005' || otpStatus === 'OTCG-FATAL-4222005') {
            errorCode = errorCodeMeta.MAX_ATTEMPTS_SEND_OTC;
        } else if (otpStatus.startsWith('OTCG-FATAL-5') || otpStatus.startsWith('OTCG-FATAL-4') || otpStatus === '500' || otpStatus === '404') {
            errorCode = errorCodeMeta.SERVER_ERROR;
        } else {
            errorCode = errorCodeMeta.SEND_OTC_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

export const retrieveOtpSuccess = (responseData) => {
    const { hasError, errorCode, data } = processsRetrieveOtpResponse(responseData);
    if (hasError) {
        return retrieveOtpError(isEmpty(errorCode) ? SERVER_ERROR : errorCode, data);
    }

    return (dispatch) => {
        dispatch({
            type: OTP_SENT_SUCCESS,
            data,
            serviceAuthToken: data.token
        });
    };
};

export const retrieveOtpError = (httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.SEND_OTC_ERROR;
            break;
        case 404:
        case 4000:
            errorCode = errorCodeMeta.UNKNOWN_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: OTP_SENT_ERROR, errorCode, data };
};

/**
 * updates contact medium
 *
 * @param {any} document
 * @param {any} [dataFactory=client.updateContactMedium]
 * @returns
 */
export const updateContactMedium = (contactMediumInput, dataFactory = client.updateContactMedium) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_CONTACT_MEDIUM_INIT });
        dataFactory(dispatch, updateContactMediumSuccess, updateContactMediumError, contactMediumInput);
    };
};

/**
 * Processes the response from service call which update
 * contact media - mobile, email, address
 * dispatches objects accordingly
 *
 * @param {object} packageDetails
 * @returns
 */
export const updateContactMediumSuccess = (packageDetails) => {
    const { hasError, errorCode, data } = processContactMediumResponse(packageDetails);

    if (hasError) {
        return updateContactMediumError(errorCode, data);
    }
    return {
        type: UPDATE_CONTACT_MEDIUM_SUCCESS,
        data
    };
};

/**
 * process response from service call
 * for update mobile
 *
 * @param {any} packageData
 * @returns
 */
const processContactMediumResponse = (packageData) => {
    let errorCode = '';
    const updateContactMediumStatus = packageData.status;
    if (typeof updateContactMediumStatus === 'string' && updateContactMediumStatus.toLowerCase() !== 'success') {
        if (updateContactMediumStatus === 'SFUC-FATAL-400') {
            errorCode = errorCodeMeta.UPDATE_MOBILE_ERROR;
        } else {
            errorCode = errorCodeMeta.UPDATE_CONTACT_MEDIUM_ERROR;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: packageData.data };
};

/**
 * Returns dispatch object for failed porting validation
 *
 * @param {any} httpStatus
 * @returns
 */
export const updateContactMediumError = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.UPDATE_CONTACT_MEDIUM_ERROR;
            break;
        case 4000:
        case 404:
            errorCode = errorCodeMeta.UNKNOWN_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { type: UPDATE_CONTACT_MEDIUM_ERROR, errorCode };
};

export const updateMobileSuccess = (payload = {}) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_MOBILE_SUCCESS, payload });
    };
};

export const unAuthUpdateAddNumberSkip = (payload = {}) => {
    return (dispatch) => {
        dispatch({ type: UNAUTH_UPDATE_ADD_NUMBER_SKIP, payload });
    };
};

export const noUpdateOnExistingNumber = (payload = {}) => {
    return (dispatch) => {
        dispatch({ type: NO_UPDATE_EXIS_NUM, payload });
    };
};

export const resetErrors = () => {
    return { type: YOUR_CONTACT_DETAILS_RESET_ERROR };
};

export const setBRInvalidMessageBox = (value) => {
    return { type: BUSINESS_REGISTRATION_SET_ERROR, value };
};

export const verifyABNOrACN = (data, dataFactory = client.verifyABNOrACN) => {
    return (dispatch) => {
        dispatch({ type: ABN_ACN_VALIDATION_REQUEST_INIT });
        dataFactory(dispatch, verifyABNOrACNSuccess, verifyABNOrACNError, data);
    };
};

export const verifyABNOrACNSuccess = (data) => {
    if (!data.status || (typeof data.status === 'string' && data.status.toLowerCase() !== 'success')) {
        return verifyABNOrACNError(data);
    }
    return (dispatch) => {
        if (data.data.businessNames.length === 1) {
            dispatch(change('businessRegistrationForm', 'businessName', data.data.businessNames[0]));
            focusOnElement('#verifyDifferentABNButton', 0);
        } else if (data.data.businessNames.length === 0) {
            dispatch(reset('businessRegistrationForm'));
        } else {
            focusOnElement('#verifyDifferentABNButton', 0);
            data.data.businessNames = data.data.businessNames.sort((name1, name2) => (name1 > name2 ? 1 : -1));
        }
        dispatch(untouch('businessRegistrationForm', ...CONTACT_PAGE_CONSTANTS.businessRegistrationFormFields));
        dispatch({
            type: ABN_ACN_VALIDATION_REQUEST_SUCCESS,
            payload: data.data
        });
    };
};

export const verifyABNOrACNError = (data) => {
    const { ABNValidation } = CONTACT_PAGE_CONSTANTS;
    let message = ABNValidation.SMB_EX_NOTFOUND;
    if (data.status === 'SMB-EX-NOTFOUND') {
        message = ABNValidation.SMB_EX_NOTFOUND;
    } else if (data.status === 'SMB-EX-NOTDIR') {
        message = ABNValidation.SMB_EX_NOTDIR;
    } else if (data.status && data.status.indexOf('SMB-FATAL-') !== -1) {
        message = data.status;
    }
    return (dispatch) => {
        dispatch({
            type: ABN_ACN_VALIDATION_REQUEST_ERROR,
            payload: message
        });
    };
};

export const verifyABNOrACNReset = () => {
    store.dispatch(reset('businessRegistrationForm'));
    return (dispatch) => {
        dispatch({ type: ABN_ACN_VALIDATION_RESET });
    };
};

export const removeInvalidABNMessage = () => {
    return { type: REMOVE_ABN_ACN_VALIDATION_MESAGE };
};

export const verifyABNOrACNLimitExceeded = () => {
    return (dispatch) => {
        dispatch({ type: ABN_ACN_VALIDATION_REQUEST_LIMIT_EXCEEDED });
    };
};

export const setSelectedBusiness = (data) => {
    return (dispatch) => {
        dispatch(verifyABNOrACNReset());
        dispatch(change('businessRegistrationForm', 'selectedBusiness', data));
        dispatch({
            type: SET_SELECTED_BUSINESS,
            payload: data
        });
    };
};

export const setSelectedBusinessError = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_BUSINESS_ERROR,
            payload: data
        });
    };
};

export const setHasMaxedOutServices = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_MAXED_OUT_BUSINESS_NUMBER,
            payload: data
        });
    };
};

export const validateBusinessContactForm = (callback) => {
    const {
        form: { businessRegistrationForm: { syncErrors } = {} }
    } = store.getState();
    return (dispatch) => {
        dispatch(submit('businessRegistrationForm'));
        callback && callback({ isValid: !syncErrors });
    };
};

export const setBusinessContactEditingField = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_BUSINESS_CONTACT_EDITING_FIELD,
            payload
        });
    };
};

export const setBusinessContactEditingStep = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_BUSINESS_CONTACT_EDITING_STEP,
            payload
        });
    };
};

export const setBusinessContactEditingValue = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_BUSINESS_CONTACT_EDITING_VALUE,
            payload
        });
    };
};

const processUpdateBizMediumResponse = (response) => {
    let errorCode;
    if (response.status !== 'SUCCESS') {
        if (typeof response.status === 'string' && response.status.indexOf('SFUC-FATAL-') !== -1) {
            errorCode = errorCodeMeta.UPDATE_BIZ_CONTACT_MEDIUM_ERROR;
        } else {
            errorCode = response.status;
        }
    }
    return {
        hasError: !!errorCode,
        errorCode,
        data: response.data
    };
};

const updateBizContactMediumError = (callback, httpStatus) => {
    callback && callback({ success: false });
    return { type: UPDATE_BIZ_CONTACT_MEDIUM_ERROR, errorCode: httpStatus };
};

const updateBizContactMediumSuccess = (payload, callback, response) => {
    const { hasError, errorCode, data } = processUpdateBizMediumResponse(response);

    if (hasError) {
        return updateBizContactMediumError(callback, errorCode);
    }
    callback && callback({ success: true, data });
    return {
        type: UPDATE_BIZ_CONTACT_MEDIUM_SUCCESS,
        data,
        payload
    };
};

export const updateBusinessContactMedium = (contactMediumInput, callback, dataFactory = client.updateContactMedium) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_BIZ_CONTACT_MEDIUM_INIT });
        dataFactory(dispatch, updateBizContactMediumSuccess.bind(null, contactMediumInput, callback), updateBizContactMediumError.bind(null, callback), contactMediumInput);
    };
};

export const bizAddressVerificationFailure = (httpStatus) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case SERVER_ERROR:
            errorCode = SERVER_ERROR;
            break;
        case 4000:
            errorCode = NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return { hasError: true, errorCode };
};

export const verifyBusinessAddress = (addressParams, callback, dataFactory = client.fetchAddressVerification) => {
    return (dispatch) => {
        dispatch({ type: VERIFY_BUSINESS_ADDRESS_INIT });
        return dataFactory(
            dispatch,
            (response) => {
                const { data, errorCode, hasError } = processAddressVerificationPackage(response);
                if (hasError) dispatch({ type: VERIFY_BUSINESS_ADDRESS_ERROR, errorCode });
                else dispatch({ type: VERIFY_BUSINESS_ADDRESS_SUCCESS });
                callback && callback({ hasError, data, errorCode });
                return { type: '' };
            },
            (status) => {
                const { errorCode } = bizAddressVerificationFailure(status);
                callback && callback(errorCode);
                return { type: VERIFY_BUSINESS_ADDRESS_ERROR, errorCode };
            },
            { data: { searchData: addressParams, businessAddress: true } }
        );
    };
};

export const resetVerifyBusinessMeta = () => {
    return {
        type: RESET_BUSINESS_ADDRESS_META
    };
};

const processCreateSMBAccountResponse = (response) => {
    let errorCode;
    if (response.status !== 'SUCCESS') {
        errorCode = response.status;
    }
    return {
        hasError: !!errorCode,
        errorCode,
        data: response.data
    };
};

const createSMBAccountError = (callback, httpStatus) => {
    callback && callback({ success: false });
    return { type: CREATE_BUSINESS_ACCOUNT_ERROR, errorCode: httpStatus };
};

const createSMBAccountSuccess = (payload, businessContactSameAsPersonalContact, isNewAccountCreation, callback, response) => {
    const { hasError, errorCode, data } = processCreateSMBAccountResponse(response);

    if (hasError) {
        return createSMBAccountError(callback, errorCode);
    }
    return (dispatch) => {
        callback && callback({ success: true, data });
        dispatch({
            type: CREATE_BUSINESS_ACCOUNT_SUCCESS,
            data,
            payload
        });
        if (isNewAccountCreation) {
            dispatch({
                type: ADD_CUSTOMER_BUSINESS,
                data
            });
            dispatch(setSelectedBusiness(data.accountNumber));
        } else {
            if (businessContactSameAsPersonalContact) {
                // replacing the business contact details with personal contact details
                const { address: { addressline1, addressline2, addressline3, localityName, customerState: state, postcode } = {} } = store.getState().app.appData;

                dispatch(
                    updateCustomerBusinessData(payload.accountNumber, {
                        addressline1,
                        addressline2,
                        addressline3,
                        state,
                        localityName,
                        postcode,
                        businessEmail: payload.businessEmail,
                        businessAddressId: payload.businessAddress
                    })
                );
            }
            // on back navigation; same as personal contact check box will be hidden.
            // so we are showing all the business contact fields to edit.
            dispatch(change('businessRegistrationForm', CONTACT_PAGE_CONSTANTS.businessContactForm.SameAsPersonalContact.name, false));
        }
    };
};

export const createSMBAccount = (payload, businessContactSameAsPersonalContact, isNewAccountCreation, callback, dataFactory = client.createSMBAccount) => {
    return (dispatch) => {
        dispatch({ type: CREATE_BUSINESS_ACCOUNT_INIT });
        dataFactory(dispatch, createSMBAccountSuccess.bind(null, payload, businessContactSameAsPersonalContact, isNewAccountCreation, callback), createSMBAccountError.bind(null, callback), { data: payload });
    };
};

export const setUpdateBusinessContactsFormErrors = (errors) => {
    return {
        type: SET_UPDATE_BUSINESS_CONTACTS_FORM_ERRORS,
        errors
    };
};

export const onEditContactFormSubmitted = () => {
    return (dispatch) => {
        dispatch({ type: EDIT_CONTACT_FORM_SUBMITTED });
    };
};

// ACMA - verify personal email | sendOneTimeCode
export const sendPersonalEmailOneTimeCode = (otpParams, dataFactory = client.sendOneTimeCode) => {
    return (dispatch) => {
        dispatch({ type: PERSONAL_EMAIL_OTC_SENT_INIT });
        dataFactory(dispatch, sendPersonalEmailOTCSuccess.bind(null, false), sendPersonalEmailOTCFailed.bind(null, false), otpParams);
    };
};

export const resendPersonalEmailOneTimeCode = (otpParams, dataFactory = client.sendOneTimeCode) => {
    return (dispatch) => {
        dispatch({ type: PERSONAL_EMAIL_OTC_SENT_INIT, isResend: true });
        dataFactory(dispatch, sendPersonalEmailOTCSuccess.bind(null, true), sendPersonalEmailOTCFailed.bind(null, true), otpParams);
    };
};

const sendPersonalEmailOTCSuccess = (isResend, responseData) => {
    const { hasError, errorCode, data } = processsRetrieveOtpResponse(responseData);
    if (hasError) {
        return sendPersonalEmailOTCFailed(isResend, isEmpty(errorCode) ? SERVER_ERROR : errorCode, data);
    }

    return (dispatch) => {
        dispatch({
            type: PERSONAL_EMAIL_OTC_SENT_SUCCESS,
            data,
            isResend
        });
    };
};

const sendPersonalEmailOTCFailed = (isResend, httpStatus, data) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
        case 404:
            errorCode = errorCodeMeta.SERVER_ERROR;
            break;
        case 4000:
            errorCode = errorCodeMeta.NETWORK_FAILURE;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return (dispatch) => {
        dispatch(setFullPageErrorAppFlag());
        dispatch({ type: PERSONAL_EMAIL_OTC_SENT_ERROR, errorCode, data, isResend });
    };
};

export const resetPersonalEmailValidation = () => {
    return {
        type: RESET_PERSONAL_EMAIL_OTC
    };
};

export const resetPersonalEmail = () => ({
    type: RESET_PERSONAL_EMAIL
});

// validate ACMA personal email otc
export const validatePersonalEmailOTC = (otcInput, emailAddress, dataFactory = client.verifyOneTimeCode) => {
    return (dispatch) => {
        dispatch({ type: VERIFY_PERSONAL_EMAIL_INIT });
        dataFactory(dispatch, validatePersonalEmailOTCSuccess.bind(null, emailAddress), validatePersonalEmailOTCError, otcInput);
    };
};

const validatePersonalEmailOTCSuccess = (emailAddress, packageDetails) => {
    const { hasError, errorCode, data } = processOTCResponse(packageDetails);

    if (hasError) {
        return validatePersonalEmailOTCError(isEmpty(errorCode) ? SERVER_ERROR : errorCode, data, emailAddress);
    }
    focusOnElement(`#${CONTACT_PAGE_CONSTANTS.EmailAddress.sendCodeButtonId}`, null, true);
    return {
        type: VERIFY_PERSONAL_EMAIL_SUCCESS,
        emailAddress,
        data
    };
};

const validatePersonalEmailOTCError = (httpStatus, data, emailAddress) => {
    let errorCode = httpStatus;
    switch (httpStatus) {
        case 500:
            errorCode = errorCodeMeta.VALIDATE_OTC_ERROR;
            break;
        case 404:
        case 4000:
            errorCode = errorCodeMeta.UNKNOWN_ERROR;
            break;
        default:
            errorCode = httpStatus;
            break;
    }
    return (dispatch) => {
        if (errorCode === errorCodeMeta.MAX_ATTEMPTS_OTC || errorCode === errorCodeMeta.VALIDATE_OTC_ERROR || errorCode === errorCodeMeta.SERVER_ERROR) {
            dispatch(setFullPageErrorAppFlag());
        }

        dispatch({ type: VERIFY_PERSONAL_EMAIL_ERROR, errorCode, data, emailAddress });
    };
};
// END of ACMA personal email verification

export const setPhoneNumVerificationLimitInfo = (limitReached, errorDetails) => {
    return {
        type: SET_PHONE_VER_LIMIT_INFO,
        limitReached,
        errorDetails
    }
}