import React from 'react';
import { showWebMessenger } from '../../adapters/utils';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';

const MessageUs = (props) => {
    const openChat = () => {
        const { message, errorType } = props;
        showWebMessenger(message, errorType);
        addDataLayerEventInfo('liveChatStarted', 'liveChat', '', message);
    };

const handleEnterClick=(e)=>{
    if (e.keyCode === 13) {
        e.preventDefault();
        document.querySelector('#messageUsLink') && document.querySelector('#messageUsLink').click();
    }
}

    return (
        <a className="button-as-link"  id="messageUsLink" role="button" tabIndex="0" onClick={() => openChat()} onKeyDown={handleEnterClick}>
            {props.text || 'Message us'}
        </a>
    );
};

export default MessageUs;
