import store, { persistor } from '../store';
import { getCurrentApplicationPath } from './utils';
import { STORE_STATE_AND_LOGIN, RESET_PERSISTOR, STORE_STATE_AND_LOGIN_MFA } from './constants';
import { loginCheckStatusUpdate } from '../routes/app/actions';

export const login = () => {
    window.TelstraAuth.login();
};

export const saveStateAndLogin = (isAemLogin = false) => {
    persistor.persist();
    store.dispatch({ type: STORE_STATE_AND_LOGIN, lastRoute: getCurrentApplicationPath(), isAemLogin });
    store.dispatch(loginCheckStatusUpdate(true));
    setTimeout(() => {
        window.TelstraAuth.login();
    }, 200);
};

export const saveStateAndLoginWelcome = (isAemLogin = false) => {
    persistor.persist();
    store.dispatch({ type: STORE_STATE_AND_LOGIN, lastRoute: null, isAemLogin });
    store.dispatch(loginCheckStatusUpdate(true));
    setTimeout(() => {
        window.TelstraAuth.login();
    }, 200);
};

export const resetPersistor = () => {
    store.dispatch({ type: RESET_PERSISTOR });
    persistor.purge();
};

export const mfaLogin = (loa, editingField, isAemLogin = false, isMfaFromEdit = true) => {
    persistor.persist();
    store.dispatch({ type: STORE_STATE_AND_LOGIN_MFA, lastRoute: getCurrentApplicationPath(), editingField: editingField, isAemLogin, isMfaFromEdit });
    setTimeout(() => {
        window.TelstraAuth.loginWithMfa();
    }, 200);
};

export const flushMfaFromEditFlag = ( isAemLogin = false, isMfaFromEdit = false) => {
    persistor.persist();
    store.dispatch({ type: STORE_STATE_AND_LOGIN_MFA, isMfaFromEdit, lastRoute: getCurrentApplicationPath(), isAemLogin, editingField: null});
};
